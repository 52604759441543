import { Action, Reducer } from 'redux';
import { AppThunkAction } from './index';

export type ToasterType = 'success' | 'info' | 'warning' | 'error';

export type LangCode = 'en' | 'de';

//ACTIONS
interface ShowSpiner {
    type: 'GLOBAL_SHOW_SPINER';
}

interface HideSpiner {
    type: 'GLOBAL_HIDE_SPINER';
}

export interface ShowToaster {
    type: 'GLOBAL_SHOW_TOASTER';
    toasterType: ToasterType;
    toasterMessage: string;
}

export interface HideToaster {
    type: 'GLOBAL_HIDE_TOASTER';
}

export interface ChangeLanguage {
    type: 'GLOBAL_CHANGE_LANGUAGE';
    code: LangCode;
}

export function prepareLanguage(lang: string) {
    switch (lang) {
        case 'en':
            return 'en';
        case 'de':
            return 'de';
        default:
            return 'de';
    }
}

//ACTION TYPES
export type GlobalAction = ShowSpiner | HideSpiner | ShowToaster | HideToaster | ChangeLanguage;

//ACTION CREATORS
export const actionCreators = {
    showSpiner: (): AppThunkAction<GlobalAction> => (dispatch) => {
        dispatch({
            type: 'GLOBAL_SHOW_SPINER',
        });
    },
    hideSpiner: (): AppThunkAction<GlobalAction> => (dispatch) => {
        dispatch({
            type: 'GLOBAL_HIDE_SPINER',
        });
    },
    showToaster:
        (type: ToasterType = 'info', message: string = ''): AppThunkAction<GlobalAction> =>
        (dispatch) => {
            dispatch({
                type: 'GLOBAL_SHOW_TOASTER',
                toasterMessage: message,
                toasterType: type,
            });
        },
    hideToaster: (): AppThunkAction<GlobalAction> => (dispatch) => {
        dispatch({
            type: 'GLOBAL_HIDE_TOASTER',
        });
    },
    changeLanguage:
        (language: LangCode): AppThunkAction<GlobalAction> =>
        (dispatch) => {
            dispatch({
                type: 'GLOBAL_CHANGE_LANGUAGE',
                code: language,
            });
        },
};

//STATE
export interface GlobalState {
    isLoading: boolean;
    showToaster: boolean;
    toasterType: ToasterType;
    toasterMessage: string;
    language: LangCode;
}

//REDUCER
const initialState: GlobalState = {
    isLoading: false,
    showToaster: false,
    toasterType: 'info',
    toasterMessage: '',
    language: 'de',
};

export const reducer: Reducer<GlobalState> = (
    state: GlobalState | undefined,
    incomingAction: Action
): GlobalState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as GlobalAction;
    switch (action.type) {
        case 'GLOBAL_SHOW_SPINER':
            return {
                ...state,
                isLoading: true,
            };
        case 'GLOBAL_HIDE_SPINER':
            return {
                ...state,
                isLoading: false,
            };
        case 'GLOBAL_SHOW_TOASTER':
            return {
                ...state,
                showToaster: true,
                toasterMessage: action.toasterMessage,
                toasterType: action.toasterType,
            };
        case 'GLOBAL_HIDE_TOASTER':
            return {
                ...state,
                showToaster: false,
                toasterMessage: '',
            };
        case 'GLOBAL_CHANGE_LANGUAGE':
            return {
                ...state,
                language: prepareLanguage(action.code),
            };
        default:
            return state;
    }
};
