import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField as MuiTextField,
    Typography,
    InputAdornment,
    ClickAwayListener,
    Tooltip,
    DialogActions,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import i18n from 'i18n-js';
import html2canvas from 'html2canvas';
import * as yup from 'yup';

import {
    actionCreators as restaurantDetailsActionCreators,
    QRData,
} from 'store/restaurantDetailsStore';
import { ApplicationState } from 'store';
import { actionCreators as menuActionCreators } from 'store/menusStore';
import { helperFunctions } from 'helpers';
import { trimLeftAndRight } from 'helpers/helperFunctions';
import { images } from 'assets';
import { BasicThreeDotsMenu, Input, Text } from 'components/shared';
import { PopupConfirmation } from 'components/shared';
import styles from './styles';
import { OrderServices } from 'constants/enums';

const QRCode = require('qrcode.react');

interface QRProps {
    restaurantId: string;
    downloadQrName?: string;
    setDownloadQrName?: (name: string) => void;
    isAdmin?: boolean;
}

const qrImageSettings = {
    src: images.icons.blackPenguin,
    width: 75,
    height: 75,
};

const qrCodeSize = 300;

const QR = ({ restaurantId, downloadQrName, setDownloadQrName, isAdmin }: QRProps) => {
    const dispatch = useDispatch();
    const menus = useSelector((state: ApplicationState) => state.menus.menus);
    const qrName = useSelector(
        (state: ApplicationState) => state.restaurantDetails.restaurantInformation.qrCodesName
    );
    const restaurantInformation = useSelector((state: ApplicationState) =>
        isAdmin ? state.restaurantDetails.restaurant : state.restaurantDetails.restaurantInformation
    );
    const qrCodes = useSelector((state: ApplicationState) => state.restaurantDetails.qrCodes);
    const classes = styles();

    const [service, setService] = useState('');
    const [serviceIndex, setServiceIndex] = useState(0);
    const [qrModalType, setQrModalType] = useState(0);
    const [showQrListModal, setShowQrListModal] = useState(false);
    const [qrModalValues, setQrModalValues] = useState({
        qrId: '',
        qrName: '',
        qrAreaName: '',
        qrValues: {
            menuId: '',
            tableId: '',
        },
        key: 0,
    });
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deletedQrIds, setDeletedQrIds] = useState<string[]>([]);
    const [renderForDownload, setRenderForDownload] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const existsServiceCodes = useRef<string[]>([]);

    const validationSchema = yup.object().shape({
        qrName: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'QR Code Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !existsServiceCodes.current.includes(value?.toUpperCase().trim())
            ),
    });

    useEffect(() => {
        if (menus.length === 0 && !isAdmin) {
            getMenus();
        }
        if (isAdmin) {
            getMenus();
            if (!restaurantInformation.id && restaurantId) {
                dispatch(restaurantDetailsActionCreators.getRestaurantDetails(restaurantId));
            }
        }

        setDownloadQrName && setDownloadQrName(qrName);
    }, [qrName]); // eslint-disable-line

    useEffect(() => {
        dispatch(
            restaurantDetailsActionCreators.getQrCodes(
                isAdmin
                    ? {
                          restaurantId: restaurantId,
                      }
                    : {}
            )
        );
    }, [restaurantId]); // eslint-disable-line

    function getMenus() {
        dispatch(menuActionCreators.getMenus(isAdmin ? restaurantId : ''));
    }

    function resetQrModalValues() {
        setQrModalValues({
            qrId: '',
            qrName: '',
            qrAreaName: '',
            qrValues: {
                menuId: '',
                tableId: '',
            },
            key: 0,
        });
    }

    function createNewQr(name: string, qrAreaName: string, url: string, qrValues: any) {
        const qrData = {
            name,
            url,
            service,
            area: qrAreaName,
            areaId: '',
            qrValues,
        };
        setQrModalType(0);
        dispatch(
            restaurantDetailsActionCreators.createQrCode(
                qrData,
                isAdmin
                    ? {
                          restaurantId,
                      }
                    : {}
            )
        );
    }

    function updateQr(id: string, name: string, qrAreaName: string, url: string, qrValues: any) {
        setQrModalType(0);
        const qrData = {
            id,
            name,
            url,
            service,
            area: qrAreaName,
            areaId: '',
            qrValues,
        };
        dispatch(restaurantDetailsActionCreators.updateQrCode(qrData));
    }

    function deleteQrs() {
        dispatch(restaurantDetailsActionCreators.deleteQrCode(deletedQrIds));
        setDeletedQrIds([]);
        setQrModalType(0);
    }

    function getQrCodeActionList() {
        const actionList = [
            { name: 'Restaurant Information', key: 0, menuId: '', tableId: '' },
            { name: 'All Menu', menuId: 'all', key: 1, tableId: '' },
        ];

        if (menus.length > 0) {
            menus.forEach((menu, i) => {
                if (menu.isEnabled) {
                    actionList.push({
                        name: `${menu.name}`,
                        key: i + 2,
                        menuId: menu.id,
                        tableId: '',
                    });
                }
            });
        }

        return actionList;
    }

    function getQrCode(
        restaurantId: string,
        service: number,
        qrName: string,
        menuId?: string,
        tableId?: string | number
    ) {
        if (restaurantId) {
            return helperFunctions.generateQrCodeUrl(
                restaurantId,
                menuId,
                tableId,
                service,
                qrName
            );
        }
        return '';
    }

    function getServiceQrs(amount = 0, name: string) {
        const filtered = qrCodes?.filter((qrCode) => qrCode.service === name);
        if (amount) {
            return filtered.slice(0, amount);
        }
        return filtered;
    }

    function QRItem(index?: number) {
        const { hasOnSite, hasHomeDelivery, hasTakeAway } = restaurantInformation;
        let name: any,
            displayName: string = '',
            image,
            serviceIndex = index || 0,
            active = true;
        switch (index) {
            case 0:
                name = OrderServices.HomeDelivery;
                displayName = i18n.t('restaurant.homeDelivery');
                image = images.icons.chartHomeDelivery;
                active = hasHomeDelivery;
                break;
            case 1:
                name = OrderServices.OrderInRestaurant;
                displayName = i18n.t('restaurant.orderInRestaurant');
                image = images.icons.chartVorort;
                active = hasOnSite;
                break;
            case 2:
                name = OrderServices.TakeAway;
                displayName = i18n.t('restaurant.takeAway');
                image = images.icons.chartTakeAway;
                active = hasTakeAway;
                break;
        }

        if (!active) {
            return;
        }

        const qrLength = getServiceQrs(0, name).length;

        function getThreeDotItems() {
            const items = [];
            items.push(
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => displayAddQrModal(name, serviceIndex)}
                >
                    <img
                        src={images.icons.plus}
                        alt="plus-icon"
                        className={classes.threeDotsItemIcon}
                    />
                    <ListItemText primary={i18n.t('restaurant.addNewQr')} />
                </div>
            );
            if (getServiceQrs(1, name).length > 0) {
                items.push(
                    <div
                        className={classes.threeDotsMenuItemContainer}
                        onClick={() => downloadQRCodes(name)}
                    >
                        <img
                            src={images.icons.fileDownloadDisabled}
                            alt="file-download-disabled"
                            className={classes.threeDotsItemIcon}
                        />
                        <ListItemText primary={i18n.t('restaurant.downloadAllQRs')} />
                    </div>
                );
            }
            return items;
        }

        return (
            <div
                onClick={() => {
                    if (getServiceQrs(0, name).length === 0) {
                        return displayAddQrModal(name, serviceIndex);
                    }
                    setService(name);
                    setServiceIndex(serviceIndex);
                    setShowQrListModal(true);
                }}
                className={classes.qrItemContainer}
            >
                <div
                    className={`${classes.qrTextContainer} ${classes.flexColumnSpaceBetweenNoAlign} ${classes.mVertical10}`}
                >
                    <div className={classes.flexColumn}>
                        <span className={classes.qrItemTitle}>{displayName}</span>
                        <span>{i18n.t('form.description')}</span>
                    </div>
                    <img
                        src={image}
                        className={classes.homeDeliveryIcon}
                        alt="home-delivery-icon"
                    />
                </div>
                {getServiceQrs(3, name).map((qrCode, i) => (
                    <div
                        key={i}
                        className={classes.qrCodeContainer}
                        style={{ marginLeft: i === 0 ? -50 : -120 }}
                    >
                        <QRCode value={''} size={105} />
                    </div>
                ))}
                {getServiceQrs(0, name).length === 0 && (
                    <div className={classes.qrCodeContainer}>
                        <span className={classes.addQrText}>{i18n.t('restaurant.addQr')}</span>
                    </div>
                )}
                <div className={`${classes.mVertical10} ${classes.flexColumnSpaceBetweenNoAlign}`}>
                    <BasicThreeDotsMenu items={getThreeDotItems()} />
                    <b>
                        {qrLength} {qrLength > 1 ? 'QRs' : 'QR'}
                    </b>
                </div>
            </div>
        );
    }

    async function downloadQRCodes(serviceName: string) {
        setRenderForDownload(true);
        setService(serviceName);
        const filtered = qrCodes.filter((qrCode) => qrCode.service === serviceName);

        await new Promise((resolve) => setTimeout(() => resolve('done'), 500));

        filtered.forEach((qrCode, i) => {
            const containerId = `qrContainerMulti-${qrCode.id}`;
            html2canvas(document.getElementById(containerId) as HTMLCanvasElement, {
                onclone: (document, element) => {
                    element.style.display = 'flex';
                },
            })
                .then((canvas) => {
                    const pngUrl = canvas
                        .toDataURL('image/png')
                        .replace('image/png', 'image/octet-stream');
                    let downloadLink = document.createElement('a');
                    downloadLink.href = pngUrl;
                    downloadLink.download = `${qrCode.service}-${qrCode.name}.png`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                })
                .catch((e) => alert(e));
        });

        setRenderForDownload(false);
    }

    function displayAddQrModal(serviceName: string, serviceIndex: number) {
        setService(serviceName);
        setServiceIndex(serviceIndex);
        setQrModalType(1);
        resetQrModalValues();
        existsServiceCodes.current = getServiceQrs(0, serviceName).map((code) =>
            code.name.toUpperCase()
        );
    }

    function hideAddQrModal() {
        setQrModalType(0);
    }

    function addOrEditQrModal() {
        function isSameQrName(currentQrName: string) {
            let hasSameName = 0;
            qrCodes.forEach((qrCode) => {
                if (
                    qrCode.name.replaceAll(/\s/g, '').toUpperCase() ===
                    currentQrName.replaceAll(/\s/g, '').toUpperCase()
                ) {
                    hasSameName++;
                }
            });
            return hasSameName > 0;
        }

        return (
            <Dialog
                open={qrModalType > 0}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">
                    <span>
                        {i18n.t(
                            `${qrModalType === 1 ? 'restaurant.addNewQr' : 'restaurant.editQr'}`
                        )}
                    </span>
                    {qrModalType === 2 && (
                        <Button
                            onClick={() => {
                                setDeletedQrIds([qrModalValues.qrId]);
                                setShowDeletePopup(true);
                            }}
                            className={classes.removeButton}
                            variant="contained"
                        >
                            {i18n.t('common.remove')}
                        </Button>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={qrModalValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={async (values) => {
                            const {
                                qrName,
                                qrAreaName,
                                qrId,
                                qrValues,
                                qrValues: { menuId, tableId },
                            } = values;
                            if (qrModalType === 1) {
                                createNewQr(
                                    trimLeftAndRight(qrName),
                                    qrAreaName,
                                    getQrCode(
                                        restaurantId,
                                        serviceIndex,
                                        trimLeftAndRight(qrName),
                                        menuId,
                                        tableId
                                    ),
                                    qrValues
                                );
                            } else {
                                updateQr(
                                    qrId,
                                    trimLeftAndRight(qrName),
                                    qrAreaName,
                                    getQrCode(
                                        restaurantId,
                                        serviceIndex,
                                        trimLeftAndRight(qrName),
                                        menuId,
                                        tableId
                                    ),
                                    qrValues
                                );
                            }
                        }}
                    >
                        {({ values, setFieldValue, submitForm, errors }) => (
                            <Form>
                                <>
                                    <div className={classes.addQrCodeContainer}>
                                        <QRCode
                                            value={getQrCode(
                                                restaurantId,
                                                serviceIndex,
                                                values.qrName
                                            )}
                                            size={105}
                                        />
                                    </div>
                                    <br />
                                    <Input
                                        value={values.qrName}
                                        error={
                                            errors.qrName ||
                                            (isSameQrName(values.qrName) && qrModalType === 1)
                                        }
                                        variant="filled"
                                        fullWidth={true}
                                        name={`qrName`}
                                        type="text"
                                        label={i18n.t('restaurant.nameQr')}
                                        placeholder={i18n.t('restaurant.nameQr')}
                                    />
                                    <br />
                                    <Autocomplete
                                        // @ts-ignore
                                        value={{
                                            name: values.qrAreaName,
                                            key: values.key,
                                        }}
                                        options={getQrCodeActionList()}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) =>
                                            option.key === value.key
                                        }
                                        onChange={(e: object, value: any | null) => {
                                            setFieldValue('key', value ? value.key : 0);
                                            setFieldValue('qrAreaName', value ? value.name : '');
                                            setFieldValue('qrValues', value);
                                        }}
                                        renderInput={(params) => (
                                            <>
                                                <MuiTextField
                                                    {...params}
                                                    name="type"
                                                    label={i18n.t('restaurant.selectThePage')}
                                                    variant="filled"
                                                    fullWidth
                                                />
                                            </>
                                        )}
                                    />
                                </>
                                <DialogActions>
                                    <Button onClick={hideAddQrModal} color="primary">
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        disabled={
                                            values.qrAreaName.length === 0 ||
                                            values.qrName.length === 0
                                        }
                                        color="primary"
                                        variant="contained"
                                        style={{ marginLeft: 20 }}
                                    >
                                        {i18n.t(
                                            `${qrModalType === 1 ? 'common.add' : 'common.save'}`
                                        )}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <div className={classes.qrContainer}>
            {renderForDownload &&
                getServiceQrs(0, service).map((qrCode, i) => (
                    <div
                        key={i}
                        id={`qrContainerMulti-${qrCode.id}`}
                        className={classes.qrDownloadContainer}
                    >
                        <span className={classes.qrCodeTopText}>
                            {downloadQrName ? downloadQrName : ''}
                        </span>
                        <QRCode
                            className={classes.qrCodeDownload}
                            id={qrCode.id}
                            value={qrCode.url}
                            imageSettings={qrImageSettings}
                            size={qrCodeSize}
                            includeMargin={true}
                            level={'Q'}
                        />
                        <span className={classes.qrCodeDownloadText}>{qrCode.name}</span>
                    </div>
                ))}
            {addOrEditQrModal()}
            <span className={classes.sectionTitle}>
                {i18n.t('restaurant.additionalTextForQr').toUpperCase()}
            </span>
            <Formik
                initialValues={{
                    downloadQrName: !!downloadQrName
                        ? downloadQrName
                        : !!restaurantInformation.qrCodesName
                        ? restaurantInformation.qrCodesName
                        : '',
                }}
                enableReinitialize={true}
                onSubmit={async (values) => {}}
            >
                {({ values, setFieldValue, submitForm }) => (
                    <Form style={{ width: '49%', margin: '20px 0px 39px 0px' }}>
                        <div>
                            <Input
                                onChange={(e: any) => {
                                    setDownloadQrName &&
                                        setDownloadQrName(e.target.value ? e.target.value : '');
                                    setFieldValue('downloadQrName', e.target.value);
                                }}
                                inputProps={{
                                    maxLength: 40,
                                }}
                                value={values.downloadQrName}
                                variant="filled"
                                fullWidth={true}
                                name={`downloadQrName`}
                                type="text"
                                label={i18n.t('form.description')}
                                placeholder={i18n.t('form.description')}
                                InputProps={{
                                    startAdornment: (
                                        <ClickAwayListener
                                            onClickAway={() => setShowTooltip(false)}
                                        >
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={() => setShowTooltip(true)}
                                                open={showTooltip}
                                                disableFocusListener
                                                style={{ top: 15 }}
                                                title={i18n.t('form.errors.qrMaxLenght')}
                                            >
                                                <InputAdornment
                                                    position="end"
                                                    style={{
                                                        position: 'absolute',
                                                        right: 17,
                                                        cursor: 'pointer',
                                                        zIndex: 25,
                                                    }}
                                                >
                                                    <img
                                                        alt="warning"
                                                        src={images.icons.warning}
                                                        onClick={() => setShowTooltip(true)}
                                                        onMouseLeave={() => setShowTooltip(false)}
                                                    />
                                                </InputAdornment>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    ),
                                }}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
            <div className={`${classes.flexRowSpaceBetween} ${classes.mTop15}`}>
                {QRItem(0)}
                {QRItem(1)}
            </div>
            <div className={`${classes.flexRowSpaceBetween} ${classes.mTop15}`}>{QRItem(2)}</div>
            <QrListModal
                downloadQrName={trimLeftAndRight(downloadQrName) ?? ''}
                isVisible={showQrListModal}
                onClose={() => setShowQrListModal(false)}
                serviceName={service}
                onEditClicked={(qrCode) => {
                    setQrModalValues({
                        qrId: qrCode.id ? qrCode.id : '',
                        // @ts-ignore
                        qrService: qrCode.service,
                        qrName: qrCode.name,
                        // @ts-ignore
                        qrAreaName: qrCode.area,
                        qrValues: {
                            menuId: '',
                            tableId: '',
                        },
                    });
                    setQrModalType(2);
                }}
            />
            <PopupConfirmation
                open={showDeletePopup}
                close={() => setShowDeletePopup(false)}
                title={i18n.t('restaurant.removeQr')}
                description={i18n.t('restaurant.removeQrWarning')}
                activeBtn={i18n.t('common.remove')}
                action={deleteQrs}
            />
        </div>
    );
};

export default QR;

interface QRListModalProps {
    isVisible: boolean;
    onClose: () => void;
    onEditClicked: (qrCode: QRData) => void;
    serviceName: string;
    downloadQrName: string;
}

const QrListModal = ({
    isVisible,
    onClose,
    onEditClicked,
    serviceName,
    downloadQrName,
}: QRListModalProps) => {
    const dispatch = useDispatch();
    const qrCodes = useSelector((state: ApplicationState) => state.restaurantDetails.qrCodes);
    const [deletedQrIds, setDeletedQrIds] = useState<string[]>([]);
    const [qrTitleQuery, setQrTitleQuery] = useState('');
    const [qrPageQuery, setQrPageQuery] = useState('');
    const [downloadQrId, setDownloadQrId] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const classes = styles();

    function addOrRemoveDeleteQr(id: string) {
        if (deletedQrIds.includes(id)) {
            const filtered = deletedQrIds.filter((deleteQrId) => deleteQrId !== id);
            return setDeletedQrIds(filtered);
        }
        setDeletedQrIds([...deletedQrIds, id]);
    }

    function getServiceQrs(amount = 0) {
        let filtered = qrCodes?.filter((qrCode) => qrCode.service === serviceName);
        if (amount) {
            return filtered.slice(0, amount);
        }
        filtered = filtered.filter(
            (qrCode) => qrCode.name.toLowerCase().indexOf(qrTitleQuery.toLowerCase()) >= 0
        );
        filtered = filtered.filter(
            (qrCode) => qrCode.area.toLowerCase().indexOf(qrPageQuery.toLowerCase()) >= 0
        );
        return filtered;
    }

    function deleteQrs() {
        dispatch(restaurantDetailsActionCreators.deleteQrCode(deletedQrIds));
        setDeletedQrIds([]);
    }

    function selectOrDeSelectAllQrs() {
        if (deletedQrIds.length !== getServiceQrs().length) {
            const qrIds: string[] = [];
            getServiceQrs().forEach((qrCode) => {
                // @ts-ignore
                qrIds.push(qrCode.id);
            });
            setDeletedQrIds(qrIds);
        } else {
            setDeletedQrIds([]);
        }
    }

    async function downloadQRCode(qrCode: any) {
        const containerId = `qrContainer-${qrCode.id}`;
        setDownloadQrId(qrCode.id);

        await new Promise((resolve) => setTimeout(() => resolve('done'), 500));

        html2canvas(document.getElementById(containerId) as HTMLCanvasElement, {
            onclone: (document, element) => {
                element.style.display = 'flex';
            },
        })
            .then((canvas) => {
                const pngUrl = canvas
                    .toDataURL('image/png')
                    .replace('image/png', 'image/octet-stream');
                let downloadLink = document.createElement('a');
                downloadLink.href = pngUrl;
                downloadLink.download = `${qrCode.service}-${qrCode.name}.png`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
            .catch((e) => alert(e))
            .finally(() => setDownloadQrId(''));
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={isVisible}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <div className={classes.flexRowSpaceBetween}>
                    <Typography variant="h6" align="left" className={classes.fontWeight500}>
                        {i18n.t('restaurant.qrCodes')}
                    </Typography>
                    <div>
                        <Button
                            onClick={() => {
                                setDeletedQrIds([]);
                                onClose();
                            }}
                            type="button"
                            color="primary"
                            className={classes.mRight20}
                        >
                            {i18n.t('button.cancel')}
                        </Button>
                        <Button
                            onClick={() => setShowDeletePopup(true)}
                            disabled={deletedQrIds.length === 0}
                            type="button"
                            color="primary"
                            variant="contained"
                            className={classes.saveRemoveButton}
                        >
                            {i18n.t('common.removeSelected')}
                        </Button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent className={classes.qrListContainer}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead style={{ opacity: 0.5 }}>
                            <TableRow key="table-head">
                                <TableCell className={classes.alignCenter}>
                                    <Checkbox
                                        checked={
                                            deletedQrIds.length === getServiceQrs().length &&
                                            deletedQrIds.length !== 0
                                        }
                                        onChange={selectOrDeSelectAllQrs}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    <MuiTextField
                                        onChange={(e) => setQrTitleQuery(e.target.value)}
                                        placeholder={i18n.t('restaurant.qrTitle')}
                                        InputProps={{
                                            endAdornment: (
                                                <img src={images.icons.search} alt="search-icon" />
                                            ),
                                            disableUnderline: true,
                                        }}
                                        className={classes.qrListHeaderInput}
                                    />
                                </TableCell>
                                <TableCell>
                                    <MuiTextField
                                        onChange={(e) => setQrPageQuery(e.target.value)}
                                        placeholder={i18n.t('restaurant.page')}
                                        InputProps={{
                                            endAdornment: (
                                                <img src={images.icons.search} alt="search-icon" />
                                            ),
                                            disableUnderline: true,
                                        }}
                                        className={classes.qrListHeaderInput}
                                    />
                                </TableCell>
                                <TableCell align={'right'}>
                                    <img
                                        src={images.icons.removeCircleDisabled}
                                        alt="remove-icon"
                                        className={classes.actionBlock}
                                    />
                                    <span>{i18n.t('restaurant.action')}</span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getServiceQrs().map((qrCode, i) => (
                                <TableRow key={i}>
                                    <div
                                        id={`qrContainer-${qrCode?.id}`}
                                        className={classes.qrDownloadContainer}
                                    >
                                        <span className={classes.qrCodeTopText}>
                                            {downloadQrName}
                                        </span>
                                        {downloadQrId === qrCode.id && (
                                            <QRCode
                                                className={classes.qrCodeDownload}
                                                id={qrCode.id}
                                                value={qrCode.url}
                                                imageSettings={qrImageSettings}
                                                size={qrCodeSize}
                                                includeMargin={true}
                                                level={'Q'}
                                            />
                                        )}
                                        <span className={classes.qrCodeDownloadText}>
                                            {qrCode.name}
                                        </span>
                                    </div>
                                    <TableCell className={classes.alignCenter}>
                                        <Checkbox
                                            // @ts-ignore
                                            checked={deletedQrIds.includes(qrCode.id)}
                                            // @ts-ignore
                                            onChange={() => addOrRemoveDeleteQr(qrCode.id)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        <QRCode value={''} size={20} className={classes.listQr} />
                                        <Text text={qrCode.name} maxLength={30} />
                                    </TableCell>
                                    <TableCell>
                                        <span>{qrCode.area}</span>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <img
                                            onClick={() => downloadQRCode(qrCode)}
                                            src={images.icons.fileDownloadDisabled}
                                            alt="file-download-icon"
                                            className={` ${classes.cursorHover} ${classes.fileDownloadIcon}`}
                                        />
                                        <img
                                            // @ts-ignore
                                            onClick={() => addOrRemoveDeleteQr(qrCode.id)}
                                            src={images.icons.removeCircleDisabled}
                                            alt="remove-icon"
                                            className={`${classes.mRight50} ${classes.cursorHover}`}
                                        />
                                        <img
                                            onClick={() => onEditClicked(qrCode)}
                                            src={images.icons.edit}
                                            alt="edit-icon"
                                            className={` ${classes.cursorHover}`}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {getServiceQrs().length === 0 && (
                                <div className={classes.noQrContainer}>
                                    <QRCode
                                        className={classes.noQrCode}
                                        value={''}
                                        size={138}
                                        includeMargin={true}
                                    />
                                    <h6 className={classes.noQrText}>
                                        {i18n.t('restaurant.noQrLeft')}
                                    </h6>
                                    <h6 className={classes.noQrCreateText}>
                                        {i18n.t('restaurant.noQrCreateNew')}
                                    </h6>
                                </div>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>

            <PopupConfirmation
                open={showDeletePopup}
                close={() => setShowDeletePopup(false)}
                title={i18n.t('restaurant.removeQr')}
                description={i18n.t('restaurant.removeQrWarning')}
                activeBtn={i18n.t('common.remove')}
                action={deleteQrs}
            />
        </Dialog>
    );
};
