import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NothingToSee } from 'components/shared';
import MenuDetails from 'components/menu/MenuDetails';
import { MenusPage } from 'components/menu/MenusTable/MenusPage';
import { OrdersPage } from 'components/orders';
import Home from 'components/home';
import Callback from 'components/callback/Callback';
import silentRenew from 'components/silent-renew';

export const RestaurantEmployeeRoute: React.FC<{}> = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/orders" component={OrdersPage} />;
            <Route exact path="/menu" component={MenusPage} />
            <Route exact path="/menu/:id" component={MenuDetails} />
            <Route exact path="/callback" component={Callback} />
            <Route exact path="/silentRenew" component={silentRenew} />
            <Route component={NothingToSee} />
        </Switch>
    );
};
