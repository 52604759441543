import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Select,
    FormControl,
    ListItemIcon,
    ListItemText,
    MenuItem,
    InputLabel,
    DialogActions,
} from '@material-ui/core';
import * as yup from 'yup';
import i18n from 'i18n-js';
import clsx from 'clsx';
import moment from 'moment';

import { images } from 'assets';
import { Input, Text } from 'components/shared';
import { CustomPhoneInput } from 'components/shared';
import { DateTimePicker } from 'components/shared';
import { ApplicationState } from 'store';
import * as OrdersStore from 'store/ordersStore';
import { formatDate, formatTime } from 'helpers/datetimeHelper';
import { actionCreators as restaurantDetailsActionsCreators } from 'store/restaurantDetailsStore';
import { useStyles } from './styles';

export default function AddNewOrderModal(props: { isOpen: boolean; onClose: () => void }) {
    const classes = useStyles();
    const restaurantDetails = useSelector((state: ApplicationState) => state.restaurantDetails);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] =
        useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const [showPicker, setShowPicker] = useState(false);
    const [selectedSrvice, setSelectedService] = useState('');

    const services = new Map<string, { icon: string; name: string }>([
        [
            'TakeAway',
            {
                icon: images.icons.chartTakeAway,
                name: i18n.t('form.takeAway'),
            },
        ],
        [
            'HomeDelivery',
            {
                icon: images.icons.chartHomeDelivery,
                name: i18n.t('form.homeDelivery'),
            },
        ],
        [
            'Vorort',
            {
                icon: images.icons.chartVorort,
                name: i18n.t('form.jamesVorort'),
            },
        ],
    ]);

    const initalFormValues = {
        service: Array.from(prepareServices().keys())[0] as string,
        deliveryTime: 0,
        name: '',
        city: '',
        addressLine: '',
        email: '',
        phoneNumber: '',
        comment: '',
        qrCodeName:
            restaurantDetails.qrCodes.find(
                (x) => x.service === Array.from(prepareServices().keys())[0]
            )?.name ?? '',
    };

    function preventProp(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
    }

    function prepareServices() {
        let services = new Map<string, { icon: string; name: string }>();
        if (restaurantDetails.restaurantInformation.hasHomeDelivery) {
            services.set('HomeDelivery', {
                icon: images.icons.chartHomeDelivery,
                name: i18n.t('form.homeDelivery'),
            });
        }
        if (restaurantDetails.restaurantInformation.hasTakeAway) {
            services.set('TakeAway', {
                icon: images.icons.chartTakeAway,
                name: i18n.t('form.takeAway'),
            });
        }
        if (restaurantDetails.restaurantInformation.hasOnSite) {
            services.set('Vorort', {
                icon: images.icons.chartVorort,
                name: i18n.t('form.jamesVorort'),
            });
        }

        return services;
    }

    function prepareQrCodes(service: string) {
        return restaurantDetails.qrCodes.filter((x) => x.service === service);
    }

    const validationSchema = () => {
        if (selectedSrvice === 'TakeAway' || selectedSrvice === 'HomeDelivery') {
            return yup.object().shape({
                service: yup.string().required(
                    i18n.t('form.errors.required', {
                        name: 'Service',
                    })
                ),
                name: yup.string().required(
                    i18n.t('form.errors.required', {
                        name: 'Name',
                    })
                ),
                city: yup.string().required(
                    i18n.t('form.errors.required', {
                        name: 'City',
                    })
                ),
                addressLine: yup.string().required(
                    i18n.t('form.errors.required', {
                        name: 'Address',
                    })
                ),
            });
        } else {
            return yup.object().shape({
                service: yup.string().required(
                    i18n.t('form.errors.required', {
                        name: 'Service',
                    })
                ),
            });
        }
    };

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    useEffect(() => {
        if (props.isOpen) {
            dispatch(restaurantDetailsActionsCreators.getQrCodes());
            !!!restaurantDetails.restaurantInformation.id &&
                dispatch(restaurantDetailsActionsCreators.getRestaurantInformation());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    return (
        <>
            <Dialog
                maxWidth={'sm'}
                onClick={preventProp}
                open={props.isOpen}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">
                    {i18n.t('restaurant.createNewOrder')}
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initalFormValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            dispatch(OrdersStore.actionCreators.createEmptyOrder(values));
                            props.onClose();
                        }}
                    >
                        {({ values, setFieldValue, errors, submitForm }) => (
                            <form>
                                <FormControl
                                    variant="filled"
                                    fullWidth={true}
                                    onClick={(e) => handleClick(e)}
                                >
                                    <div className={classes.labelTitle}>
                                        {i18n.t('form.services')}
                                    </div>
                                    <InputLabel style={!!values.service ? { display: 'none' } : {}}>
                                        {i18n.t('form.services')}
                                    </InputLabel>
                                    <Select
                                        variant="filled"
                                        value={values.service}
                                        fullWidth={true}
                                        className={clsx(classes.selectService, {
                                            [classes.selectServiceSelected]: !!values.service,
                                        })}
                                        MenuProps={{
                                            classes: {
                                                list: classes.selectServiceOptions,
                                            },
                                            anchorEl: anchorEl,
                                            open: !!anchorEl,
                                        }}
                                        error={!!errors.service}
                                        onChange={(e) => {
                                            const newService = e.target.value as string;
                                            setSelectedService(newService);
                                            setFieldValue('service', newService);
                                            setFieldValue(
                                                'qrCodeName',
                                                prepareQrCodes(newService)[0]?.name ?? ''
                                            );
                                        }}
                                    >
                                        {Array.from(prepareServices().keys()).map((key) => (
                                            <MenuItem
                                                value={key}
                                                style={{ width: '100%' }}
                                                key={key}
                                            >
                                                <ListItemText primary={services.get(key)?.name} />
                                                <ListItemIcon>
                                                    <img src={services.get(key)?.icon} alt={key} />
                                                </ListItemIcon>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div style={{ position: 'relative' }}>
                                    <div className={classes.labelTitle}>
                                        {i18n.t('common.timeOfDelivery')}
                                    </div>
                                    {!!values.deliveryTime && (
                                        <div className={classes.deliveryTimeText}>
                                            <span style={{ fontWeight: 600 }}>
                                                {`${formatTime(
                                                    moment(values.deliveryTime).toDate()
                                                )} / `}
                                            </span>
                                            {formatDate(moment(values.deliveryTime).toDate())}
                                        </div>
                                    )}

                                    <div className={classes.flexRowSpaceBetween}>
                                        <Input
                                            id="date-time-picker"
                                            style={{ width: '48%' }}
                                            value={undefined}
                                            variant="filled"
                                            readOnly
                                            name={`timeOfDelivery`}
                                            type="text"
                                            label={
                                                !!!values.deliveryTime
                                                    ? i18n.t('common.timeOfDelivery')
                                                    : ''
                                            }
                                            //placeholder={i18n.t('common.timeOfDelivery')}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            onClick={() => setShowPicker(!showPicker)}
                                        />
                                        <DateTimePicker
                                            showButtons
                                            futureOnly
                                            futureTimeOnly
                                            date={
                                                !!values.deliveryTime
                                                    ? new Date(values.deliveryTime)
                                                    : new Date(Date.now())
                                            }
                                            onSubmit={(e) =>
                                                setFieldValue('deliveryTime', moment(e).valueOf())
                                            }
                                            isOpen={showPicker}
                                            onClose={() => setShowPicker(false)}
                                        />
                                        <FormControl
                                            variant="filled"
                                            style={{ width: '48%', marginBottom: 28 }}
                                        >
                                            <InputLabel>{i18n.t('restaurant.qrName')}</InputLabel>
                                            <Select
                                                value={values.qrCodeName}
                                                onChange={(e) =>
                                                    setFieldValue('qrCodeName', e.target.value)
                                                }
                                                style={{ textAlign: 'start' }}
                                            >
                                                {prepareQrCodes(values.service).map((qr) => (
                                                    <MenuItem value={qr.name}>
                                                        <Text text={qr.name} maxLength={20} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <div className={classes.labelTitle}>
                                        {i18n.t('common.clientInformation')}
                                    </div>
                                    <Input
                                        value={values.name}
                                        variant="filled"
                                        fullWidth={true}
                                        name={`name`}
                                        type="text"
                                        error={errors.name}
                                        label={i18n.t('common.clientName')}
                                        placeholder={i18n.t('common.clientName')}
                                    />
                                </div>
                                <div className={classes.flexRowSpaceBetween}>
                                    <Input
                                        style={{ width: '48%' }}
                                        value={values.city}
                                        variant="filled"
                                        fullWidth={false}
                                        name={`city`}
                                        type="text"
                                        label={i18n.t('form.city')}
                                        placeholder={i18n.t('form.city')}
                                        error={errors.city}
                                    />
                                    <Input
                                        style={{ width: '48%' }}
                                        value={values.addressLine}
                                        variant="filled"
                                        fullWidth={false}
                                        name={`addressLine`}
                                        type="text"
                                        label={i18n.t('form.restaurantAddress')}
                                        placeholder={i18n.t('form.restaurantAddress')}
                                        error={errors.addressLine}
                                    />
                                </div>
                                <Input
                                    value={values.email}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`email`}
                                    type="text"
                                    error={errors.email}
                                    label={i18n.t('form.email')}
                                    placeholder={i18n.t('form.email')}
                                />
                                <CustomPhoneInput
                                    //containerStyle={styles.phoneInput}
                                    invalid={errors.phoneNumber}
                                    placeholder={i18n.t('form.phoneNumber')}
                                    value={values.phoneNumber}
                                    onChange={(val: any) => setFieldValue('phoneNumber', val)}
                                />
                                <Input
                                    value={values.comment}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`comment`}
                                    type="text"
                                    label={i18n.t('common.clientNotes')}
                                    placeholder={i18n.t('common.clientNotes')}
                                />
                                <DialogActions>
                                    <Button type="button" color="primary" onClick={props.onClose}>
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {i18n.t('button.create')}
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
}
