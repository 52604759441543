import React from 'react';
import { useSelector } from 'react-redux';

import {
    createStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme,
} from '@material-ui/core';
import i18n from 'i18n-js';

import { MenuData } from 'store/menusStore';
import { EditMenuForm } from '../MenusTable/components/EditMenuForm';
import commonStyles from 'config/commonStyles';
import { MenuType } from 'constants/enums';
import { images } from 'assets';
import { ApplicationState } from 'store';

interface MenuEditModalProps {
    menu: MenuData;
    isOpen: boolean;
    isDetails: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        dialog: {
            '& .MuiTextField-root': {
                marginBottom: 28,
            },
            '& .MuiDialogTitle-root > h2': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        dropdown: {
            '& .MuiPopover-paper ': {
                top: '56px !important',
            },
        },
    })
);

export const MenuEditModal = (props: MenuEditModalProps) => {
    const classes = useStyles();
    const menus = useSelector((state: ApplicationState) => state.menus.menus);

    return (
        <Dialog open={props.isOpen} aria-labelledby="form-dialog-title" className={classes.dialog}>
            {props.menu.type === MenuType.Regular && (
                <DialogTitle id="form-dialog-title">
                    {i18n.t('restaurant.editStandartMenu')}
                </DialogTitle>
            )}
            {props.menu.type === MenuType.Daily && (
                <DialogTitle id="form-dialog-title">
                    {i18n.t('restaurant.editDailyMenu')}
                    <img src={images.icons.breakfastLogo} alt="breakfast" />
                </DialogTitle>
            )}
            <DialogContent>
                <EditMenuForm
                    cancel={props.handleClose}
                    menu={props.menu}
                    isDetails={props.isDetails}
                    menus={menus.reduce((result: string[], current) => {
                        if (props.menu.name !== current.name) {
                            result.push(current.name.toUpperCase());
                        }
                        return result;
                    }, [])}
                />
            </DialogContent>
        </Dialog>
    );
};
