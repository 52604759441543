import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';

import { images } from 'assets';
import * as UserStore from 'store/userStore';
import { theme } from 'config/theme';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';

interface NavRestaurantSwitcherProps {
    isOpen: boolean;
    open: () => void;
}

export function NavRestaurantSwitcher(props: NavRestaurantSwitcherProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useAppSelector((state) => state.user);
    const currentRestaurant = user.restaurants?.filter((x) => x.id === user.currentRestaurantId)[0];
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        dispatch(UserStore.actionCreators.getUserRestaurants());
    }, []); // eslint-disable-line

    function handleExpand() {
        if (props.isOpen) {
            setIsExpanded(!isExpanded);
        } else {
            setIsExpanded(true);
            props.open();
        }
    }

    function changeRestaurant(id: string) {
        if (id !== user.currentRestaurantId) {
            dispatch(UserStore.actionCreators.setUserCurrentRestaurant(id));
        }
    }

    return (
        <>
            <div className={classes.root}>
                <Accordion expanded={isExpanded && props.isOpen}>
                    <AccordionSummary
                        className={props.isOpen ? classes.header : classes.headerHidden}
                        expandIcon={<ExpandIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={handleExpand}
                    >
                        <Typography>{currentRestaurant?.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {user?.restaurants?.map((restaurant) => (
                                <ListItem
                                    divider
                                    key={restaurant.id}
                                    selected={user.currentRestaurantId === restaurant.id}
                                    onClick={() => changeRestaurant(restaurant.id)}
                                    style={{
                                        cursor:
                                            user.currentRestaurantId === restaurant.id
                                                ? 'unset'
                                                : 'pointer',
                                    }}
                                >
                                    <ListItemText
                                        primary={restaurant.addressLine}
                                        secondary={restaurant.name}
                                        primaryTypographyProps={{
                                            style: {
                                                color: theme.palette.text.disabled,
                                                fontWeight: 400,
                                                fontSize: 12,
                                            },
                                        }}
                                        secondaryTypographyProps={{
                                            style: {
                                                color: theme.palette.primary.dark,
                                                fontWeight: 400,
                                                fontSize: 16,
                                            },
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}

function ExpandIcon() {
    return <img src={images.icons.triangleDown} alt="triangle-down" />;
}
