import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { RestaurantsPage } from 'components/restaurants/restaurantsPage/RestaurantsPage';
import CreateEditRestaurantPage from 'components/restaurants/CreateEditRestaurantPage/CreateEditRestaurantPage';
import { UsersPage } from 'components/users';
import { NothingToSee } from 'components/shared';
import Home from 'components/home';
import Callback from 'components/callback/Callback';
import silentRenew from 'components/silent-renew';

export const AdminRoute: React.FC<{}> = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/restaurants" component={RestaurantsPage} />
            <Route exact path="/restaurants/:id" component={CreateEditRestaurantPage} />
            <Route exact path="/createrestaurant" component={CreateEditRestaurantPage} />
            <Route exact path="/users" component={UsersPage} />
            <Route exact path="/callback" component={Callback} />
            <Route exact path="/silentRenew" component={silentRenew} />
            <Route component={NothingToSee} />
        </Switch>
    );
};
