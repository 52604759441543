import { makeStyles } from '@material-ui/core';
import { theme } from 'config/theme';
import commonStyles from 'config/commonStyles';

export const useStyles = makeStyles({
    ...commonStyles,
    root: {
        height: 650,
    },
    scrollArrow: {
        position: 'fixed',
        bottom: 80,
        right: 0,
    },
    disabledRow: {
        '& td:not(:last-child)': {
            opacity: 0.35,
        },
        '& td:last-child': {
            borderBottomColor: 'rgba(224, 224, 224, 0.35)',
        },
    },
    formControl: {
        width: '100%',
        '& .MuiFormLabel-root': {
            zIndex: 2,
            left: 10,
        },
    },
    selectRoot: {
        marginTop: 0,
        '& .MuiSelect-root': {
            color: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.light,
            padding: '27px 12px 10px',
        },
        '& + .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
            fontSize: 14,
        },
        '&.MuiInput-formControl': {
            marginTop: 0,
        },
        '& input': {
            width: '95%',
        },
        '& .MuiSelect-icon': {
            rigth: 10,
        },
    },
    dropdown: {
        '& .MuiPopover-paper ': {
            top: '70px !important',
        },
    },
    textMargin: {
        marginLeft: 12,
    },
    button: {
        zIndex: 3,
    },
});
