import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { alpha, Checkbox, createStyles, makeStyles, Radio, Theme } from '@material-ui/core';
import i18n from 'i18n-js';
import clsx from 'clsx';

import { IngredientModel } from '../../../api/agent';
import { ApplicationState } from 'store';
import { images } from 'assets';
import commonStyles from 'config/commonStyles';
import { Roles } from 'constants/enums';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        optionText: {
            fontSize: 14,
            color: alpha(theme.palette.common.black, 0.6),
        },
        amountField: {
            ...commonStyles.flexRowSpaceBetween,
            width: 62,
            height: 28,
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: 14,
            padding: '0 5px',
            marginRight: 15,
            '& input': {
                border: 'none',
                outline: 'none',
                paddingTop: 2,
                textAlign: 'end',
            },
        },
        amountFieldEdit: {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        amountFieldEditError: {
            border: `1px solid ${theme.palette.error.main}`,
        },
        amoutEditIcon: {
            cursor: 'pointer',
        },
    })
);

interface Props {
    defaultIngredient: IngredientModel;
    onRemove: (ingredient: IngredientModel) => void;
    onIngredientChange: (ingredient: IngredientModel) => void;
    index: number;
}

const IngredientItem = ({ defaultIngredient, onRemove, onIngredientChange, index }: Props) => {
    const classes = useStyles();
    const ingredients = useSelector((state: ApplicationState) => state.ingredients.ingredients);
    const user = useSelector((state: ApplicationState) => state.user);
    const allowToEdit = user.role === Roles.OWNER;

    const [ingredient, setIngredient] = useState(defaultIngredient);
    const [optionOpen, setOptionOpen] = useState(true);
    const [editAmount, setEditAmount] = useState(false);
    const [amount, setAmount] = useState<number | string>(ingredient.amount);
    const [amountError, setAmountError] = useState(false);

    function changeIngredientProp(key: 'state' | 'isAddition' | 'isRemoval' | 'amount', val: any) {
        const newIngredient = { ...ingredient, [key]: val };
        if (key === 'state' && val === 1) {
            newIngredient.isAddition = false;
            newIngredient.isRemoval = false;
        }
        setIngredient(newIngredient);
        onIngredientChange(newIngredient);
    }

    function getIngredientName(ingredient: IngredientModel) {
        if (ingredient.name) {
            return ingredient.name;
        }
        return ingredients.find((each) => each.id === ingredient.id)?.name;
    }

    function getIngredientUnit(ingredient: IngredientModel) {
        if (ingredient.unitOfMeasure) {
            return ingredient.unitOfMeasure;
        }
        return ingredients.find((each) => each.id === ingredient.id)?.unitOfMeasure;
    }

    function handleAmount(value: number) {
        if (value < 0) {
            setAmountError(true);
            return;
        } else {
            setAmount(value === 0 ? '' : value);
            amountError && setAmountError(false);
        }
    }

    return (
        <div>
            <div
                style={{
                    height: 43,
                    width: 295,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid lightgray',
                    padding: '0 15px',
                    marginTop: index > 0 ? 5 : 0,
                }}
            >
                <div className={classes.flexRow}>
                    <span className={classes.optionText}>{getIngredientName(ingredient)} </span>
                    <img src={images.icons.lock} alt="lock" style={{ marginLeft: 5 }} />
                </div>
                <div className={classes.flexRow}>
                    <div className={classes.flexRow}>
                        <div
                            className={clsx(classes.amountField, {
                                [classes.amountFieldEdit]: !amountError && editAmount,
                                [classes.amountFieldEditError]: editAmount && amountError,
                            })}
                        >
                            {' '}
                            <div>
                                <input
                                    type="number"
                                    lang="en-150"
                                    min="0"
                                    step="0.1"
                                    readOnly={!editAmount}
                                    value={amount}
                                    style={{ width: editAmount ? 30 : 25 }}
                                    onChange={(e) => handleAmount(+e.target.value)}
                                />
                                {!editAmount && <span>{getIngredientUnit(ingredient)}</span>}
                            </div>
                            {editAmount ? (
                                <img
                                    className={classes.amoutEditIcon}
                                    alt="amount"
                                    onClick={() => {
                                        changeIngredientProp('amount', amount);
                                        setEditAmount(false);
                                    }}
                                    src={images.icons.checkMark}
                                />
                            ) : (
                                <img
                                    className={clsx(classes.amoutEditIcon, {
                                        [classes.disabled]: !allowToEdit,
                                    })}
                                    onClick={() => setEditAmount(true)}
                                    src={images.icons.penOnlyDisabled}
                                    alt="amount"
                                />
                            )}
                        </div>
                        <img
                            onClick={() => setOptionOpen(!optionOpen)}
                            src={images.icons.arrowDropdown}
                            style={{
                                marginLeft: 5,
                                transform: optionOpen ? 'rotate(180deg)' : 'none',
                                cursor: 'pointer',
                            }}
                            alt={'dropdown-icon'}
                        />
                        <img
                            onClick={() => onRemove(ingredient)}
                            src={images.icons.removeCircle}
                            style={{ marginLeft: 5, cursor: 'pointer' }}
                            alt={'remove-circle'}
                            className={clsx({
                                [classes.disabled]: !allowToEdit,
                            })}
                        />
                    </div>
                </div>
            </div>
            {optionOpen && (
                <>
                    <div className={classes.flexRow}>
                        <div
                            onClick={() => changeIngredientProp('state', 0)}
                            className={clsx(classes.flexRow, {
                                [classes.disabled]: !allowToEdit,
                            })}
                        >
                            <Radio
                                checked={ingredient.state === 0}
                                color={'primary'}
                                disabled={!allowToEdit}
                            />
                            <span className={classes.optionText}>
                                {i18n.t('restaurant.fixedIngredient')}
                            </span>
                        </div>
                        <div
                            onClick={() => changeIngredientProp('state', 1)}
                            className={clsx(classes.flexRow, {
                                [classes.disabled]: !allowToEdit,
                            })}
                        >
                            <Radio
                                checked={ingredient.state === 1}
                                color={'primary'}
                                disabled={!allowToEdit}
                            />
                            <span className={classes.optionText}>
                                {i18n.t('restaurant.additionToMeal')}
                            </span>
                        </div>
                    </div>
                    {ingredient.state !== 1 && (
                        <div className={classes.flexRow}>
                            <div
                                onClick={() =>
                                    changeIngredientProp('isAddition', !ingredient.isAddition)
                                }
                                className={clsx(classes.flexRow, {
                                    [classes.disabled]: !allowToEdit,
                                })}
                            >
                                <Checkbox
                                    checked={ingredient.isAddition}
                                    color={'primary'}
                                    disabled={!allowToEdit}
                                />
                                <span className={classes.optionText}>
                                    {i18n.t('restaurant.addition')}
                                </span>
                            </div>
                            <div
                                onClick={() =>
                                    changeIngredientProp('isRemoval', !ingredient.isRemoval)
                                }
                                className={clsx(classes.flexRow, {
                                    [classes.disabled]: !allowToEdit,
                                })}
                            >
                                <Checkbox
                                    checked={ingredient.isRemoval}
                                    color={'primary'}
                                    disabled={!allowToEdit}
                                />
                                <span className={classes.optionText}>
                                    {i18n.t('restaurant.removalIsAllowed')}
                                </span>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default IngredientItem;
