import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import i18n from 'i18n-js';

import { IngredientCategoryData } from 'store/ingredientsStore';
import { SearchInput } from 'components/shared';

interface CategoryIngredientsModalProps {
    isVisible: boolean;
    category: IngredientCategoryData | undefined;
    onClose: () => void;
}

export default function CategoryIngredientsModal(props: CategoryIngredientsModalProps) {
    const [filterText, setFilterText] = useState('');

    function filterSearchText(ingredient: { name: string }) {
        return ingredient.name.toLowerCase().includes(filterText);
    }

    return (
        <Dialog open={props.isVisible} style={{ width: '370px', margin: 'auto' }}>
            <DialogTitle>
                {props.category?.name}
                <Button onClick={props.onClose} variant={'text'} color={'primary'}>
                    {i18n.t('button.close')}
                </Button>
            </DialogTitle>
            <DialogContent style={{ padding: '0px' }}>
                <Table stickyHeader style={{ paddingBottom: 15 }}>
                    <TableHead>
                        <TableRow
                            style={{
                                display: 'flex',
                                marginTop: -25,
                                paddingBottom: 15,
                                width: '40%',
                                marginLeft: 25,
                            }}
                        >
                            <SearchInput
                                value={filterText}
                                name={i18n.t('form.itemName')}
                                onChange={(value: string) => setFilterText(value)}
                                onEnter={() => ''}
                                onReset={() => setFilterText('')}
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.category?.ingredients
                            .filter(filterSearchText)
                            .map((ingredient, i) => (
                                <TableRow>
                                    <TableCell
                                        style={{
                                            borderTop:
                                                i === 0 ? '1px solid rgba(224, 224, 224, 1)' : 0,
                                        }}
                                        key={i}
                                    >
                                        <span style={{ marginLeft: 15 }}>{ingredient.name}</span>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
}
