import React from 'react';

import * as yup from 'yup';
import i18n from 'i18n-js';
import { Button, DialogActions } from '@material-ui/core';
import { Form, Formik } from 'formik';

import { regexHelper } from 'helpers/regexHelper';
import { AdminEditModel, UserData } from 'store/usersStore';
import { CustomPhoneInput, Input } from 'components/shared';
import { trimLeftAndRight } from 'helpers/helperFunctions';

interface EditAdminProps {
    user: UserData;
    editAdmin: any;
    cancel: any;
    close: any;
}

export default function EditAdminForm(props: EditAdminProps) {
    let initalFormValues: AdminEditModel = {
        id: props.user.id,
        firstName: props.user.firstName || '',
        lastName: props.user.lastName || '',
        email: props.user.email,
        phoneNumber: props.user.phoneNumber,
    };

    const validationSchema = yup.object().shape({
        firstName: yup
            .string()
            .matches(
                regexHelper.onlyLetters,
                i18n.t('form.errors.lettersOnly', {
                    name: 'First Name',
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'First Name',
                })
            )
            .nullable(),
        lastName: yup
            .string()
            .matches(
                regexHelper.onlyLetters,
                i18n.t('form.errors.lettersOnly', {
                    name: 'Last Name',
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'Last Name',
                })
            )
            .nullable(),
        email: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Email',
                })
            )
            .email(i18n.t('form.errors.email')),
        phoneNumber: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Phone Number',
                })
            )
            .matches(regexHelper.phoneNumber, i18n.t('form.errors.phoneNumber')),
    });

    const submitPromise = async (model: AdminEditModel) => {
        props.close();
        props.editAdmin(model);
    };

    return (
        <Formik
            initialValues={initalFormValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
                submitPromise({
                    ...values,
                    firstName: trimLeftAndRight(values.firstName),
                    lastName: trimLeftAndRight(values.lastName),
                }).then(() => {
                    resetForm();
                    props.cancel();
                });
            }}
        >
            {({ values, errors, setFieldValue, submitForm, dirty }) => (
                <Form>
                    <Input
                        error={errors.firstName}
                        variant="filled"
                        fullWidth={true}
                        name={`firstName`}
                        type="text"
                        label={i18n.t('form.firstName')}
                        placeholder={i18n.t('form.firstName')}
                        onBlur={(e: any) => setFieldValue('firstName', e.target.value.trim())}
                    />
                    <Input
                        error={errors.lastName}
                        variant="filled"
                        fullWidth={true}
                        name={`lastName`}
                        type="text"
                        label={i18n.t('form.lastName')}
                        placeholder={i18n.t('form.lastName')}
                        onBlur={(e: any) => setFieldValue('lastName', e.target.value.trim())}
                    />
                    <Input
                        error={errors.email}
                        variant="filled"
                        fullWidth={true}
                        name={`email`}
                        type="text"
                        label={i18n.t('form.email')}
                        placeholder={i18n.t('form.email')}
                    />
                    <CustomPhoneInput
                        containerStyle={{
                            marginTop: '0px',
                        }}
                        invalid={errors.phoneNumber}
                        placeholder={i18n.t('form.phoneNumber')}
                        value={values.phoneNumber}
                        onChange={(val) => setFieldValue('phoneNumber', val)}
                    />
                    <DialogActions>
                        <Button type="button" color="primary" onClick={props.cancel}>
                            {i18n.t('button.cancel')}
                        </Button>
                        <Button
                            disabled={!dirty}
                            onClick={submitForm}
                            variant="contained"
                            color="primary"
                        >
                            {i18n.t('button.save')}
                        </Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}
