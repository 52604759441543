import { Action, Reducer } from 'redux';

import { AppThunkAction } from './index';
import agent from '../api/agent';
import { actionCreators as globalActions, GlobalAction } from './globalStore';

export interface FinanceDishData {
    amount: number;
    name: string;
    number: number;
    price: number;
}
export interface FinanceServicesData {
    dishes: FinanceDishData[];
    name: string;
    totalAmount: number;
    cancelledDishes: FinanceDishData[];
}

export interface FinanceData {
    amount: number;
    name: string;
    services: FinanceServicesData[];
}

export interface FinanceOrderData {
    id: string;
    createDate: string;
    service: string;
    cancelled?: boolean;
    totalAmount: number;
}

//ACTIONS
interface ShowFinanceDetails {
    type: 'SELECT_FINANCE_ITEM';
    payload: any;
}

interface GetFinanceDetailsStart {
    type: 'GET_FINANCE_DETAILS_START';
}

interface GetFinanceDetailsSuccess {
    type: 'GET_FINANCE_DETAILS_SUCCESS';
    categories: FinanceData[];
    orders: FinanceOrderData[];
}

interface GetFinanceDetailsError {
    type: 'GET_FINANCE_DETAILS_ERROR';
}

//ACTION TYPES
type FinanceAction =
    | ShowFinanceDetails
    | GetFinanceDetailsSuccess
    | GetFinanceDetailsError
    | GetFinanceDetailsStart
    | GlobalAction;

//ACTION CREATORS
export const actionCreators = {
    selectFinanceItem:
        (payload: any): AppThunkAction<FinanceAction> =>
        (dispatch) => {
            dispatch({
                type: 'SELECT_FINANCE_ITEM',
                payload,
            });
        },
    getFinance:
        (dateFrom: any, dateTo: any, restaurantId: string = ''): AppThunkAction<FinanceAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_FINANCE_DETAILS_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Restaurants.GetFinance(dateFrom, dateTo, restaurantId)
                .then((res) => {
                    dispatch({
                        type: 'GET_FINANCE_DETAILS_SUCCESS',
                        categories: res.categories,
                        orders: res.orders,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_FINANCE_DETAILS_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
};

//STATE
export interface FinanceState {
    financeItem: null | FinanceData;
    financeDetails: null | FinanceData[];
    financeOrders: FinanceOrderData[];
}

//REDUCER
const initialState: FinanceState = {
    financeItem: null,
    financeDetails: null,
    financeOrders: [],
};

export const reducer: Reducer<FinanceState> = (
    state: FinanceState | undefined,
    incomingAction: Action
): FinanceState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as FinanceAction;
    switch (action.type) {
        case 'SELECT_FINANCE_ITEM':
            return {
                ...state,
                financeItem: action.payload,
            };
        case 'GET_FINANCE_DETAILS_SUCCESS': {
            let updatedFinanceItem = null;
            if (state.financeItem) {
                updatedFinanceItem = action.categories.find(
                    (eachItem) => eachItem.name === state.financeItem?.name
                );
                updatedFinanceItem = !updatedFinanceItem ? null : updatedFinanceItem;
            }
            return {
                ...state,
                financeDetails: action.categories,
                financeOrders: action.orders,
                financeItem: updatedFinanceItem,
            };
        }
        default:
            return state;
    }
};
