import i18n from 'i18n-js';

import { FinanceData, FinanceOrderData } from 'store/financeStore';
import { DateHistoryData } from './index';
import { helperFunctions } from '../../../helpers';

const faker = require('faker');

export function getFinanceTotalAmount(categories: FinanceData[] | null) {
    let amount = 0;
    if (categories) {
        amount = categories.reduce((a: number, b: FinanceData) => a + b.amount, 0);
    }
    return helperFunctions.formatNumber(amount);
}

export function formatDateForCheck(date: string) {
    const jsDate = new Date(date);
    return `${jsDate.getDate()}.${jsDate.getMonth()}`;
}

export function getDayAgo(filterNumber: number) {
    let dayAgo = 1;
    const now = new Date();
    switch (filterNumber) {
        case 2:
            let dayNumber = now.getDay() - 1;
            dayNumber = dayNumber === -1 ? 6 : dayNumber;
            dayAgo = dayNumber;
            break;
        case 3:
            dayAgo = now.getDate() - 1;
            break;
        case 4:
            const start = new Date(now.getFullYear(), 0, 0);
            const diff =
                // @ts-ignore
                now - start + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
            const oneDay = 1000 * 60 * 60 * 24;
            const day = Math.floor(diff / oneDay);
            dayAgo = day - 1;
    }

    return dayAgo;
}

export function getSelectedDateString(dayAgo: number) {
    const dateFrom = new Date();
    const dateTo = new Date();
    dateTo.setDate(dateFrom.getDate() - dayAgo);

    const dateToDay = dateTo.getDate();
    const dateToMonth = dateTo.getMonth();

    if (dayAgo !== 0) {
        const dateFromDay = dateFrom.getDate();
        const dateFromMonth = dateFrom.getMonth();

        return `${dateToDay} ${i18n.t(
            'common.monthNames.' + dateToMonth
        )} - ${dateFromDay} ${i18n.t('common.monthNames.' + dateFromMonth)} `;
    } else {
        return `${dateToDay} ${i18n.t('common.monthNames.' + dateToMonth)}`;
    }
}

export function getChartLabels(
    dateFilter: number,
    secondaryDateFilter: number,
    selectedDatesHistory: DateHistoryData[]
) {
    const labels: { name: string; date: Date; endDate?: undefined | Date }[] = [];

    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentMonthDay = currentDate.getDate();

    let lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
    ).getDate();
    let isCustom: boolean | undefined = false;

    let lastHistoryDate, lastHistoryEndDate;
    const selectedDatesHistoryLength = selectedDatesHistory.length;

    if (selectedDatesHistory[selectedDatesHistoryLength - 1]) {
        isCustom = selectedDatesHistory[selectedDatesHistoryLength - 1].isCustom;
        lastHistoryDate = new Date(selectedDatesHistory[selectedDatesHistoryLength - 1].startDate);
        lastHistoryEndDate = selectedDatesHistory[selectedDatesHistoryLength - 1].endDate;
        currentYear = lastHistoryDate.getFullYear();

        lastDayOfMonth = new Date(
            currentDate.getFullYear(),
            lastHistoryDate.getMonth() + 1,
            0
        ).getDate();
    }

    switch (dateFilter) {
        case 1:
            for (let i = 1; i <= 24; i++) {
                labels.push({
                    name: `${i}h`,
                    date: new Date(
                        currentYear,
                        currentMonth,
                        lastHistoryDate ? lastHistoryDate.getDate() : currentMonthDay,
                        i
                    ),
                });
            }
            break;

        case 2:
            const d = new Date(currentDate);
            const day = d.getDay(),
                diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
            let startingDay = lastHistoryDate
                ? lastHistoryDate.getDate()
                : new Date(d.setDate(diff)).getDate();
            // @ts-ignore
            const week = Math.ceil(lastHistoryDate.getDate() / 7);
            // @ts-ignore
            if (week >= 5 || lastHistoryDate.getDay() === 0) {
                // @ts-ignore
                startingDay += getExtraDaysUntil(lastHistoryDate.getDay(), 1);
            }

            let count = 0;
            for (let i = 0; i <= 6; i++) {
                let endDay = startingDay + i + 7;
                endDay = endDay > 31 ? 31 : endDay;

                let lastStartingDay = startingDay + i;
                let extraMonth = 0;
                if (lastStartingDay > lastDayOfMonth) {
                    lastStartingDay = lastStartingDay - lastDayOfMonth;
                    extraMonth = 1;
                }

                const endDate = new Date(
                    lastHistoryDate ? lastHistoryDate.getFullYear() : currentYear,
                    lastHistoryDate ? lastHistoryDate.getMonth() : currentMonth,
                    endDay
                );

                const date = new Date(
                    currentYear,
                    lastHistoryDate ? lastHistoryDate.getMonth() + extraMonth : currentMonth,
                    lastStartingDay
                );
                if (endDate.getDay() === 1 && week === 1 && count > 0) {
                    break;
                }
                labels.push({
                    name:
                        lastStartingDay +
                        ' ' +
                        i18n.t(`common.weekDays.${date.getDay().toString()}`).substr(0, 3),
                    date,
                    endDate,
                });

                if (date.getDate() >= lastDayOfMonth) {
                    break;
                }
                count += 1;
            }

            break;

        case 3:
            if (secondaryDateFilter === 0) {
                if (isCustom && selectedDatesHistoryLength) {
                    let year = lastHistoryDate?.getFullYear() || 0;
                    let startDate = lastHistoryDate?.getDate() || 0;
                    let startMonth = lastHistoryDate?.getMonth() || 0;
                    const endDate = lastHistoryEndDate?.getDate() || 0;
                    let lastDayOfMonth = new Date(year, startMonth + 1, 0).getDate();

                    for (let i = 1; i <= 32; i++) {
                        labels.push({
                            name: `${startDate}`,
                            date: new Date(year, startMonth, startDate),
                            endDate: new Date(year, startMonth, startDate + 1),
                        });

                        startDate++;
                        if (startDate > lastDayOfMonth) {
                            startDate = 1;
                            startMonth += 1;
                            if (startMonth > 11) {
                                startMonth = 1;
                                year += 1;
                            }
                        }

                        if (
                            endDate + 1 === startDate &&
                            startMonth === lastHistoryEndDate?.getMonth()
                        ) {
                            break;
                        }
                    }
                } else {
                    for (let i = 1; i <= lastDayOfMonth; i++) {
                        labels.push({
                            name: `${i}`,
                            date: new Date(currentYear, currentMonth, i),
                            endDate: new Date(currentYear, currentMonth, i + 1),
                        });
                    }
                }
            } else {
                const date = lastHistoryDate ? lastHistoryDate : new Date();
                let startDay = 1,
                    endDay = getDaysUntilMonday(date);
                for (let i = 1; i <= getWeeksAmountOfMonth(date); i++) {
                    if (i > 1) {
                        startDay = endDay;
                        endDay += 7;
                        endDay = endDay > lastDayOfMonth ? lastDayOfMonth : endDay;
                    }
                    labels.push({
                        name: `${i} week`,
                        date: new Date(
                            lastHistoryDate ? lastHistoryDate.getFullYear() : currentYear,
                            lastHistoryDate ? lastHistoryDate.getMonth() : currentMonth,
                            startDay
                        ),
                        endDate: new Date(
                            lastHistoryDate ? lastHistoryDate.getFullYear() : currentYear,
                            lastHistoryDate ? lastHistoryDate.getMonth() : currentMonth,
                            endDay
                        ),
                    });
                }
            }
            break;
        case 4:
            if (isCustom && selectedDatesHistoryLength) {
                let year = lastHistoryDate?.getFullYear() || 0;
                const startMonth = lastHistoryDate?.getMonth() || 0;
                const endMonth = lastHistoryEndDate?.getMonth();
                let reset = false,
                    monthIndex: number = 0;

                for (let i = 0; i <= 11; i++) {
                    if (reset) {
                        monthIndex += 1;
                    } else {
                        monthIndex = startMonth + i;
                    }

                    labels.push({
                        name: i18n.t(`common.monthNames.${monthIndex.toString()}`),
                        date: new Date(year, monthIndex, 1),
                        endDate: new Date(year, monthIndex, 1),
                    });

                    if (monthIndex === 11) {
                        reset = true;
                        monthIndex = -1;
                        year += 1;
                    }

                    if (monthIndex === endMonth) {
                        break;
                    }
                }
            } else {
                for (let i = 0; i <= 11; i++) {
                    let lastDayOfMonth = new Date(currentYear, i + 1, 0).getDate();
                    labels.push({
                        name: i18n.t(`common.monthNames.${i.toString()}`),
                        date: new Date(currentYear, i, 1),
                        endDate: new Date(currentYear, i, lastDayOfMonth),
                    });
                }
            }
            break;
    }

    return labels;
}

export function getChartDataSet(
    selectedServices: boolean[],
    labels: { name: string; date: Date; endDate?: Date }[],
    financeOrders: FinanceOrderData[],
    selectedFilter: number,
    selectedSecondaryFilter: number,
    showFakeData: boolean
) {
    const dataSet: { label: string; backgroundColor: string; data: any[]; minBarLength: number }[] =
        [];

    function filterDataByDate(each: FinanceOrderData, dateOfLabel: Date, endDateOfLabel: Date) {
        const eachDate = normalizeServerDate(each.createDate);
        switch (selectedFilter) {
            case 1: {
                return (
                    dateOfLabel.getHours() === eachDate.getHours() &&
                    dateOfLabel.getDate() === eachDate.getDate()
                );
            }
            case 2: {
                return dateOfLabel.getDate() === eachDate.getDate();
            }
            case 3: {
                if (selectedSecondaryFilter === 0) {
                    return (
                        dateOfLabel.getMonth() === eachDate.getMonth() &&
                        dateOfLabel.getDate() === eachDate.getDate()
                    );
                } else {
                    return (
                        eachDate.getTime() >= dateOfLabel.getTime() &&
                        eachDate.getTime() <= endDateOfLabel.getTime()
                    );
                }
            }
            case 4: {
                return (
                    dateOfLabel.getFullYear() === eachDate.getFullYear() &&
                    dateOfLabel.getMonth() === eachDate.getMonth()
                );
            }
        }
    }

    selectedServices.forEach((isSelected, i) => {
        let name = '',
            backgroundColor = '',
            serviceName = '';
        switch (i) {
            case 0:
                serviceName = 'HomeDelivery';
                name = 'form.homeDelivery';
                backgroundColor = '#92C2EE';
                break;
            case 1:
                serviceName = 'TakeAway';
                name = 'form.takeAway';
                backgroundColor = '#FFA899';
                break;
            case 2:
                serviceName = 'Vorort';
                name = 'form.jamesVorort';
                backgroundColor = '#C1C0ED';
                break;
            case 3:
                serviceName = 'cancelled';
                name = 'form.cancelled';
                backgroundColor = '#818181';
                break;
        }

        if (isSelected) {
            const dataSetData: number[] = [];
            labels.forEach((item) => {
                const dateOfLabel = item.date;
                const endDateOfLabel = item.endDate || new Date();
                let filteredFinanceOrders = financeOrders
                    .filter((each) => each.service === serviceName)
                    .filter((financeItem) =>
                        filterDataByDate(financeItem, dateOfLabel, endDateOfLabel)
                    );
                if (i === 3) {
                    filteredFinanceOrders = financeOrders
                        .filter((each) => each.cancelled === true)
                        .filter((financeItem) =>
                            filterDataByDate(financeItem, dateOfLabel, endDateOfLabel)
                        );
                }

                let totalAmount = 0;
                filteredFinanceOrders.forEach((each) => {
                    if (i === 3 || !each.cancelled) {
                        totalAmount += each.totalAmount;
                    }
                });

                dataSetData.push(totalAmount);
            });

            dataSet.push({
                label: i18n.t(name),
                backgroundColor,
                data: !showFakeData
                    ? dataSetData
                    : labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                minBarLength: 15,
            });
        } else {
            dataSet.push({
                label: i18n.t(name),
                backgroundColor,
                data: !showFakeData
                    ? []
                    : labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                minBarLength: 15,
            });
        }
    });
    return dataSet;
}

export function isChartStacked(dateFilter: number, secondaryDateFilter: number) {
    switch (dateFilter) {
        case 3: {
            return secondaryDateFilter !== 0;
        }
        case 4:
            return false;
        default:
            return true;
    }
}

export function createDateHistoryList(until: number, date: Date, weekCount: number = 1) {
    const dateHistoryList = [];

    const year = date.getFullYear();
    const month = date.getMonth();
    const monthDay = date.getDate();

    let startDate, endDate;

    for (let i = 1; i <= until; i++) {
        let name = '';
        switch (i) {
            case 1:
                name = `${year}`;
                startDate = new Date(year, 0, 1);
                endDate = new Date(year + 1, 0, 1);
                break;
            case 2:
                name = `${i18n.t('common.monthNames.' + month)}`;
                startDate = new Date(year, month);
                endDate = new Date(year, month + 1);
                break;
            case 3:
                name = `${weekCount} ${i18n.t('common.week')}`;
                startDate = new Date(year, month, monthDay);
                endDate = new Date(year, month, monthDay + 7);
                break;
            case 4:
                name = `${monthDay} ${i18n.t('common.monthNames.' + month)} ${year}`;
                startDate = new Date(year, month, monthDay);
                endDate = new Date(year, month, monthDay + 1);
        }

        dateHistoryList.push({
            name,
            startDate,
            endDate,
        });
    }

    return dateHistoryList;
}

function getDaysUntilMonday(date: Date): number {
    let dayNumber = date.getDay();
    dayNumber = dayNumber === 0 ? 7 : dayNumber;
    return 8 - dayNumber;
}

function getWeeksAmountOfMonth(date: Date) {
    const firstDay = new Date(date.setDate(1)).getDay() - 1;
    const totalDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    return Math.ceil((firstDay + totalDays) / 7);
}

/* function getNextMonday(d: Date) {
    d.setDate(d.getDate() + ((((7 - d.getDay()) % 7) + 1) % 7));
    return d;
} */

function normalizeServerDate(serverDate: string) {
    const parts = serverDate.split('-');
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1;
    const day = parseInt(parts[2].substr(0, 2));
    const hourParts = serverDate.split(':');
    const hours = parseInt(hourParts[0].substr(-2));
    const minutes = parseInt(hourParts[1]);
    return new Date(year, month, day, hours, minutes);
}

/* function getWeekOfMonth(date: Date) {
    var day = date.getDate();
    day -= date.getDay() == 0 ? 6 : date.getDay() - 1; //get monday of this week
    //special case handling for 0 (sunday)

    day += 7;
    //for the first non full week the value was negative

    const prefixes = ['0', '1', '2', '3', '4', '5'];
    return parseInt(prefixes[0 | (day / 7)]) + 1;
} */

export function getExtraDaysUntil(currentDayNumber: number, untilDayNumber: number) {
    let extraDays = 0;
    for (let i = 0; i < 8; i++) {
        if (currentDayNumber === 6) {
            currentDayNumber = 0;
        } else {
            currentDayNumber++;
        }
        extraDays++;

        if (untilDayNumber === currentDayNumber) {
            break;
        }
    }
    return extraDays;
}
