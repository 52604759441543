import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    alpha,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import i18n from 'i18n-js';

import { ApplicationState } from 'store';
import { actionCreators as globalActions } from 'store/globalStore';

import commonStyles from 'config/commonStyles';
import { actionCreators as ingredientsActions } from 'store/ingredientsStore';
import { IngredientModel } from 'api/agent';
import IngredientItem from './IngredientItem';
import { MultiSelectInputDropdown, PopupConfirmation, SearchInput } from '../index';
import { Roles } from 'constants/enums';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        root: {
            width: '100%',
            '& .MuiTableCell-root:first-child': {
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
            },
            '& .MuiTableCell-root:last-child': {
                borderRight: '1px solid rgba(224, 224, 224, 1)',
            },
        },
        tableHead: {
            borderLeft: '0px solid white!important',
            borderRight: '0px solid white!important',
        },
        rightContainer: {
            width: '49%',
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '10px',
            height: 475,
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        titleText: {
            fontSize: 16,
            fontWeight: '500',
        },
        optionText: {
            fontSize: 14,
            color: alpha(theme.palette.common.black, 0.6),
        },
        selectedCategoryIngredient: {
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
    })
);

interface Props {
    isVisible: boolean;
    onClose: () => void;
    onSave: (values: IngredientModel[]) => void;
    defaultIngredients?: IngredientModel[];
}

const AddIngredientsModal = ({ isVisible, onClose, onSave, defaultIngredients = [] }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const ingredients = useSelector((state: ApplicationState) => state.ingredients.ingredients);
    const categories = useSelector((state: ApplicationState) => state.ingredients.categories);
    const allergens = useSelector((state: ApplicationState) => state.ingredients.allergies);
    const lang = useSelector((state: ApplicationState) => state.global.language);
    const user = useSelector((state: ApplicationState) => state.user);
    const allowToEdit = user.role === Roles.OWNER;

    const realDefaultIngredients = useRef([...defaultIngredients]).current;

    const [ingredientFilterText, setIngredientFilterText] = useState('');
    const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
    const [selectedIngredients, setSelectedIngredients] =
        useState<IngredientModel[]>(defaultIngredients);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [showEditingConfirmation, setShowEditingConfirmation] = useState(false);
    const [editingChecked, setEditingChecked] = useState(false);

    useEffect(() => {
        dispatch(ingredientsActions.getIngredients(lang, 0, 250, true));

        if (!categories.length) {
            dispatch(ingredientsActions.getCategories(lang, 0, 250));
        }
    }, []);

    function filterIngredients(ingredient: { name: string; categoryId: string }) {
        const categoryId = ingredient.categoryId;
        if (categoryFilters.length > 0) {
            if (ingredientFilterText.length > 0) {
                return (
                    ingredient.name.toLowerCase().includes(ingredientFilterText) &&
                    categoryFilters.includes(categoryId)
                );
            }
            return categoryFilters.includes(categoryId);
        }
        if (ingredient.name.toLowerCase().includes(ingredientFilterText)) {
            return true;
        }
    }

    function getCategoryNameOfIngredient(ingredient: { categoryId: string }) {
        const category = categories.find((each) => each.id === ingredient.categoryId);
        return category?.name ?? '';
    }

    function getAllergenIcons(ingredient: IngredientModel) {
        return ingredient.allergens.map((id: string) => {
            const allergen = allergens.find((each) => each.id === id);
            return (
                <img
                    src={allergen?.icon}
                    alt={allergen?.name}
                    style={{ width: 15, marginLeft: 7 }}
                />
            );
        });
    }

    function addOrRemoveIngredient(newIngredient: IngredientModel) {
        if (defaultIngredients.length > 0 && !editingChecked) {
            return setShowEditingConfirmation(true);
        }
        const hasIngredient = selectedIngredients.find((each) => each.id === newIngredient.id);
        if (hasIngredient) {
            const newIngredients = selectedIngredients.filter(
                (each) => each.id !== newIngredient.id
            );
            setSelectedIngredients(newIngredients);
        } else {
            setSelectedIngredients((prev) => [
                ...prev,
                {
                    ...newIngredient,
                    isAddition: false,
                    isRemoval: false,
                    state: 0,
                },
            ]);
        }
    }

    function updateIngredient(newIngredient: IngredientModel) {
        const index = selectedIngredients.findIndex((each) => each.id === newIngredient.id);
        setSelectedIngredients((prev) => {
            prev[index] = newIngredient;
            return [...prev];
        });
        if (defaultIngredients.length > 0 && !editingChecked) {
            return setShowEditingConfirmation(true);
        }
    }

    function hasSelectedCategory(ingredient: IngredientModel) {
        return categoryFilters.includes(ingredient.categoryId);
    }

    function hasChanged() {
        return JSON.stringify(realDefaultIngredients) !== JSON.stringify(selectedIngredients);
    }

    return (
        <Dialog open={isVisible} maxWidth={'md'}>
            <div className={classes.flexRowSpaceBetween}>
                <DialogTitle>
                    {defaultIngredients?.length
                        ? i18n.t('restaurant.editIngredients')
                        : i18n.t('restaurant.addIngredients')}
                </DialogTitle>
                <div className={`${classes.flexRow} ${classes.mRight20}`}>
                    <Button
                        style={{ width: 136 }}
                        className={`${classes.mRight20}`}
                        onClick={() => {
                            if (hasChanged() && user.role !== Roles.EMPLOYEE) {
                                return setShowCancelConfirmation(true);
                            }
                            onClose();
                        }}
                        variant={'text'}
                        color={'primary'}
                    >
                        {i18n.t('button.close')}
                    </Button>
                    <Button
                        style={{ width: 136 }}
                        onClick={() => {
                            onSave(selectedIngredients);
                            dispatch(
                                globalActions.showToaster(
                                    'info',
                                    i18n.t('restaurant.yourChangesHaveBeenSaved')
                                )
                            );
                        }}
                        variant={'contained'}
                        color={'primary'}
                        disabled={!allowToEdit || !hasChanged()}
                    >
                        {i18n.t('button.save')}
                    </Button>
                </div>
            </div>

            <DialogContent style={{ height: 545 }}>
                <div className={classes.flexRow}>
                    <div style={{ width: '50%' }}>
                        <TableContainer id="container" style={{ height: 500, overflowX: 'hidden' }}>
                            <Table className={classes.root} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            classes={{ root: classes.tableHead }}
                                            style={{ width: '35%' }}
                                        >
                                            <SearchInput
                                                fontSize={15}
                                                name={i18n.t('form.itemName')}
                                                onChange={(val) => setIngredientFilterText(val)}
                                                value={ingredientFilterText}
                                                onEnter={() => ''}
                                                onReset={() => setIngredientFilterText('')}
                                            />
                                        </TableCell>
                                        <TableCell style={{ width: '30%' }} />
                                        <TableCell
                                            classes={{ root: classes.tableHead }}
                                            style={{ width: '40%' }}
                                        >
                                            <MultiSelectInputDropdown
                                                options={categories}
                                                inputLabel={i18n.t('common.category')}
                                                onChange={(values) =>
                                                    setCategoryFilters([
                                                        ...values.map((val) => val.id),
                                                    ])
                                                }
                                                trimLength={15}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ingredients
                                        .filter(filterIngredients)
                                        .sort(function (a, b) {
                                            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                                return -1;
                                            }
                                            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                                return 1;
                                            }
                                            return 0;
                                        })
                                        .map((ingredient, i) => (
                                            <TableRow
                                                style={{ height: 42 }}
                                                key={i}
                                                onClick={() => addOrRemoveIngredient(ingredient)}
                                                className={`${
                                                    hasSelectedCategory(ingredient) &&
                                                    classes.selectedCategoryIngredient
                                                }`}
                                            >
                                                <TableCell>
                                                    <span className={classes.flexRow}>
                                                        {ingredient.name}
                                                        {getAllergenIcons(ingredient)}
                                                    </span>
                                                </TableCell>
                                                <TableCell />
                                                <TableCell>
                                                    <span>
                                                        {getCategoryNameOfIngredient(ingredient)}
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div
                        className={classes.rightContainer}
                        style={{
                            alignItems: selectedIngredients.length ? 'flex-start' : 'center',
                        }}
                    >
                        {!selectedIngredients.length ? (
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <span className={classes.titleText}>
                                    {i18n.t('restaurant.addTheIngredients')}
                                </span>
                                <br />
                                <br />
                                <span className={classes.optionText}>
                                    {i18n.t('restaurant.youCanGiveUser')}
                                </span>
                            </div>
                        ) : (
                            <div>
                                {selectedIngredients.map((each, i) => (
                                    <IngredientItem
                                        index={i}
                                        key={each.id}
                                        defaultIngredient={each}
                                        onRemove={addOrRemoveIngredient}
                                        onIngredientChange={updateIngredient}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </DialogContent>

            <PopupConfirmation
                open={showCancelConfirmation}
                close={() => {
                    setShowCancelConfirmation(false);
                }}
                action={() => {
                    setShowCancelConfirmation(false);
                    setSelectedIngredients([...realDefaultIngredients]);
                    onClose();
                }}
                title={i18n.t('warnings.cancelChanges')}
                description={i18n.t('warnings.areYouSureToCloseWithoutChanges')}
                activeBtn={i18n.t('button.yesClose')}
                basicButton
            />

            <PopupConfirmation
                open={showEditingConfirmation}
                close={() => {
                    setSelectedIngredients([]);
                    setEditingChecked(true);
                    setTimeout(() => setSelectedIngredients([...realDefaultIngredients]), 250);
                    setShowEditingConfirmation(false);
                }}
                action={() => {
                    setEditingChecked(true);
                    setShowEditingConfirmation(false);
                }}
                title={i18n.t('restaurant.editingIngredients')}
                description={i18n.t('warnings.editingIngredientsWarning')}
                activeBtn={i18n.t('common.continue')}
                basicButton
            />
        </Dialog>
    );
};

export default AddIngredientsModal;
