import React from 'react';
import { useSelector } from 'react-redux';

import i18n from 'i18n-js';

import { useStyles } from './styles';
import { dateTimeIgnoreTimezone, formatDate, formatTime } from 'helpers/datetimeHelper';
import { OrderDetails } from 'store/ordersStore';
import { ApplicationState } from 'store';
import { Text } from '../../shared';

export default function OrderTime(props: { order: OrderDetails }) {
    const classes = useStyles();
    const orders = useSelector((state: ApplicationState) => state.orders);

    return (
        <div className={`${classes.orderHeaderItem} ${classes.flexColumnSpaceBetween}`}>
            <Text
                maxLength={13}
                withoutMore
                text={i18n.t('common.timeOfOrder')}
                className={classes.orderDetailsHeadingText}
                fontSize={10}
            />
            <div
                className={classes.orderHeaderItemText}
                style={{ visibility: orders.orderDetailsId ? 'visible' : 'hidden' }}
            >
                <span>
                    {props.order.createDate
                        ? formatTime(dateTimeIgnoreTimezone(props.order.createDate))
                        : formatTime(new Date())}
                </span>
                <span>
                    {props.order.createDate
                        ? formatDate(dateTimeIgnoreTimezone(props.order.createDate))
                        : formatDate(new Date())}
                </span>
            </div>
        </div>
    );
}
