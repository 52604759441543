import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from 'store';
import { Spinner } from 'components/shared';
import OrdersListTable from './ordersList/OrdersListTable';
import { OrderDetails } from './orderDetails/OrderDetails';
import CookView from '../cook-view';
import { actionCreators as ordersStoreActions } from 'store/ordersStore';
import { useStyles } from './orderDetails/styles';
import { Positions } from 'constants/enums';

export const OrdersPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const positionOrdersLoaded = useSelector(
        (state: ApplicationState) => state.orders.positionOrdersLoaded
    );

    const user = useSelector((state: ApplicationState) => state.user);
    const currentRestaurant = user.restaurants?.filter((x) => x.id === user.currentRestaurantId)[0];
    //const isNavBarOpen = useSelector((state: ApplicationState) => state.ui.navBarOpen);

    /* const containerWidth = isNavBarOpen ? '100%' : 'calc( 100% + 187px)'; */
    const containerWidth = '100%';

    useEffect(() => {
        if (currentRestaurant?.currentPosition === Positions.COOK) {
            dispatch(
                ordersStoreActions.getPositionOrders(
                    currentRestaurant.currentPosition || Positions.WAITER
                )
            );
        }
        const layout = document.querySelector('.MuiContainer-root.MuiContainer-maxWidthLg');
        const defaultStyle = (layout as HTMLElement).style.backgroundColor;
        if (!!layout) {
            (layout as HTMLElement).style.backgroundColor = 'transparent';
        }

        return () => {
            (layout as HTMLElement).style.backgroundColor = defaultStyle;
        };
    }, [currentRestaurant]); // eslint-disable-line

    if (currentRestaurant?.currentPosition === Positions.COOK) {
        if (!positionOrdersLoaded) {
            return <Spinner />;
        }

        return <CookView />;
    }

    return (
        <div
            className={classes.ordersPage}
            style={{
                width: containerWidth,
            }}
        >
            <div style={{ width: '30%', minWidth: '30%' }}>
                <OrdersListTable />
            </div>
            <OrderDetails />
        </div>
    );
};
