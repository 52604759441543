import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import i18n from 'i18n-js';

import agent, { UserFilterModel, UsersToRestaurantUserModel } from 'api/agent';
import { RestaurantData } from './restaurantsStore';
import { RoleData } from './rolesStore';
import { actionCreators as globalActions, GlobalAction } from './globalStore';
import { Positions } from 'constants/enums';

//STATE
export interface UsersState {
    users: UserData[];
    count: number;
}

export interface UserData {
    id: string;
    firstName: string;
    lastName: string;
    restaurants: RestaurantUserData[];
    email: string;
    phoneNumber: string;
    roles: RoleData[];
    isEnabled: boolean;
}

export interface UserPagedModel {
    list: UserData[];
    count: number;
}

export interface AdminCreateModel {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface RestaurantUserCreateModel {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    restaurantId: string;
    restaurantName: string;
    isContactPerson: boolean;
    positions: string[];
    isOwner: boolean;
}

export interface AdminEditModel {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface RestaurantUserEditModel {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    restaurant: RestaurantData;
    positions: string[];
    isContactPerson: boolean;
    isOwner: boolean;
}

export interface RestaurantUserData {
    restaurantId: string;
    restaurantName: string;
    positions: string[];
    isContactPerson: boolean;
}

//ACTIONS
interface UsersGetStartAction {
    type: 'USERS_GET_START';
}

interface UsersGetSuccessAction {
    type: 'USERS_GET_SUCCESS';
    users: UserPagedModel;
}

interface UsersGetErrorAction {
    type: 'USERS_GET_ERROR';
}

interface UsersCreateAdminStartAction {
    type: 'USERS_CREATE_ADMIN_START';
}

interface UsersCreateAdminSuccessAction {
    type: 'USERS_CREATE_ADMIN_SUCCESS';
    user: UserData;
}

interface UsersCreateAdminErrorAction {
    type: 'USERS_CREATE_ADMIN_ERROR';
}

interface UsersCreateRestaurantUserStartAction {
    type: 'USERS_CREATE_RESTAURANT_USER_START';
}

interface UsersCreateRestaurantUserSuccessAction {
    type: 'USERS_CREATE_RESTAURANT_USER_SUCCESS';
    user: UserData;
}

interface UsersCreateRestaurantUserErrorAction {
    type: 'USERS_CREATE_RESTAURANT_USER_ERROR';
}

interface UsersCreateContactPersonStartAction {
    type: 'USERS_CREATE_CONTACT_PERSON_START';
}

interface UsersCreateContactPersonSuccessAction {
    type: 'USERS_CREATE_CONTACT_PERSON_SUCCESS';
    user: UserData;
}

interface UsersCreateContactPersonErrorAction {
    type: 'USERS_CREATE_CONTACT_PERSON_ERROR';
}

interface UserEnableStartAction {
    type: 'USER_ENABLE_START';
}

interface UserEnableSuccessAction {
    type: 'USER_ENABLE_SUCCESS';
    users: UserData[];
}

interface UserEnableErrorAction {
    type: 'USER_ENABLE_ERROR';
}

interface UserDisableStartAction {
    type: 'USER_DISABLE_START';
}

interface UserDisableSuccessAction {
    type: 'USER_DISABLE_SUCCESS';
    users: UserData[];
}

interface UserDisableErrorAction {
    type: 'USER_DISABLE_ERROR';
}

interface UsersEditAdminStartAction {
    type: 'USERS_EDIT_ADMIN_START';
}

interface UsersEditAdminSuccessAction {
    type: 'USERS_EDIT_ADMIN_SUCCESS';
    user: UserData;
}

interface UsersEditAdminErrorAction {
    type: 'USERS_EDIT_ADMIN_ERROR';
}

interface UsersEditRestaurantUserStartAction {
    type: 'USERS_EDIT_RESTAURANT_USER_START';
}

interface UsersEditRestaurantUserSuccessAction {
    type: 'USERS_EDIT_RESTAURANT_USER_SUCCESS';
    user: UserData;
}

interface UsersEditRestaurantUserErrorAction {
    type: 'USERS_EDIT_RESTAURANT_USER_ERROR';
}

interface FromUsersToRestaurantUsersStartAction {
    type: 'FROM_USERS_TO_RESTAURANT_USERS_START';
}

interface FromUsersToRestaurantUsersSuccessAction {
    type: 'FROM_USERS_TO_RESTAURANT_USERS_SUCCESS';
    data: UserData[];
}

interface FromUsersToRestaurantUsersErrorAction {
    type: 'FROM_USERS_TO_RESTAURANT_USERS_ERROR';
}

//ACTION TYPES
export type UsersAction =
    | UsersGetStartAction
    | UsersGetSuccessAction
    | UsersGetErrorAction
    | UsersCreateAdminStartAction
    | UsersCreateAdminSuccessAction
    | UsersCreateAdminErrorAction
    | UsersCreateRestaurantUserStartAction
    | UsersCreateRestaurantUserSuccessAction
    | UsersCreateRestaurantUserErrorAction
    | UsersCreateContactPersonStartAction
    | UsersCreateContactPersonSuccessAction
    | UsersCreateContactPersonErrorAction
    | UserEnableStartAction
    | UserEnableSuccessAction
    | UserEnableErrorAction
    | UserDisableStartAction
    | UserDisableSuccessAction
    | UserDisableErrorAction
    | UsersEditAdminStartAction
    | UsersEditAdminSuccessAction
    | UsersEditAdminErrorAction
    | UsersEditRestaurantUserStartAction
    | UsersEditRestaurantUserSuccessAction
    | UsersEditRestaurantUserErrorAction
    | FromUsersToRestaurantUsersStartAction
    | FromUsersToRestaurantUsersSuccessAction
    | FromUsersToRestaurantUsersErrorAction
    | GlobalAction;

//ACTION CREATORS
export const actionCreators = {
    getUsers:
        (
            restaurantFilterModel: UserFilterModel | null = null,
            page?: number,
            limit?: number,
            asc?: boolean,
            orderBy?: string
        ): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_GET_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Users.GetList(restaurantFilterModel, page, limit, asc, orderBy)
                .then((response) => dispatch({ type: 'USERS_GET_SUCCESS', users: response }))
                .catch((e) => {
                    dispatch({ type: 'USERS_GET_ERROR' });
                    globalActions.showToaster('error', e);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    getRestaurantUsers:
        (
            restaurantFilterModel: UserFilterModel | null = null,
            page?: number,
            limit?: number,
            asc?: boolean,
            orderBy?: string
        ): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_GET_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Users.GetRestaurantUsersList(restaurantFilterModel, page, limit, asc, orderBy)
                .then((response) => dispatch({ type: 'USERS_GET_SUCCESS', users: response }))
                .catch((e) => {
                    dispatch({ type: 'USERS_GET_ERROR' });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    createAdmin:
        (model: AdminCreateModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_CREATE_ADMIN_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Users.CreateAdmin(model)
                .then((response) => {
                    dispatch({ type: 'USERS_CREATE_ADMIN_SUCCESS', user: response });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USERS_CREATE_ADMIN_ERROR' });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    createRestaurantUser:
        (model: RestaurantUserCreateModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_CREATE_RESTAURANT_USER_START',
            });
            globalActions.showSpiner()(dispatch);
            (model.isOwner
                ? agent.Users.CreateRestaurantOwner(model)
                : agent.Users.CreateRestaurantEmployee(model)
            )
                .then((response) => {
                    dispatch({ type: 'USERS_CREATE_RESTAURANT_USER_SUCCESS', user: response });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USERS_CREATE_RESTAURANT_USER_ERROR' });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    disableUser:
        (ids: string[]): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_DISABLE_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Users.DisableUsers(ids)
                .then((response) => {
                    dispatch({ type: 'USER_DISABLE_SUCCESS', users: response });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USER_DISABLE_ERROR' });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    enableUser:
        (ids: string[]): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_ENABLE_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Users.EnableUsers(ids)
                .then((response) => {
                    dispatch({ type: 'USER_ENABLE_SUCCESS', users: response });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USER_ENABLE_ERROR' });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    editAdmin:
        (model: AdminEditModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_EDIT_ADMIN_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Users.EditAdmin(model)
                .then((response) => {
                    dispatch({ type: 'USERS_EDIT_ADMIN_SUCCESS', user: response });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USERS_EDIT_ADMIN_ERROR' });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    editRestaurantUser:
        (model: RestaurantUserEditModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_EDIT_RESTAURANT_USER_START',
            });
            globalActions.showSpiner()(dispatch);
            model.isOwner = model.positions.includes(Positions.MANAGER);
            agent.Users.EditRestaurantUser(model)
                .then((response) => {
                    dispatch({ type: 'USERS_EDIT_RESTAURANT_USER_SUCCESS', user: response });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USERS_EDIT_RESTAURANT_USER_ERROR' });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    fromUsersToRestaurantOwners:
        (model: UsersToRestaurantUserModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'FROM_USERS_TO_RESTAURANT_USERS_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Users.FromUsersToRestaurantOwners(model)
                .then((response) => {
                    dispatch({
                        type: 'FROM_USERS_TO_RESTAURANT_USERS_SUCCESS',
                        data: response,
                    });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'FROM_USERS_TO_RESTAURANT_USERS_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    fromUsersToRestaurantEmployees:
        (model: UsersToRestaurantUserModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'FROM_USERS_TO_RESTAURANT_USERS_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Users.FromUsersToRestaurantEmployees(model)
                .then((response) => {
                    dispatch({
                        type: 'FROM_USERS_TO_RESTAURANT_USERS_SUCCESS',
                        data: response,
                    });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'FROM_USERS_TO_RESTAURANT_USERS_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
};

//REDUCER
const initialState: UsersState = {
    users: [],
    count: 0,
};

export const reducer: Reducer<UsersState> = (
    state: UsersState | undefined,
    incomingAction: Action
): UsersState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as UsersAction;

    switch (action.type) {
        case 'USERS_GET_SUCCESS':
            return {
                ...state,
                users: action.users.list,
                count: action.users.count,
            };
        case 'USERS_CREATE_ADMIN_SUCCESS':
            return {
                ...state,
                users: state.users.concat(action.user),
            };
        case 'USERS_CREATE_RESTAURANT_USER_SUCCESS':
            const newUsers = [action.user, ...state.users];
            return {
                ...state,
                users: newUsers,
            };
        case 'USER_ENABLE_SUCCESS':
            return {
                ...state,
                users: state.users
                    .map((user) =>
                        action.users.some((x) => x.id === user.id)
                            ? action.users.find((x) => x.id === user.id)!
                            : user
                    )
                    .sort((a, b) => Number(b?.isEnabled) - Number(a?.isEnabled)),
            };
        case 'USER_DISABLE_SUCCESS':
            return {
                ...state,
                users: state.users
                    .map((user) =>
                        action.users.some((x) => x.id === user.id)
                            ? action.users.find((x) => x.id === user.id)!
                            : user
                    )
                    .sort((a, b) => Number(b?.isEnabled) - Number(a?.isEnabled)),
            };
        case 'USERS_EDIT_ADMIN_SUCCESS':
            return {
                ...state,
                users: state.users.map((user) => (user.id === action.user.id ? action.user : user)),
            };
        case 'USERS_EDIT_RESTAURANT_USER_SUCCESS':
            return {
                ...state,
                users: state.users.map((user) => (user.id === action.user.id ? action.user : user)),
            };
        case 'FROM_USERS_TO_RESTAURANT_USERS_SUCCESS':
            return {
                ...state,
                users: [...state.users, ...action.data],
            };
        default:
            return state;
    }
};
