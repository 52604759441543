import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';

import i18n from 'i18n-js';
import { Form, Formik, getIn } from 'formik';
import clsx from 'clsx';
import * as FormikFields from 'formik-material-ui';
import {
    Avatar,
    InputAdornment,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    TextField as MuiTextField,
} from '@material-ui/core';
import {
    Divider,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    ListItemText,
    Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';

import { images } from 'assets';
import { regexHelper } from 'helpers/regexHelper';
import {
    BasicThreeDotsMenu,
    CustomPhoneInput,
    Input,
    SectionTab,
    TimePickerModal,
    PopupConfirmation,
    UserRoleLabel,
    Text,
} from 'components/shared';
import { helperFunctions } from 'helpers';
import { CategoryDetailsData, CategoryDishData } from 'store/categoryStore';
import {
    getDayList,
    getFormattedHour,
    getInitialDates,
    getOrderPreparationDurations,
} from './functions';
import {
    getDynamicRowLength,
    renderMultipleClassNames,
    getDynamicImageSize,
    trimLeftAndRight,
} from 'helpers/helperFunctions';
import { ApplicationState } from 'store';
import * as UsersStore from 'store/usersStore';
import * as RestaurantDetailsStore from 'store/restaurantDetailsStore';
import logo from 'assets/user-logo.svg';
import { UserData } from 'store/usersStore';
import { EditRestaurantUserForm } from 'components/users/components';
import { AddRestaurantUserForm } from 'components/users/components';
import { CuisineData, RestaurantContactPerson } from 'store/restaurantDetailsStore';
import agent from 'api/agent';
import * as RestaurantStore from 'store/restaurantDetailsStore';
import { RestaurantData } from 'store/restaurantsStore';
import styles from './styles';
import { PositionsList } from 'constants/lists';
import { theme } from 'config/theme';
import { actionCreators as globalActions } from 'store/globalStore';

const defaultHours = { from: '00.00', to: '00.00' };

export interface HourData {
    from: string;
    to: string;
}

export interface AddWorkingHoursProps {
    isVisible: boolean;
    onClose: () => void;
    onSave: () => void;
    title: string;
    openHours: any;
    onHourAdd: (index: number, hours: HourData) => void;
    onHourRemove: (index: number) => void;
    onCloseChecked: (isChecked: boolean) => void;
    onSelectedDaysChange: (days: string[], checked: boolean, hours: HourData[]) => void;
}

const AddWorkingHoursModal = ({
    isVisible,
    onClose,
    title,
    onSave,
    openHours,
    onHourAdd,
    onHourRemove,
    onCloseChecked,
    onSelectedDaysChange,
}: AddWorkingHoursProps) => {
    const [render, reRender] = useState(false);
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [timePickerModalType, setTimePickerModalType] = useState(0);
    const [clickedElementRect, setClickedElementRect] = useState<any>(null);
    const [hours, setHours] = useState<{ from: string; to: string }[]>([defaultHours]);
    const [checked, setChecked] = useState(false);
    const classes = styles();

    function addMoreHours() {
        const nextIndex = hours.length;
        addWorkingHour(nextIndex, defaultHours);
    }

    function getClickedAndSet(i: number) {
        const id = `Add-Mui${i}`;
        const clickedElement = document.getElementById(id);
        const clickedElementRect = clickedElement?.getBoundingClientRect();

        setClickedElementRect(clickedElementRect);
    }

    function selectDay(dayName: string) {
        let newSelectedDays;

        if (selectedDays.includes(dayName)) {
            newSelectedDays = selectedDays.filter((day) => day !== dayName);
        } else {
            newSelectedDays = [...selectedDays, dayName];
        }
        onSelectedDaysChange(newSelectedDays, checked, hours);
        setSelectedDays(newSelectedDays);
    }

    function addWorkingHour(i: number, incomingHours: HourData) {
        const newHours = [...hours];
        newHours[i] = incomingHours;
        setHours(newHours);
        onHourAdd(i, incomingHours);
    }

    function removeWorkingHour(i: number) {
        const newHours = hours.filter((item, index) => index !== i);
        setHours(newHours);
    }

    return (
        <Dialog open={isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Typography variant="h6" align="left" className={classes.fontWeight500}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className={classes.smallModalContainer}>
                    <div
                        className={renderMultipleClassNames([
                            classes.flexRowSpaceBetween,
                            classes.mBot5P,
                        ])}
                    >
                        {getDayList(openHours).map((day) => {
                            return (
                                <div
                                    key={day.key}
                                    onClick={() => selectDay(day.key)}
                                    className={
                                        selectedDays.includes(day.key)
                                            ? classes.selectedDayContainer
                                            : classes.dayContainer
                                    }
                                >
                                    {day.name.toUpperCase()}
                                </div>
                            );
                        })}
                    </div>

                    {hours.map((workingHour: HourData, i: number) => (
                        <div key={i}>
                            {i !== 0 ? (
                                <MuiTextField
                                    className={classes.width100}
                                    id={`Add-Mui${i}`}
                                    value={
                                        workingHour ? workingHour.from + ' - ' + workingHour.to : ''
                                    }
                                    onClick={() => {
                                        setTimePickerModalType(i + 1);
                                        getClickedAndSet(i);
                                    }}
                                    variant="filled"
                                    name={`extraHours[${i}]`}
                                    type="text"
                                    label={i18n.t('common.workingHours') + ` ${i > 0 ? i + 1 : ''}`}
                                    placeholder={i18n.t('common.workingHours')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    onHourRemove(i);
                                                    removeWorkingHour(i);
                                                    reRender(!render);
                                                }}
                                            >
                                                <img
                                                    alt="remove-icon"
                                                    src={images.icons.removeCircle}
                                                    className={classes.basicHover}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            ) : (
                                <MuiTextField
                                    className={classes.width100}
                                    id={`Add-Mui${i}`}
                                    value={
                                        workingHour ? workingHour.from + ' - ' + workingHour.to : ''
                                    }
                                    onClick={() => {
                                        setTimePickerModalType(i + 1);
                                        getClickedAndSet(i);
                                    }}
                                    variant="filled"
                                    name={`extraHours[${i}]`}
                                    type="text"
                                    label={i18n.t('common.workingHours') + ` ${i > 0 ? i : ''}`}
                                    placeholder={i18n.t('common.workingHours')}
                                />
                            )}
                        </div>
                    ))}
                    <div className={classes.checkboxContainer}>
                        <Checkbox
                            color="primary"
                            onChange={(e) => {
                                const newValue = !checked;
                                setChecked(newValue);
                                onCloseChecked(newValue);
                            }}
                        />
                        <span className={classes.closedText}>{i18n.t('common.closed')}</span>
                    </div>
                    <div className={classes.flexRowSpaceBetween}>
                        <Button
                            className={classes.mTop10}
                            type="button"
                            color="primary"
                            onClick={addMoreHours}
                        >
                            {i18n.t('common.addMoreHours')}
                        </Button>
                        <div className={classes.smallModalButtonContainer}>
                            <Button
                                type="button"
                                color="primary"
                                className={classes.cancelButton}
                                onClick={() => {
                                    setSelectedDays([]);
                                    onClose();
                                }}
                            >
                                {i18n.t('button.cancel')}
                            </Button>
                            <Button
                                disabled={selectedDays.length === 0}
                                onClick={onSave}
                                className={classes.smallButton}
                                variant="contained"
                                color="primary"
                            >
                                {i18n.t(`common.save`)}
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <TimePickerModal
                initialFromHour={getInitialDates(hours[timePickerModalType - 1], 'from', 'hour')}
                initialFromMinute={getInitialDates(
                    hours[timePickerModalType - 1],
                    'from',
                    'minute'
                )}
                initialToHour={getInitialDates(hours[timePickerModalType - 1], 'to', 'hour')}
                initialToMinute={getInitialDates(hours[timePickerModalType - 1], 'to', 'minute')}
                clickedElementRect={clickedElementRect}
                isVisible={timePickerModalType > 0}
                onClose={(selectedHours) => {
                    addWorkingHour(timePickerModalType - 1, selectedHours);
                    setTimePickerModalType(0);
                }}
                onDateChange={(selectedHours) => {
                    addWorkingHour(timePickerModalType - 1, selectedHours);
                }}
            />
        </Dialog>
    );
};

interface RestaurantDetailsProps {
    restaurantInformation: any;
    formikRef: React.Ref<any>;
    onFormChange: () => void;
}

export const RestaurantDetails = ({
    restaurantInformation,
    formikRef,
    onFormChange,
}: RestaurantDetailsProps) => {
    const dispatch = useDispatch();
    const cuisineTypes = useSelector(
        (state: ApplicationState) => state.restaurantDetails.cuisineTypes
    );
    const classes = styles();
    const logoRef: any = React.useRef(null);
    const restaurantPhotoRef: any = React.useRef(null);
    const restaurantOpenHoursRef: any = React.useRef(null);
    const [initialValues, setInitialValues] = useState({
        name: restaurantInformation.name,
        addressLine: restaurantInformation.addressLine,
        zip: restaurantInformation.zip,
        city: restaurantInformation.city,
        phoneNumber: restaurantInformation.phoneNumber,
        email: restaurantInformation.email,
        description: restaurantInformation.description,
        hasHomeDelivery: restaurantInformation.hasHomeDelivery,
        hasTakeAway: restaurantInformation.hasTakeAway,
        hasOnSite: restaurantInformation.hasOnSite,
        logoUrl: restaurantInformation.logoUrl,
        photoUrl: restaurantInformation.photoUrl,
        website: restaurantInformation.website,
        facebook: restaurantInformation.facebook,
        instagram: restaurantInformation.instagram,
        workdays: restaurantInformation.workdays || [],
        saturday: restaurantInformation.saturday || [],
        sunday: restaurantInformation.sunday || [],
        openHours: restaurantInformation.openHours,
        type: restaurantInformation.type || [],
        addToGallery: restaurantInformation.addToGallery || false,
        ordersDeadline: restaurantInformation.ordersDeadline || 30,
    });
    const [showWorkingHoursModal, setShowWorkingHoursModal] = useState(false);
    const [restaurantLogo, setRestaurantLogo] = useState(images.icons.foodIcon);
    const [restaurantImage, setRestaurantImage] = useState('');
    const [render, reRender] = useState(false);
    const [selectedOpenHoursDays, setSelectedOpenHoursDays] = useState<string[]>([]);

    useEffect(() => {
        initialValues.name !== restaurantInformation.name &&
            setInitialValues({
                name: restaurantInformation.name,
                addressLine: restaurantInformation.addressLine,
                zip: restaurantInformation.zip,
                city: restaurantInformation.city,
                phoneNumber: restaurantInformation.phoneNumber,
                email: restaurantInformation.email,
                description: restaurantInformation.description,
                hasHomeDelivery: restaurantInformation.hasHomeDelivery,
                hasTakeAway: restaurantInformation.hasTakeAway,
                hasOnSite: restaurantInformation.hasOnSite,
                logoUrl: restaurantInformation.logoUrl,
                photoUrl: restaurantInformation.photoUrl,
                website: restaurantInformation.website,
                facebook: restaurantInformation.facebook,
                instagram: restaurantInformation.instagram,
                workdays: restaurantInformation.workdays || [],
                saturday: restaurantInformation.saturday || [],
                sunday: restaurantInformation.sunday || [],
                openHours: restaurantInformation.openHours,
                type: restaurantInformation.type || [],
                addToGallery: restaurantInformation.addToGallery || false,
                ordersDeadline: restaurantInformation.ordersDeadline,
            });

        const strVer = JSON.stringify(restaurantInformation.openHours);
        if (strVer && restaurantOpenHoursRef.current === null) {
            restaurantOpenHoursRef.current = JSON.parse(strVer);
        }
    }, [restaurantInformation.name]); // eslint-disable-line

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .max(
                128,
                i18n.t('form.errors.maxLength', {
                    name: 'Name',
                    length: 128,
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            ),
        addressLine: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Address line',
            })
        ),
        email: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Email',
                })
            )
            .email(i18n.t('form.errors.email')),
        phoneNumber: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Phone number',
                })
            )
            .matches(regexHelper.phoneNumber, i18n.t('form.errors.phoneNumber')),
        zip: yup
            .string()
            .max(10)
            .required(
                i18n.t('form.errors.required', {
                    name: i18n.t('paymentDetails.zip'),
                })
            ),
        city: yup.string().matches(regexHelper.city, i18n.t('form.errors.city')),
    });

    function displayWorkingHoursModal(title: string, typeName: string) {
        setShowWorkingHoursModal(true);
        // @ts-ignore
        let workingHours = initialValues[typeName];
        workingHours = workingHours.length === 0 ? [{ from: '', to: '' }] : workingHours;
        Object.keys(workingHours).forEach((workingHour: any) => {
            if (workingHours[workingHour].from.toString().length === 4) {
                workingHours[workingHour].from = '0' + workingHours[workingHour].from;
            }
            if (workingHours[workingHour].to.toString().length === 4) {
                workingHours[workingHour].to = '0' + workingHours[workingHour].to;
            }
        });
    }

    /**
     * Hides working hours modal
     * and before that cleans the empty hours to avoid validation issues for backend
     */
    function hideWorkingHoursModal(resetHours: boolean = true) {
        for (const [, value] of Object.entries(initialValues.openHours)) {
            //@ts-ignore
            value.workingHours = value.workingHours.filter((hourData) => hourData.from !== '');
        }
        resetHours &&
            setInitialValues({
                ...initialValues,
                openHours: JSON.parse(JSON.stringify(restaurantOpenHoursRef.current)),
            });
        setShowWorkingHoursModal(false);
    }

    function getImageUrl(fieldName: string, fieldValue: any) {
        if (!fieldValue || fieldValue.name) {
            switch (fieldName) {
                case 'logoUrl':
                    return restaurantLogo;
                case 'photoUrl':
                    return restaurantImage;
            }
        }
        return fieldValue;
    }

    function getImageStyle(file: any, fieldName: string) {
        if (file) {
            if (fieldName === 'logoUrl') {
                return {
                    style: {
                        ...getDynamicImageSize(96),
                        height: 96,
                        borderRadius: '100%',
                        objectFit: 'cover',
                    },
                };
            } else {
                return {
                    style: getDynamicImageSize(null, null),
                };
            }
        }
        return {
            className: fieldName === 'logoUrl' ? classes.foodLogo : classes.restaurantImage,
        };
    }

    function hasAnyService(values: any) {
        const { hasHomeDelivery, hasOnSite, hasTakeAway } = values;
        if (hasHomeDelivery || hasOnSite || hasTakeAway) {
            return true;
        }
        return false;
    }

    return (
        <div className={classes.container}>
            <AddWorkingHoursModal
                title={i18n.t('restaurant.editWorkingHours')}
                isVisible={showWorkingHoursModal}
                onClose={hideWorkingHoursModal}
                onSave={() => {
                    hideWorkingHoursModal(false);
                    onFormChange();
                }}
                onHourAdd={(index, hours) => {
                    selectedOpenHoursDays.forEach((day) => {
                        initialValues.openHours[day].workingHours[index] = hours;
                        initialValues.openHours[day].isClosed = false;
                        setInitialValues(initialValues);
                    });
                    reRender(!render);
                }}
                onHourRemove={(index) => {
                    if (selectedOpenHoursDays.length > 0) {
                        let newInitialValues: any = {};
                        selectedOpenHoursDays.forEach((day, i) => {
                            newInitialValues =
                                i === 0 ? { ...initialValues } : { ...newInitialValues };
                            newInitialValues.openHours =
                                i === 0
                                    ? { ...initialValues.openHours }
                                    : { ...newInitialValues.openHours };

                            newInitialValues.openHours[day] = {
                                workingHours: initialValues.openHours[day].workingHours.filter(
                                    (item: any, itemIndex: number) => itemIndex !== index
                                ),
                                isClosed: false,
                            };
                        });
                        setInitialValues(newInitialValues);
                    }
                }}
                onCloseChecked={(isChecked) => {
                    selectedOpenHoursDays.forEach((day) => {
                        initialValues.openHours[day].isClosed = isChecked;
                        setInitialValues(initialValues);
                    });
                    reRender(!render);
                }}
                openHours={initialValues.openHours}
                onSelectedDaysChange={(days, checked, hours) => {
                    if (checked) {
                        days.forEach((day) => {
                            initialValues.openHours[day].isClosed = checked;
                        });
                    } else {
                        days.forEach((day, dayIndex) => {
                            hours.forEach((hour, index) => {
                                initialValues.openHours[day].workingHours[index] = hour;
                                initialValues.openHours[day].isClosed = false;
                            });
                            if (initialValues.openHours[day].workingHours.length > hours.length) {
                                initialValues.openHours[day].workingHours = initialValues.openHours[
                                    day
                                ].workingHours.filter(
                                    (val: any, hIndex: number) => hIndex < hours.length
                                );
                            }
                        });
                    }
                    setSelectedOpenHoursDays(days);
                    setInitialValues(initialValues);
                }}
            />
            <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {}}
            >
                {({ values, setFieldValue, errors, setFieldError }) => (
                    <>
                        <div
                            key="first-div"
                            onChange={() => {
                                onFormChange();
                                !hasAnyService(values) && setFieldError('hasOnSite', 'required');
                            }}
                            className={classes.restaurantDetailsLeftContainer}
                        >
                            <div className={classes.flexRowContainer} key="image">
                                <div className={classes.logoContainer}>
                                    <input
                                        accept="image/*"
                                        type="file"
                                        name="logoUrl"
                                        ref={logoRef}
                                        style={{ display: 'none' }}
                                        onChange={async (e) => {
                                            const res = await helperFunctions.pickImage(e);
                                            const { file, errorMessage } = res;

                                            if (file) {
                                                const objectUrl = URL.createObjectURL(file);
                                                setRestaurantLogo(objectUrl);
                                                setFieldValue('logoUrl', file);
                                            } else {
                                                dispatch(
                                                    globalActions.showToaster('error', errorMessage)
                                                );
                                            }
                                        }}
                                    />
                                    <img
                                        alt="logo"
                                        src={getImageUrl('logoUrl', values.logoUrl)}
                                        {...getImageStyle(values.logoUrl, 'logoUrl')}
                                    />
                                    <div className={classes.uploadLogoTextContainer}>
                                        <h4
                                            style={{
                                                marginRight: i18n.locale === 'de' ? 25 : 0,
                                                marginLeft: i18n.locale === 'de' ? -40 : 0,
                                            }}
                                            className={classes.uploadLogoText}
                                            onClick={() => logoRef.current.click()}
                                        >
                                            {helperFunctions
                                                .truncateLangString(i18n.t('common.change'), 12)
                                                .toUpperCase()}
                                        </h4>
                                        <h4
                                            className={classes.uploadLogoText}
                                            onClick={() => {
                                                setFieldValue('logoUrl', '');
                                                setRestaurantLogo(images.icons.foodIcon);
                                                onFormChange();
                                            }}
                                        >
                                            {helperFunctions
                                                .truncateLangString(i18n.t('common.remove'), 10)
                                                .toUpperCase()}
                                        </h4>
                                    </div>
                                </div>
                                <div
                                    onClick={() => restaurantPhotoRef.current.click()}
                                    className={classes.restaurantPhotoContainer}
                                >
                                    <input
                                        accept="image/*"
                                        type="file"
                                        name="photoUrl"
                                        ref={restaurantPhotoRef}
                                        style={{ display: 'none' }}
                                        onChange={async (e) => {
                                            const res = await helperFunctions.pickImage(e);
                                            const { file, errorMessage } = res;
                                            if (file) {
                                                const objectUrl = URL.createObjectURL(file);
                                                setRestaurantImage(objectUrl);
                                                setFieldValue('photoUrl', file);
                                            } else {
                                                dispatch(
                                                    globalActions.showToaster('error', errorMessage)
                                                );
                                            }
                                        }}
                                    />
                                    {getImageUrl('photoUrl', values.photoUrl) !== '' ? (
                                        <img
                                            alt="icon"
                                            src={getImageUrl('photoUrl', values.photoUrl)}
                                            {...getImageStyle(values.photoUrl, 'photoUrl')}
                                        />
                                    ) : (
                                        <span className={classes.uploadLogoText}>
                                            {i18n.t('restaurant.addRestaurantPhoto').toUpperCase()}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <Form
                                className={classes.formContainer}
                                onChange={onFormChange}
                                key="form-group"
                            >
                                <div className={classes.flexRowSpaceBetween}>
                                    <h6 className={classes.sectionTitle}>
                                        {i18n.t('restaurant.restaurantInformation').toUpperCase()}
                                    </h6>
                                    <div className={classes.makePhotoMainCheckbox}>
                                        <Input
                                            component={FormikFields.CheckboxWithLabel}
                                            variant="filled"
                                            checked={values.addToGallery}
                                            name={'addToGallery'}
                                            key="addToGallery"
                                            type="checkbox"
                                            Label={{ label: i18n.t('form.addPhotoToGallery') }}
                                            placeholder={i18n.t('form.addPhotoToGallery')}
                                            color="primary"
                                        />
                                    </div>
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <Input
                                        error={errors.name}
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.restaurantName')}
                                        name="name"
                                        key="name"
                                        type="text"
                                        label={i18n.t('form.restaurantName')}
                                        variant="filled"
                                    />
                                    <Input
                                        error={errors.addressLine}
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.restaurantAddress')}
                                        name="addressLine"
                                        key="addressLine"
                                        type="text"
                                        label={i18n.t('form.restaurantAddress')}
                                        variant="filled"
                                    />
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <CustomPhoneInput
                                        width={'44.5%'}
                                        placeholder={i18n.t('form.phoneNumber')}
                                        value={values.phoneNumber}
                                        onChange={(val: string) =>
                                            setFieldValue('phoneNumber', val)
                                        }
                                        // @ts-ignore
                                        invalid={errors.phoneNumber}
                                    />
                                    <div className={classes.halfFormInputContainer}>
                                        <Input
                                            className={classes.halfFormInput}
                                            placeholder={i18n.t('form.zip')}
                                            name="zip"
                                            key="zip"
                                            type="text"
                                            label={i18n.t('form.zip')}
                                            variant="filled"
                                        />
                                        <Input
                                            className={classes.halfFormInput}
                                            placeholder={i18n.t('form.city')}
                                            name="city"
                                            key="city"
                                            type="text"
                                            label={i18n.t('form.city')}
                                            variant="filled"
                                        />
                                    </div>
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <Input
                                        error={errors.email}
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.email')}
                                        name="email"
                                        key="email"
                                        type="text"
                                        label={i18n.t('form.email')}
                                        variant="filled"
                                    />
                                    <Input
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.facebook')}
                                        name="facebook"
                                        key="facebook"
                                        value={values.facebook || ''}
                                        type="text"
                                        label={i18n.t('form.facebook')}
                                        variant="filled"
                                    />
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <Input
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.webpage')}
                                        name="website"
                                        key="website"
                                        type="text"
                                        label={i18n.t('form.webpage')}
                                        variant="filled"
                                    />
                                    <Input
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.instagram')}
                                        name="instagram"
                                        key="instagram"
                                        value={values.instagram || ''}
                                        type="text"
                                        label={i18n.t('form.instagram')}
                                        variant="filled"
                                    />
                                </div>
                                <Input
                                    as="textarea"
                                    fullWidth
                                    placeholder={i18n.t('form.detailedDescription')}
                                    name="description"
                                    key="description"
                                    type="text"
                                    value={values.description || ''}
                                    label={i18n.t('form.detailedDescription')}
                                    variant="filled"
                                    multiline
                                    minRows={getDynamicRowLength(values.description, 75)}
                                />
                            </Form>
                        </div>
                        <div
                            key="second-div"
                            className={classes.restaurantDetailsRightContainer}
                            onChange={onFormChange}
                        >
                            <h6 className={classes.sectionTitle}>
                                {i18n.t('restaurant.typeOfCuisine').toUpperCase()}
                            </h6>
                            <Autocomplete
                                options={cuisineTypes}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                classes={{
                                    option: classes.dropdownItem,
                                    listbox: classes.listBox,
                                    paper: classes.paper,
                                }}
                                renderOption={(option) => {
                                    const exists = !!values.type.find(
                                        (currentOption: CuisineData) =>
                                            currentOption.id === option.id
                                    );
                                    return (
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                onFormChange();
                                                let newValues;
                                                if (exists) {
                                                    newValues = values.type.filter(
                                                        (selectedOption: CuisineData) =>
                                                            selectedOption.id !== option.id
                                                    );
                                                } else {
                                                    newValues = [...values.type, option];
                                                }
                                                setFieldValue('type', newValues);
                                            }}
                                            className={`${classes.width100} ${classes.bottomLine} ${
                                                exists ? classes.selectedDropdownItem : ''
                                            }`}
                                        >
                                            <Checkbox color="primary" checked={exists} />
                                            <span>{option.name}</span>
                                        </div>
                                    );
                                }}
                                renderInput={(params) => (
                                    <>
                                        <MuiTextField
                                            {...params}
                                            name="type"
                                            label={
                                                !values?.type?.length
                                                    ? i18n.t('common.chooseTheType')
                                                    : `${values.type.length} ${i18n.t(
                                                          'restaurant.cuisines'
                                                      )}`
                                            }
                                            error={values.type.length === 0}
                                            variant="filled"
                                        />
                                    </>
                                )}
                            />
                            <h6 className={`${classes.sectionTitle} ${classes.mTop5P}`}>
                                {i18n.t('restaurant.maxTimeForOrder').toUpperCase()}
                            </h6>
                            <Autocomplete
                                options={getOrderPreparationDurations()}
                                getOptionLabel={(option) => option.name}
                                value={{
                                    id: values.ordersDeadline / 30,
                                    duration: values.ordersDeadline,
                                    name:
                                        values.ordersDeadline + ` ${i18n.t('restaurant.minutes')}`,
                                }}
                                getOptionSelected={(option, value) => {
                                    return option.id === value.id;
                                }}
                                classes={{
                                    option: classes.dropdownItem,
                                    listbox: classes.listBox,
                                    paper: classes.paper,
                                }}
                                onChange={(e, val) => {
                                    if (val) {
                                        setFieldValue('ordersDeadline', val?.duration);
                                        onFormChange();
                                    }
                                }}
                                renderOption={(option) => {
                                    return (
                                        <div
                                            style={{ padding: '10px' }}
                                            className={`${classes.width100} ${classes.bottomLine}`}
                                        >
                                            <span>{option.name}</span>
                                        </div>
                                    );
                                }}
                                renderInput={(params) => (
                                    <>
                                        <MuiTextField
                                            {...params}
                                            label={i18n.t('restaurant.duration')}
                                            variant="filled"
                                        />
                                    </>
                                )}
                            />

                            <h6
                                className={renderMultipleClassNames([
                                    classes.sectionTitle,
                                    classes.mTop10P,
                                ])}
                                style={{
                                    color: !hasAnyService(values)
                                        ? theme.palette.error.main
                                        : 'black',
                                }}
                            >
                                {i18n.t('form.services').toUpperCase() + ' '}
                                {!hasAnyService(values) && (
                                    <span style={{ color: theme.palette.error.main }}>
                                        - {i18n.t('form.errors.onlyRequired')}
                                    </span>
                                )}
                            </h6>
                            <div className={classes.serviceSectionContainer}>
                                <div className={classes.serviceText}>
                                    <Input
                                        component={FormikFields.CheckboxWithLabel}
                                        variant="filled"
                                        name={'hasHomeDelivery'}
                                        type="checkbox"
                                        Label={{ label: i18n.t('form.homeDelivery') }}
                                        placeholder={i18n.t('form.homeDelivery')}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                    />
                                </div>
                                <Divider className={classes.divider} />
                                <img
                                    alt="home-delivery"
                                    src={
                                        values.hasHomeDelivery
                                            ? images.icons.chartHomeDelivery
                                            : images.icons.homeDeliveryDisabled
                                    }
                                    className={classes.homeDeliveryIcon}
                                />
                            </div>
                            <div className={classes.serviceSectionContainer}>
                                <div className={classes.serviceText}>
                                    <Input
                                        component={FormikFields.CheckboxWithLabel}
                                        variant="filled"
                                        name={'hasTakeAway'}
                                        type="checkbox"
                                        Label={{ label: i18n.t('form.takeAway') }}
                                        placeholder={i18n.t('form.takeAway')}
                                        color="primary"
                                    />
                                </div>
                                <Divider className={classes.divider} />
                                <img
                                    alt="take-away"
                                    src={
                                        values.hasTakeAway
                                            ? images.icons.chartTakeAway
                                            : images.icons.takeAwayDisabled
                                    }
                                    className={classes.takeAwayIcon}
                                />
                            </div>
                            <div className={classes.serviceSectionContainer}>
                                <div className={classes.serviceText}>
                                    <Input
                                        component={FormikFields.CheckboxWithLabel}
                                        variant="filled"
                                        name={'hasOnSite'}
                                        type="checkbox"
                                        Label={{ label: i18n.t('form.jamesVorort') }}
                                        placeholder={i18n.t('form.jamesVorort')}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                    />
                                </div>
                                <Divider className={classes.divider} />
                                <img
                                    alt="on-site"
                                    src={
                                        values.hasOnSite
                                            ? images.icons.chartVorort
                                            : images.icons.hasOnSiteDisabled
                                    }
                                    className={classes.takeOutIcon}
                                />
                            </div>
                            <div
                                className={helperFunctions.renderMultipleClassNames([
                                    classes.flexRowSpaceBetween,
                                    classes.pTop5P,
                                ])}
                            >
                                <h6 className={classes.sectionTitle}>
                                    {i18n.t('common.workingHours').toUpperCase()}
                                </h6>
                                <BasicThreeDotsMenu
                                    items={[
                                        <div
                                            className={classes.threeDotsMenuItemContainer}
                                            onClick={() => displayWorkingHoursModal('', 'workdays')}
                                        >
                                            <EditIcon className={classes.threeDotsItemIcon} />
                                            <ListItemText primary={i18n.t('common.edit')} />
                                        </div>,
                                    ]}
                                />
                            </div>

                            {getDayList(initialValues.openHours).map((day) => (
                                <div className={classes.serviceSectionContainer} key={day.name}>
                                    <span className={classes.serviceSectionTitle}>{day.name}</span>
                                    <Divider className={classes.halfDivider} />
                                    <div className={classes.workingHoursContainer}>
                                        {day.isClosed ? (
                                            <span className={classes.hourText}>
                                                {day.isClosed
                                                    ? i18n.t('common.closed')
                                                    : '00.00 - 23.59'}
                                            </span>
                                        ) : (
                                            day.workingHours.map(
                                                (date: { from: string; to: string }, i: number) => (
                                                    <span key={i} className={classes.hourText}>
                                                        {getFormattedHour(date)}
                                                    </span>
                                                )
                                            )
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};

interface CreateOrEditCategoryProps {
    isVisible: boolean;
    onClose: () => void;
    onCreate: (
        categoryName: string,
        dishes: string[],
        openHours: HourData[],
        position: string
    ) => void;
    onEdit: (
        categoryName: string,
        dishes: string[],
        openHours: HourData[],
        position: string,
        categoryId: string
    ) => void;
    categoryDetails: CategoryDetailsData;
    dishes: CategoryDishData[];
    categoryName: string;
    categoryId: string;
    position: string;
    isEdit: boolean;
    categories: string[];
}

const CreateOrEditCategoryModal = ({
    isVisible,
    onClose,
    categoryDetails,
    onCreate,
    onEdit,
    dishes,
    categoryName,
    categoryId,
    position,
    isEdit,
    categories,
}: CreateOrEditCategoryProps) => {
    const [workingHours, setWorkingHours] = useState<HourData[]>([defaultHours]);
    const [timePickerModalType, setTimePickerModalType] = useState(0);
    const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
    const [clickedElementRect, setClickedElementRect] = useState<any>(null);
    const classes = styles();

    useEffect(() => {
        setCheckedOptions(getCheckedDishes());
        setWorkingHours(getWorkingHours());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dishes]);

    function preventPropagationAndCheck(e: any, id: string) {
        e.stopPropagation();
        e.preventDefault();
        setCheckedOne(id);
    }

    function getWorkingHours() {
        const defaultWorkingHours = [defaultHours];

        if (isEdit) {
            const currentCategory = categoryDetails.categories.find(
                (category) => category.id === categoryId
            );
            if (currentCategory && currentCategory.openHours.length > 0) {
                return currentCategory.openHours;
            }
            return defaultWorkingHours;
        }
        return defaultWorkingHours;
    }

    function addMoreHours() {
        setWorkingHours([...workingHours, defaultHours]);
    }

    function getCheckedDishes(): string[] {
        if (dishes.length > 0) {
            const dishIds: string[] = [];
            dishes.map((dish) => dishIds.push(dish.id));
            return dishIds;
        }
        return [];
    }

    function setCheckedOne(id: string) {
        let newCheckedOptions = [...checkedOptions];
        if (checkedOptions.includes(id)) {
            newCheckedOptions = newCheckedOptions.filter((currentOption) => currentOption !== id);
        } else {
            newCheckedOptions.push(id);
        }
        setCheckedOptions(newCheckedOptions);
    }

    const validationSchema = yup.object().shape({
        categoryName: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Category Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !categories.includes(value?.toUpperCase())
            ),
        position: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Position Name',
            })
        ),
    });

    function getAvailableDishes() {
        const availableDishes = categoryDetails.dishes.filter((dish) => !dish.categoryId);
        return [...availableDishes, ...dishes];
    }

    function onCloseClicked() {
        setWorkingHours([defaultHours]);
        onClose();
    }

    function getClickedAndSet(i: number) {
        const id = `Create-Mui${i}`;
        const clickedElement = document.getElementById(id);
        const clickedElementRect = clickedElement?.getBoundingClientRect();
        if (clickedElementRect) {
            // @ts-ignore
            clickedElementRect.index = i;
        }
        setClickedElementRect(clickedElementRect);
    }

    return (
        <Dialog open={isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Typography variant="h6" align="left" className={classes.fontWeight500}>
                    {i18n.t(`${isEdit ? 'common.editCategory' : 'common.createNewCategory'}`)}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ overflowY: 'auto' }}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        categoryName,
                        dishes,
                        position,
                    }}
                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                        if (values.categoryName !== '') {
                            setSubmitting(false);
                            if (isEdit) {
                                onEdit(
                                    trimLeftAndRight(values.categoryName),
                                    checkedOptions,
                                    workingHours,
                                    values.position,
                                    categoryId
                                );
                            } else {
                                onCreate(
                                    trimLeftAndRight(values.categoryName),
                                    checkedOptions,
                                    workingHours,
                                    values.position
                                );
                            }
                            onClose();
                        }
                    }}
                >
                    {({
                        errors,
                        submitForm,
                        submitCount,
                        values,
                        setFieldValue,
                        setFieldError,
                        touched,
                    }) => (
                        <Form className={classes.smallModalContainer}>
                            <Input
                                error={errors.categoryName}
                                variant="filled"
                                name={`categoryName`}
                                type="text"
                                label={i18n.t('common.categoryName')}
                                placeholder={i18n.t('common.categoryName')}
                            />
                            <>
                                <Autocomplete
                                    id="dishes"
                                    options={getAvailableDishes()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderOption={(option) => (
                                        <div
                                            onClick={(e) =>
                                                preventPropagationAndCheck(e, option.id)
                                            }
                                            className={clsx(classes.width100, classes.flexRowStart)}
                                        >
                                            <Checkbox
                                                checked={checkedOptions.includes(option.id)}
                                            />
                                            <div
                                                className={classes.coffeeCupIcon}
                                                style={{
                                                    backgroundImage: `url(${images.icons.coffee})`,
                                                }}
                                            />
                                            {option.name}
                                        </div>
                                    )}
                                    renderInput={(params) => (
                                        <>
                                            <MuiTextField
                                                {...params}
                                                name="dishes"
                                                className={classes.dishesSelect}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <div
                                                                className={classes.coffeeCupIcon}
                                                                style={{
                                                                    backgroundImage: `url(${images.icons.coffee})`,
                                                                }}
                                                            />
                                                            <span>
                                                                {`${checkedOptions.length} ${i18n.t(
                                                                    'common.items'
                                                                )}`}
                                                            </span>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                label={i18n.t('common.chooseDishes')}
                                                variant="filled"
                                            />
                                        </>
                                    )}
                                />
                                <Autocomplete
                                    options={PositionsList.map((item) => item.name)}
                                    getOptionLabel={(option) => option}
                                    value={values.position}
                                    getOptionSelected={(option, value) => option === value}
                                    onChange={(e: object, value: any | null) => {
                                        setFieldValue('position', value);
                                    }}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                name="position"
                                                label={i18n.t('form.responsiblePosition')}
                                                variant="filled"
                                                error={
                                                    values.position === '' &&
                                                    getIn(touched, 'position')
                                                }
                                            />
                                        </>
                                    )}
                                />
                                {workingHours.map((workingHour, i: number) => (
                                    <div key={i}>
                                        <MuiTextField
                                            id={`Create-Mui${i}`}
                                            className={classes.width100}
                                            value={workingHour ? getFormattedHour(workingHour) : ''}
                                            onClick={() => {
                                                setTimePickerModalType(i + 1);
                                                getClickedAndSet(i);
                                            }}
                                            variant="filled"
                                            name={`extraHours[${i}]`}
                                            type="text"
                                            label={
                                                i18n.t('common.openHours') +
                                                ` ${i > 0 ? i + 1 : ''}`
                                            }
                                            placeholder={i18n.t('common.openHours')}
                                            InputProps={
                                                i > 0
                                                    ? {
                                                          endAdornment: (
                                                              <InputAdornment
                                                                  position="end"
                                                                  onClick={(e) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                      setWorkingHours(
                                                                          (oldWorkingHours) =>
                                                                              oldWorkingHours.filter(
                                                                                  (val, oldI) =>
                                                                                      oldI !== i
                                                                              )
                                                                      );
                                                                  }}
                                                              >
                                                                  <img
                                                                      alt="remove-icon"
                                                                      src={
                                                                          images.icons.removeCircle
                                                                      }
                                                                      className={classes.basicHover}
                                                                  />
                                                              </InputAdornment>
                                                          ),
                                                      }
                                                    : {}
                                            }
                                        />
                                        <br />
                                    </div>
                                ))}
                            </>
                            <div className={classes.flexRowSpaceBetween}>
                                <Button
                                    className={classes.mTop10}
                                    type="button"
                                    color="primary"
                                    onClick={addMoreHours}
                                >
                                    {i18n.t('common.addMoreHours')}
                                </Button>
                                <div className={classes.smallModalButtonContainer}>
                                    <Button
                                        style={{ marginRight: '15px' }}
                                        type="button"
                                        color="primary"
                                        onClick={onCloseClicked}
                                    >
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {i18n.t(`${isEdit ? 'button.save' : 'common.create'}`)}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
            <TimePickerModal
                initialFromHour={getInitialDates(workingHours[timePickerModalType], 'from', 'hour')}
                initialFromMinute={getInitialDates(
                    workingHours[timePickerModalType],
                    'from',
                    'minute'
                )}
                initialToHour={getInitialDates(workingHours[timePickerModalType], 'to', 'hour')}
                initialToMinute={getInitialDates(workingHours[timePickerModalType], 'to', 'minute')}
                clickedElementRect={clickedElementRect}
                isVisible={timePickerModalType > 0}
                onClose={(selectedHours) => {
                    let newWorkingHours = [...workingHours];
                    newWorkingHours[timePickerModalType - 1] = selectedHours;
                    setWorkingHours(newWorkingHours);
                    setTimePickerModalType(0);
                }}
                onDateChange={(selectedHours) => {
                    let newWorkingHours = [...workingHours];
                    newWorkingHours[timePickerModalType - 1] = selectedHours;
                    setWorkingHours(newWorkingHours);
                }}
            />
        </Dialog>
    );
};

interface CategoriesProps {
    categoryDetails: CategoryDetailsData;
    onCreate: (
        categoryName: string,
        dishes: string[],
        openHours: HourData[],
        position: string
    ) => void;
    onEdit: (
        categoryName: string,
        dishes: string[],
        openHours: HourData[],
        position: string,
        categoryId: string
    ) => void;
    onDisableOrEnable: (categoriesIds: string[]) => void;
    onOrder: (id: string) => void;
    orderBy: string;
    orderNames: string[];
    asc: boolean;
}

export const Categories = ({
    categoryDetails,
    onCreate,
    onEdit,
    onDisableOrEnable,
    onOrder,
    orderBy,
    orderNames,
    asc,
}: CategoriesProps) => {
    const [localCategoryDetails, setLocalCategoryDetails] = useState({
        id: '',
        name: '',
        position: '',
    });
    const [editDishes, setEditDishes] = useState<CategoryDishData[]>([]);
    const [showPopupConfirmation, setShowPopupConfirmation] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const classes = styles();

    function getListMenuItems(category: {
        name: string;
        id: string;
        position: string;
        isDisabled: boolean;
    }) {
        return [
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => editCategory(category)}
            >
                <EditIcon className={classes.threeDotsItemIcon} />
                <ListItemText primary={i18n.t('common.edit')} />
            </div>,
            category.isDisabled ? (
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => showDisableOrEnablePopup(category.id, category.isDisabled)}
                >
                    <SettingsBackupRestoreIcon className={classes.threeDotsItemIcon} />
                    <ListItemText primary={i18n.t('button.restore')} />
                </div>
            ) : (
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => showDisableOrEnablePopup(category.id, category.isDisabled)}
                >
                    <RemoveCircleOutlineIcon className={classes.threeDotsItemIcon} />
                    <ListItemText primary={i18n.t('common.disable')} />
                </div>
            ),
        ];
    }

    function showDisableOrEnablePopup(id: string, isDisabled: boolean) {
        setLocalCategoryDetails({ ...localCategoryDetails, id });
        setShowPopupConfirmation(isDisabled ? 1 : 2);
    }

    function createCategory() {
        displayCategoryModal();
        setEditDishes([]);
        setLocalCategoryDetails({ id: '', name: '', position: '' });
        setIsEdit(false);
    }

    function editCategory(category: { name: string; id: string; position: string }) {
        setEditDishes(getCategoryDishes(category.id));
        setLocalCategoryDetails(category);
        setIsEdit(true);
        displayCategoryModal();
    }

    function displayCategoryModal() {
        setShowCategoryModal(true);
    }

    function hideCategoryModal() {
        setShowCategoryModal(false);
    }

    function getCategoryDishes(categoryId: string) {
        let { dishes } = categoryDetails;
        dishes = dishes.filter((dish) => dish.categoryId === categoryId);
        return dishes;
    }

    function getCategoryHours(categoryId: string) {
        let { categories } = categoryDetails;
        const currentCategory = categories.find((category) => category.id === categoryId);
        if (currentCategory) {
            return currentCategory.openHours;
        }
        return [defaultHours];
    }

    return (
        <div className={classes.categoriesContainer}>
            <CreateOrEditCategoryModal
                categoryDetails={categoryDetails}
                isVisible={showCategoryModal}
                onClose={hideCategoryModal}
                onCreate={onCreate}
                onEdit={onEdit}
                position={localCategoryDetails.position}
                categoryName={localCategoryDetails.name}
                categoryId={localCategoryDetails.id}
                dishes={editDishes}
                isEdit={isEdit}
                categories={categoryDetails.categories.reduce((result: string[], current) => {
                    if (localCategoryDetails.name !== current.name) {
                        result.push(current.name.toUpperCase());
                    }
                    return result;
                }, [])}
            />

            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow key="table-head">
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === orderNames[0]}
                                direction={asc ? 'asc' : 'desc'}
                                onClick={() => onOrder(orderNames[0])}
                            >
                                <span className={classes.tableHead}>
                                    {i18n.t('common.categories')}
                                </span>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === orderNames[1]}
                                direction={asc ? 'asc' : 'desc'}
                                onClick={() => onOrder(orderNames[1])}
                            >
                                <span className={classes.tableHead}>
                                    {i18n.t('common.numberOfDishes')}
                                </span>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <span className={classes.tableHead}>
                                {i18n.t('common.workingHours')}
                            </span>
                        </TableCell>
                        <TableCell align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={6} padding="none">
                            <SectionTab
                                text={i18n.t('common.newCategory')}
                                onClick={createCategory}
                            />
                        </TableCell>
                    </TableRow>
                    {categoryDetails.categories.map((category) => (
                        <TableRow key={category.id}>
                            <TableCell
                                className={`${category.isDisabled && classes.disabledOpacity}`}
                            >
                                <Text text={category.name} maxLength={35} />
                            </TableCell>
                            <TableCell
                                className={`${category.isDisabled && classes.disabledOpacity}`}
                            >
                                {getCategoryDishes(category.id).length}
                            </TableCell>
                            <TableCell
                                className={`${category.isDisabled && classes.disabledOpacity}`}
                            >
                                <div className={classes.flexColumn}>
                                    {getCategoryHours(category.id).map(
                                        (hour: HourData, i: number) => (
                                            <span key={i}>{getFormattedHour(hour)}</span>
                                        )
                                    )}
                                </div>
                            </TableCell>
                            <TableCell
                                align="right"
                                className={clsx({
                                    [classes.threeDotsMenuDisabledCell]: category.isDisabled,
                                })}
                            >
                                <BasicThreeDotsMenu items={getListMenuItems(category)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <PopupConfirmation
                open={showPopupConfirmation > 0}
                close={() => setShowPopupConfirmation(0)}
                title={
                    showPopupConfirmation === 1
                        ? i18n.t('button.restore')
                        : i18n.t('common.disable')
                }
                description={
                    showPopupConfirmation === 1
                        ? i18n.t('confirmation.sureToRestoreRestaurant')
                        : i18n.t('confirmation.sureToDisableRestaurant')
                }
                activeBtn={
                    showPopupConfirmation === 1
                        ? i18n.t('button.restore')
                        : i18n.t('common.disable')
                }
                action={() => onDisableOrEnable([localCategoryDetails.id])}
            />
        </div>
    );
};

type ContactPersonsProps = { users: UsersStore.UsersState } & {
    restaurant: RestaurantDetailsStore.RestaurantDetailsState;
} & typeof UsersStore.actionCreators &
    typeof RestaurantDetailsStore.actionCreators;

export const ContactPersons = (props: ContactPersonsProps) => {
    const dispatch = useDispatch();
    const classes = styles();

    const authUserId = useSelector((state: ApplicationState) => state.user.authUserId);

    const [selected] = useState<string[]>([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
    const [userToEdit, setUserToEdit] = useState<UserData>({
        id: '',
        firstName: '',
        lastName: '',
        restaurants: [],
        email: '',
        phoneNumber: '',
        roles: [],
        isEnabled: false,
    });
    const [orderBy, setOrderBy] = useState('name');
    const [asc, setAsc] = useState(true);
    const orderNames: string[] = ['name', 'restaurant', 'email', 'phoneNumber', 'position', 'role'];
    const isGroupAction = useRef(false);

    useEffect(() => {
        if (!props.users || props.users?.users.length === 0) {
            props.getRestaurantUsers(
                {
                    name: '',
                    restaurant: '',
                    email: '',
                    phoneNumber: '',
                    position: '',
                    roles: [],
                },
                0,
                100,
                false,
                ''
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleOrder(id: string) {
        if (id === orderBy) {
            props.getRestaurantUsers(
                {
                    name: '',
                    restaurant: '',
                    email: '',
                    phoneNumber: '',
                    position: '',
                    roles: [],
                },
                0,
                100,
                !asc,
                orderBy
            );
            setAsc(!asc);
        } else {
            setOrderBy(id);
            props.getRestaurantUsers(
                {
                    name: '',
                    restaurant: '',
                    email: '',
                    phoneNumber: '',
                    position: '',
                    roles: [],
                },
                0,
                100,
                asc,
                id
            );
        }
    }

    const handleEditModal = () => {
        setOpenEditModal(!openEditModal);
    };
    const handleCreateModal = () => {
        setOpenCreateModal(!openCreateModal);
    };

    const handleConfirmationPopup = (isGroup: boolean = false) => {
        isGroupAction.current = isGroup;
        setOpenConfirmationPopup(!openConfirmationPopup);
    };

    const handleEnableDisableUser = (enable: boolean, params: string[]) => {
        return new Promise((resolve) =>
            resolve(enable ? agent.Users.EnableUsers(params) : agent.Users.DisableUsers(params))
        );
    };

    const enableDisableUser = (enable: boolean) => {
        const params = isGroupAction.current ? selected : [userToEdit.id];
        handleEnableDisableUser(enable, params).then((res) => {
            dispatch(
                RestaurantStore.actionCreators.updateContactPersons(
                    res as RestaurantContactPerson[]
                )
            );
        });
        handleConfirmationPopup();
    };

    function getRestaurantUsers() {
        const restaurantId = props.restaurant.restaurantInformation.id;
        return props.users.users.filter((user) => {
            const isSameRestaurant = user.restaurants.find(
                (eachRestaurant) => eachRestaurant.restaurantId === restaurantId
            );
            if (user.id === authUserId || (isSameRestaurant && isSameRestaurant.isContactPerson)) {
                return true;
            }
            return false;
        });
    }

    function getThreeDotsItems(row: any) {
        const items = [
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => {
                    setUserToEdit(row);
                    handleEditModal();
                }}
            >
                <ListItemIcon>
                    <BorderColorIcon fontSize="small" color="disabled" />
                </ListItemIcon>
                <Typography variant="inherit">{i18n.t('common.edit')}</Typography>
            </div>,
        ];
        if (row.id !== authUserId) {
            items.push(
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => {
                        setUserToEdit(row);
                        handleConfirmationPopup();
                    }}
                >
                    <ListItemIcon>
                        {row.isEnabled ? (
                            <RemoveCircleOutlineIcon fontSize="small" color="disabled" />
                        ) : (
                            <SettingsBackupRestoreIcon fontSize="small" color="disabled" />
                        )}
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {row.isEnabled ? i18n.t('button.disable') : i18n.t('button.restore')}
                    </Typography>
                </div>
            );
        }
        return items;
    }

    return (
        <>
            <TableContainer className={classes.root}>
                <Table className={classes.contactsTable} aria-label="simple table">
                    <TableHead>
                        <TableRow key="table-head">
                            <TableCell style={{ width: '25%' }}>
                                <TableSortLabel
                                    active={orderBy === orderNames[0]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[0])}
                                >
                                    {i18n.t('form.userName')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === orderNames[1]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[1])}
                                >
                                    {i18n.t('form.email')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === orderNames[2]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[2])}
                                >
                                    {i18n.t('form.phoneNumber')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === orderNames[3]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[3])}
                                >
                                    {i18n.t('form.position')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell colSpan={2}>
                                <TableSortLabel
                                    active={orderBy === orderNames[4]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[4])}
                                >
                                    {i18n.t('form.role')}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={6} padding="none">
                                <SectionTab
                                    text={i18n.t('user.newContactPerson')}
                                    onClick={handleCreateModal}
                                />
                            </TableCell>
                        </TableRow>
                        {getRestaurantUsers().map((row) => (
                            <TableRow key={row.id} className={row.id}>
                                <TableCell
                                    scope="row"
                                    onClick={() => {
                                        setUserToEdit(row);
                                        handleEditModal();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    className={`${!row.isEnabled && classes.disabledRow}`}
                                >
                                    <ListItem alignItems="center">
                                        <ListItemAvatar>
                                            <Avatar alt="Remy Sharp" src={logo} />
                                        </ListItemAvatar>
                                        <Text
                                            text={`${row.firstName} ${row.lastName}`}
                                            maxLength={20}
                                        />
                                    </ListItem>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={`${!row.isEnabled && classes.disabledRow}`}
                                >
                                    <Text text={row.email} fontSize={14} maxLength={30} />
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={`${!row.isEnabled && classes.disabledRow}`}
                                >
                                    {row.phoneNumber ? (
                                        <NumberFormat
                                            format="+## (###) ### ## ##"
                                            displayType={'text'}
                                            value={row.phoneNumber}
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={`${!row.isEnabled && classes.disabledRow}`}
                                >
                                    {row.restaurants
                                        .filter(
                                            (x) =>
                                                x.restaurantId ===
                                                props.restaurant.restaurantInformation.id
                                        )[0]
                                        ?.positions.join(', ') ?? '-'}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={`${!row.isEnabled && classes.disabledRow}`}
                                >
                                    <UserRoleLabel role={row.roles[0]?.name} />
                                </TableCell>
                                <TableCell align="right">
                                    <BasicThreeDotsMenu items={getThreeDotsItems(row)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={openEditModal}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">{i18n.t('user.editUser')}</DialogTitle>
                <DialogContent>
                    <EditRestaurantUserForm
                        user={userToEdit}
                        restaurants={[
                            props.restaurant.restaurantInformation as unknown as RestaurantData,
                        ]}
                        editRestaurantUser={props.editRestaurantUser}
                        cancel={handleEditModal}
                        close={handleEditModal}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openCreateModal}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">{i18n.t('user.createNewUser')}</DialogTitle>
                <DialogContent>
                    <AddRestaurantUserForm
                        createUser={props.createRestaurantUser}
                        restaurants={[
                            props.restaurant.restaurantInformation as unknown as RestaurantData,
                        ]}
                        currentRestaurant={
                            props.restaurant.restaurantInformation as unknown as RestaurantData
                        }
                        cancel={handleCreateModal}
                        isContactPerson
                        forContactPersons
                    />
                </DialogContent>
            </Dialog>
            <PopupConfirmation
                open={openConfirmationPopup}
                close={handleConfirmationPopup}
                title={
                    userToEdit.isEnabled
                        ? i18n.t('common.disableUser')
                        : i18n.t('common.restoreUser')
                }
                description={
                    userToEdit.isEnabled
                        ? i18n.t('confirmation.disableUser')
                        : i18n.t('confirmation.restoreUser')
                }
                activeBtn={
                    userToEdit.isEnabled ? i18n.t('common.disable') : i18n.t('common.enable')
                }
                action={() => enableDisableUser(!userToEdit.isEnabled)}
            />
        </>
    );
};

export default connect(
    (state: ApplicationState) => ({
        users: state.users,
        restaurant: state.restaurantDetails,
    }),
    { ...UsersStore.actionCreators, ...RestaurantDetailsStore.actionCreators }
)(ContactPersons as any);
