import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import { PopupConfirmation } from 'components/shared';
import { actionCreators } from 'store/menuDetailsStore';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export interface Props {
    isDisabled: boolean;
    id: string;
    onClose: any;
}

export default function DisableEnableSection({ isDisabled, id, onClose }: Props) {
    const dispatch = useDispatch();
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

    function handleSecionStatus() {
        dispatch(actionCreators.disableEnableSection(id));
        onClose();
    }

    return (
        <>
            <StyledMenuItem
                color="primary"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowConfirmationPopup(true);
                }}
            >
                {isDisabled ? (
                    <SettingsBackupRestoreIcon
                        fontSize="small"
                        color="disabled"
                        style={{ marginRight: 23 }}
                    />
                ) : (
                    <RemoveCircleOutlineIcon
                        fontSize="small"
                        color="disabled"
                        style={{ marginRight: 23 }}
                    />
                )}
                <ListItemText
                    primary={isDisabled ? i18n.t('common.enable') : i18n.t('common.disable')}
                />
            </StyledMenuItem>
            <PopupConfirmation
                open={showConfirmationPopup}
                close={() => setShowConfirmationPopup(false)}
                title={
                    isDisabled ? i18n.t('common.restoreSection') : i18n.t('common.disableSection')
                }
                description={
                    isDisabled
                        ? i18n.t('confirmation.restoreSection')
                        : i18n.t('confirmation.disableSection')
                }
                activeBtn={isDisabled ? i18n.t('common.enable') : i18n.t('common.disable')}
                action={handleSecionStatus}
            />
        </>
    );
}
