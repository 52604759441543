import React, { useState, useRef, useEffect } from 'react';
import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { isMobile, isTablet } from 'react-device-detect';

import commonStyles from '../../../config/commonStyles';
import i18n from 'i18n-js';

interface TextProps {
    text: string;
    onClick?: () => void;
    fontSize?: number;
    minifyThreshold?: number;
    maxLength?: number;
    directMinify?: boolean;
    whiteSpace?: 'normal' | 'nowrap';
    className?: string;
    withoutMore?: boolean;
    useClassic?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        moreText: {
            color: theme.palette.primary.main,
            '&:hover': {
                fontWeight: 'bold',
            },
        },
        classicContainer: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
        },
    })
);

export default function Text({
    text = '',
    onClick,
    fontSize = 16,
    minifyThreshold = 0,
    maxLength = 100,
    directMinify = false,
    whiteSpace = 'nowrap',
    className,
    withoutMore,
    useClassic = false,
}: TextProps) {
    const classes = useStyles();
    const [showAllText, setShowAllText] = useState(false);
    const el = useRef<HTMLInputElement>(null);
    const textLength = text.length;

    function overLength() {
        return textLength > maxLength;
    }

    function isMobileOrTablet() {
        return isMobile || isTablet;
    }

    function getFontSize(): string {
        let lastFontSize = fontSize;

        if (minifyThreshold > 0 && textLength >= minifyThreshold) {
            if (directMinify) {
                const directMinifyMultiplier = 0.75;
                lastFontSize *= directMinifyMultiplier;
            } else {
                const extraLength = textLength - minifyThreshold;
                const minifyMultiplierPerCharacter = 0.05;
                const minimumFontSizeRate = 0.4;
                lastFontSize -= extraLength * minifyMultiplierPerCharacter;
                if (lastFontSize < fontSize * minimumFontSizeRate) {
                    lastFontSize = fontSize * minimumFontSizeRate;
                }
            }
        }

        return `${lastFontSize}px`;
    }

    function getText(): string {
        if (maxLength && overLength()) {
            return text.substr(0, maxLength) + ' ...';
        }

        return text;
    }

    function getProps() {
        if (isMobileOrTablet() && showAllText) {
            return {
                open: true,
            };
        }
    }

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (useClassic) {
            timer = setTimeout(() => {
                setShowAllText(el.current?.offsetWidth! < el.current?.scrollWidth!);
            }, 500);
        }
        return () => timer && clearTimeout(timer);
    }, []); // eslint-disable-line

    return useClassic ? (
        <Tooltip title={text} disableHoverListener={!showAllText}>
            <div className={classes.classicContainer} style={{ fontSize: fontSize }} ref={el}>
                {text}
            </div>
        </Tooltip>
    ) : (
        <Tooltip title={text} {...getProps()}>
            <span
                onClick={onClick}
                style={{ fontSize: getFontSize(), whiteSpace: whiteSpace }}
                className={className}
            >
                {getText()}
                {overLength() && (
                    <span
                        className={classes.moreText}
                        onClick={() => {
                            setShowAllText((prev) => !prev);
                        }}
                    >
                        {' '}
                        {isMobileOrTablet() && showAllText
                            ? 'hide'
                            : withoutMore
                            ? ''
                            : i18n.t('common.more')}
                    </span>
                )}
            </span>
        </Tooltip>
    );
}
