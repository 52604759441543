import { theme } from 'config/theme';
import { Roles, OrderServices } from './enums';
import { images } from 'assets';

export const roleColor = new Map([
    [Roles.ADMIN as string, theme.palette.success.main],
    [Roles.OWNER as string, theme.palette.info.dark],
    [Roles.EMPLOYEE as string, theme.palette.info.main],
    [Roles.USER as string, theme.palette.warning.main],
]);

export const services = new Map<OrderServices, { icon: string }>([
    [
        OrderServices.OrderInRestaurant,
        {
            icon: images.icons.chartVorort,
        },
    ],
    [
        OrderServices.TakeAway,
        {
            icon: images.icons.chartTakeAway,
        },
    ],
    [
        OrderServices.HomeDelivery,
        {
            icon: images.icons.chartHomeDelivery,
        },
    ],
]);
