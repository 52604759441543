import React from 'react';
import { connect } from 'react-redux';

import i18n from 'i18n-js';
import {
    createStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
} from '@material-ui/core';
import PopupState, { bindMenu } from 'material-ui-popup-state';

import * as MenusStore from 'store/menusStore';
import { ApplicationState } from 'store';
import { AddMenuForm } from './AddMenuForm';
import { MenuType } from 'constants/enums';
import images from 'assets/images';
import commonStyles from 'config/commonStyles';

type MenuAddNewProps = MenusStore.MenusState & typeof MenusStore.actionCreators;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        dialog: {
            '& form': {
                textAlign: 'end',
            },

            '& .MuiTextField-root': {
                marginBottom: 28,
            },
            '& .MuiDialogTitle-root > h2': {
                ...commonStyles.flexRowSpaceBetween,
                width: '100%',
            },
        },
        dropdown: {
            '& .MuiPopover-paper ': {
                top: '63px !important',
            },
            '& ul > li:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
            },
            '& .MuiMenuItem-root': {
                minHeight: '48px !important',
            },
        },
        createNewDailyMenu: {
            fontSize: '20px',
            lineHeight: '30px',
        },
    })
);

const AddNewMenu = (props: MenuAddNewProps) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [menuType, setMenuType] = React.useState(MenuType.Daily);

    const handleClickOpen = (menuType: MenuType) => {
        setMenuType(menuType);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        {/*<Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                            {`+ ${i18n.t('restaurant.newMenu').toUpperCase()}`}
                        </Button>*/}
                        <Menu
                            {...bindMenu(popupState)}
                            className={classes.dropdown}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClickOpen(MenuType.Regular);
                                    popupState.close();
                                }}
                            >
                                {i18n.t('restaurant.standartMenu')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClickOpen(MenuType.Daily);
                                    popupState.close();
                                }}
                            >
                                {i18n.t('restaurant.dailyMenu')}
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
            <Dialog open={open} aria-labelledby="form-dialog-title" className={classes.dialog}>
                {menuType === MenuType.Regular && (
                    <DialogTitle id="form-dialog-title">
                        {i18n.t('restaurant.createNewStandartMenu')}
                    </DialogTitle>
                )}
                {menuType === MenuType.Daily && (
                    <DialogTitle id="form-dialog-title">
                        <span className={classes.createNewDailyMenu}>
                            {i18n.t('restaurant.createNewDailyMenu')}
                        </span>
                        <img src={images.icons.breakfastLogo} alt="breakfast" />
                    </DialogTitle>
                )}
                <DialogContent>
                    <AddMenuForm
                        menuType={menuType}
                        cancel={handleClose}
                        create={props.createMenu}
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default connect(
    (state: ApplicationState) => state.menus,
    MenusStore.actionCreators
)(AddNewMenu as any);
