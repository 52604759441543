import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'i18n-js';

import { Button, Dialog, DialogContent, DialogTitle, Menu, MenuItem } from '@material-ui/core';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import {
    actionCreators as usersActions,
    AdminCreateModel,
    RestaurantUserCreateModel,
} from 'store/usersStore';
import { actionCreators as restaurantsActions } from 'store/restaurantsStore';
import { Roles } from 'constants/enums';
import { UserRoleLabel } from '../../shared';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';
import AddAdminForm from './AddAdminForm';
import AddRestaurantUserForm from './AddRestaurantUserForm';

export default function AddUserComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userState = useAppSelector((state) => state.user);
    const restaurantsState = useAppSelector((state) => state.restaurants);
    const [open, setOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        (!restaurantsState.restaurants || restaurantsState.restaurants.length === 0) &&
            dispatch(restaurantsActions.getRestaurants());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <React.Fragment>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        {userState.role !== Roles.OWNER ? (
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                {...bindTrigger(popupState)}
                            >
                                <span>+</span>{' '}
                                <span className={classes.textMargin}>{i18n.t('user.newUser')}</span>
                            </Button>
                        ) : (
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={handleClickOpen}
                            >
                                <span>+</span>{' '}
                                <span className={classes.textMargin}>{i18n.t('user.newUser')}</span>
                            </Button>
                        )}
                        {userState.role !== Roles.OWNER && (
                            <Menu
                                {...bindMenu(popupState)}
                                className={classes.dropdown}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setIsAdmin(true);
                                        handleClickOpen();
                                        popupState.close();
                                    }}
                                >
                                    {i18n.t('user.admin')}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setIsAdmin(false);
                                        handleClickOpen();
                                        popupState.close();
                                    }}
                                >
                                    {i18n.t('user.restaurantUser')}
                                </MenuItem>
                            </Menu>
                        )}
                    </React.Fragment>
                )}
            </PopupState>
            <Dialog open={open} aria-labelledby="form-dialog-title" style={{ overflowY: 'hidden' }}>
                <DialogTitle id="form-dialog-title">
                    {userState.role === Roles.OWNER
                        ? i18n.t('user.createNewUser')
                        : isAdmin
                        ? i18n.t('user.createNewAdmin')
                        : i18n.t('user.createNewRestaurantUser')}
                    {isAdmin && <UserRoleLabel role={i18n.t('user.admin')} size={'large'} />}
                </DialogTitle>
                <DialogContent>
                    {isAdmin ? (
                        <AddAdminForm
                            createUser={(model: AdminCreateModel) =>
                                dispatch(usersActions.createAdmin(model))
                            }
                            cancel={handleClose}
                        />
                    ) : (
                        <AddRestaurantUserForm
                            createUser={(model: RestaurantUserCreateModel) =>
                                dispatch(usersActions.createRestaurantUser(model))
                            }
                            restaurants={restaurantsState.restaurants}
                            cancel={handleClose}
                            currentRestaurant={restaurantsState.restaurants[0]}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
