import React from 'react';
import { Link } from 'react-router-dom';

import { ListItem, ListItemIcon, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import i18n from 'i18n-js';

import { Text } from 'components/shared';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';

interface NavbarListItemProps {
    isOpen: boolean;
    title: string;
    icon: string;
    path: string;
    matches?: string[];
}

export default function NavbarListItem(props: NavbarListItemProps) {
    const classes = useStyles();
    const location = useAppSelector((state) => state.router.location.pathname);
    return (
        <div className={classes.navbarItem}>
            <ListItem
                className={clsx({
                    [classes.tabActive]: !!props.matches
                        ? props.matches.includes(location)
                        : location === props.path,
                })}
                component={Link}
                to={props.path}
                key={props.path.slice(1)}
            >
                <Tooltip
                    title={props.title}
                    placement="bottom-start"
                    disableHoverListener={props.isOpen}
                >
                    <ListItemIcon>
                        <div
                            className={classes.imageContainer}
                            style={{ backgroundImage: `url(${props.icon})` }}
                        />
                    </ListItemIcon>
                </Tooltip>
                {/*<ListItemText primary={props.title} />*/}
                <Text
                    text={props.title}
                    className={classes.listItemText}
                    fontSize={14}
                    maxLength={i18n.locale === 'de' ? 20 : 25}
                    withoutMore
                />
            </ListItem>
        </div>
    );
}
