import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import MenuManagementTable from './MenuManagementTable/MenuManagementTable';
import * as MenuDetailsStore from 'store/menuDetailsStore';
import { ApplicationState } from 'store';

interface MenuDetailsProps {
    id?: string;
    close?: () => void;
    isModal?: boolean;
}

export default function MenuDetails(props: MenuDetailsProps) {
    const params: { id: string } = useParams();
    const menuDetails = useSelector((state: ApplicationState) => state.menuDetails);
    const dispatch = useDispatch();

    useEffect(() => {
        // @ts-ignore
        dispatch(MenuDetailsStore.actionCreators.getDetails(params.id ? params.id : props.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);
    return menuDetails.menu.name ? (
        <MenuManagementTable close={props.close} isModal={props.isModal} />
    ) : null;
}
