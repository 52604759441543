import React, { useRef, useImperativeHandle, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import i18n from 'i18n-js';
import * as FormikFields from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { alpha, TextField, Theme } from '@material-ui/core';

import { AddIngredientsModal, Input } from 'components/shared';
import { DishCreateModel, IngredientModel } from 'api/agent';
import { images } from 'assets';
import commonStyles from 'config/commonStyles';
import { ApplicationState } from 'store';
import { actionCreators as categoryStoreActionCreators } from 'store/categoryStore';

import { DishData, SectionData } from 'store/menuDetailsStore';
import { trimLeftAndRight } from 'helpers/helperFunctions';
import ImageContainer, { ImageContainerRefProps } from '../../../shared/components/ImageContainer';
import AllergensSelect from '../../../shared/components/AllergensSelect';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:focus': {
                backgroundColor: theme.palette.primary.main,
                '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                    color: theme.palette.common.white,
                },
            },
            '& img': {
                width: 'auto',
                maxHeight: 171,
                height: 'auto',
            },
        },
        formEdit: {
            flexDirection: 'column',
            width: '48%',
            '&:last-child': {
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'space-between',
            },
        },
        formWrapper: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
        },
        bottomPanel: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '20px 0 30px 0',
        },
        dialog: {
            '& form': {
                textAlign: 'end',
            },

            '& .MuiTextField-root': {
                marginBottom: 28,
            },
            '& .MuiDialogTitle-root > h2': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            '& .MuiDialog-paperWidthSm': {
                maxWidth: '750px',
            },
            '& .MuiFormControl-root': {
                width: '337px',
            },
        },
        actionsArea: {
            margin: '10px 0 15px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&>span': {
                color: theme.palette.primary.main,
                cursor: 'pointer',
            },
            '& button': {
                margin: 0,
            },
            '&>div': {
                width: 180,
                display: 'flex',
                justifyContent: 'space-between',
            },
        },
        bottomContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        bottomIcon: {
            margin: '0 20px 0 -10px',
        },
        photoContainer: {
            minHeight: 158,
            //width: '48%',
            //marginRight: '5%',
            margin: '0 auto',
        },
        changePhotoButton: {
            position: 'absolute',
            top: '25%',
            left: '4.5%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette.common.white, 0.8),
            color: theme.palette.primary.main,
            width: '155px',
            height: '36px',
            borderRadius: '4px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            cursor: 'pointer',
        },
        removePhotoButton: {
            position: 'absolute',
            top: '25%',
            left: '26%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette.common.white, 0.8),
            color: theme.palette.error.light,
            width: '155px',
            height: '36px',
            borderRadius: '4px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            cursor: 'pointer',
        },
        manageIngredientButton: {
            width: '100%',
            height: '56px',
            borderRadius: 0,
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
        ...commonStyles,
    })
);

export interface EditNewDishProps {
    dish: DishData;
    disabled: boolean;
    sections: SectionData[];
    action: any;
    close: any;
}

export type EditNewDishItemHandler = {
    handleOpen: () => void;
};

export const EditNewDishItem = React.forwardRef<any, EditNewDishProps>((props, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const childRef = useRef<ImageContainerRefProps>(null);
    const dispatch = useDispatch();
    const categoryDetails = useSelector(
        (state: ApplicationState) => state.category.categoryDetails
    );
    const dishes = useRef<string[]>([]);
    const [showAddIngredientsModal, setShowAddIngredientsModal] = useState(false);

    useEffect(() => {
        if (!categoryDetails) {
            dispatch(categoryStoreActionCreators.getCategoryDetails());
        } else {
            dishes.current = categoryDetails!.dishes.reduce((result: string[], current) => {
                if (current.name !== props.dish.name) {
                    result.push(current.name.toUpperCase());
                }
                return result;
            }, []);
        }
    }, [categoryDetails]); // eslint-disable-line

    useImperativeHandle(ref, () => ({
        handleOpen: () => {
            setOpen(true);
            props.close();
        },
    }));

    const initalFormValues = {
        name: props.dish.name,
        description: props.dish.description,
        shortDescription: props.dish.shortDescription,
        price: props.dish.price!.toFixed(2),
        photo: props.dish.photo,
        isSpicy: props.dish.isSpicy,
        isVegetarian: props.dish.isVegetarian,
        isVegan: props.dish.isVegan,
        isHalal: props.dish.isHalal,
        section: props.dish.sectionId,
        kitchenId: '',
        addToGallery: props.dish.addToGallery,
        category: props.dish.categoryId,
        photoSelected: props.dish.photo.length > 0,
        allergens: props.dish.allergens,
        ingredients: props.dish.ingredients,
    };

    function handleClose(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        setOpen(false);
        props.close();
    }

    function preventProp(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !dishes.current.includes(value?.toUpperCase())
            ),
        shortDescription: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Short description',
            })
        ),
        price: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .min(0.1, i18n.t('form.errors.greaterZero'))
            .required(
                i18n.t('form.errors.required', {
                    name: 'Price',
                })
            ),
        category: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Category',
            })
        ),
        section: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Section',
            })
        ),
    });

    const SubmitPromise = async (model: DishCreateModel, id: string) => {
        await props.action(model, id, props.dish.sectionId);
    };

    function formDisabled(values: any) {
        if (values) {
            return JSON.stringify(initalFormValues) === JSON.stringify(values);
        }
        return true;
    }

    function addToAllergens(
        ingredients: IngredientModel[],
        values: any,
        setFieldValue: (key: string, value: any) => void
    ) {
        const newAllergens: string[] = [...values.allergens];
        ingredients.forEach((eachIngredient) => {
            eachIngredient.allergens.forEach((allergen) => {
                if (!newAllergens.includes(allergen)) {
                    newAllergens.push(allergen);
                }
            });
        });
        setFieldValue('allergens', newAllergens);
    }

    return (
        <>
            <Dialog
                maxWidth={'md'}
                onClick={preventProp}
                open={open}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">
                    {props.disabled ? props.dish.name : i18n.t('common.editItem')}
                </DialogTitle>
                <DialogContent>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initalFormValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            childRef?.current
                                ?.uploadImage()
                                .then((photo) => {
                                    values.photo = photo;
                                    let price = values.price.replaceAll(' ', '');
                                    const model: DishCreateModel = {
                                        name: trimLeftAndRight(values.name),
                                        description: trimLeftAndRight(values.description),
                                        shortDescription: trimLeftAndRight(values.shortDescription),
                                        price: +price,
                                        photo: values.photo,
                                        isSpicy: values.isSpicy,
                                        isVegetarian: values.isVegetarian,
                                        isVegan: values.isVegan,
                                        isHalal: values.isHalal,
                                        sectionId: values.section,
                                        kitchenId: '',
                                        addToGallery: values.addToGallery,
                                        categoryId: values.category,
                                        allergens: values.allergens,
                                        ingredients: values.ingredients,
                                    };
                                    SubmitPromise(model, props.dish.id).then(() => {
                                        resetForm();
                                        setOpen(false);
                                    });
                                })
                                .catch((error) => alert(error));
                        }}
                    >
                        {({ values, touched, setFieldValue, errors, submitForm }) => (
                            <Form className={classes.root}>
                                <>
                                    <div className={classes.formWrapper}>
                                        <div className={classes.formEdit}>
                                            <div className={classes.photoContainer}>
                                                <ImageContainer
                                                    disabled={props.disabled}
                                                    ref={childRef}
                                                    photo={values.photo}
                                                    onChange={(selected) => {
                                                        setFieldValue('photoSelected', selected);
                                                    }}
                                                />
                                            </div>
                                            <Input
                                                style={{ marginTop: 28 }}
                                                disabled={props.disabled}
                                                variant="filled"
                                                fullWidth={true}
                                                name={`name`}
                                                type="text"
                                                error={errors.name}
                                                label={i18n.t('form.itemName')}
                                                placeholder={i18n.t('form.itemName')}
                                            />
                                            <Input
                                                disabled={props.disabled}
                                                variant="filled"
                                                fullWidth={true}
                                                name={`shortDescription`}
                                                error={errors.shortDescription}
                                                type="text"
                                                label={i18n.t('form.shortDescription')}
                                                placeholder={i18n.t('form.shortDescription')}
                                            />
                                            <Input
                                                disabled={props.disabled}
                                                variant="filled"
                                                fullWidth={true}
                                                name={`description`}
                                                type="textarea"
                                                minRows={4}
                                                label={i18n.t('form.description')}
                                                placeholder={i18n.t('form.description')}
                                                style={{ marginBottom: 0 }}
                                            />
                                        </div>
                                        <div className={classes.formEdit}>
                                            <div
                                                style={{
                                                    visibility: props.disabled
                                                        ? 'hidden'
                                                        : 'visible',
                                                    position: 'absolute',
                                                    right: 5,
                                                    top: 10,
                                                }}
                                            >
                                                <Input
                                                    disabled={!values.photoSelected}
                                                    component={FormikFields.CheckboxWithLabel}
                                                    variant="filled"
                                                    checked={values.addToGallery}
                                                    name={'addToGallery'}
                                                    type="checkbox"
                                                    Label={{
                                                        label: i18n.t('form.addPhotoToGallery'),
                                                    }}
                                                    placeholder={i18n.t('form.addPhotoToGallery')}
                                                    color="primary"
                                                />
                                            </div>
                                            <br />
                                            <br />
                                            <div>
                                                <Autocomplete
                                                    id="section"
                                                    disabled={true}
                                                    options={props.sections.map((x) => x.id)}
                                                    getOptionLabel={(option) =>
                                                        props.sections.find((x) => x.id === option)
                                                            ?.name ?? ''
                                                    }
                                                    value={values.section}
                                                    getOptionSelected={(option, value) =>
                                                        option === value
                                                    }
                                                    onChange={(e, value) => {
                                                        setFieldValue('section', value || '');
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            name="section"
                                                            variant="filled"
                                                            fullWidth
                                                            label={
                                                                Boolean(
                                                                    touched.section &&
                                                                        errors.section
                                                                )
                                                                    ? i18n.t(
                                                                          'form.errors.required',
                                                                          {
                                                                              name: 'Section',
                                                                          }
                                                                      )
                                                                    : i18n.t('common.section')
                                                            }
                                                            error={Boolean(
                                                                touched.section && errors.section
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    id="category"
                                                    disabled={props.disabled}
                                                    options={
                                                        categoryDetails?.categories.map(
                                                            (x) => x.id
                                                        ) || []
                                                    }
                                                    getOptionLabel={(option) =>
                                                        categoryDetails?.categories.find(
                                                            (x) => x.id === option
                                                        )?.name ?? ''
                                                    }
                                                    value={values.category}
                                                    getOptionSelected={(option, value) =>
                                                        option === value
                                                    }
                                                    onChange={(e, value) =>
                                                        setFieldValue('category', value || '')
                                                    }
                                                    renderInput={(params) => (
                                                        <>
                                                            <TextField
                                                                {...params}
                                                                name="categories"
                                                                label={
                                                                    Boolean(
                                                                        touched.category &&
                                                                            errors.category
                                                                    )
                                                                        ? i18n.t(
                                                                              'form.errors.required',
                                                                              {
                                                                                  name: 'Category',
                                                                              }
                                                                          )
                                                                        : i18n.t(
                                                                              'common.categories'
                                                                          )
                                                                }
                                                                variant="filled"
                                                                fullWidth
                                                                error={Boolean(
                                                                    touched.category &&
                                                                        errors.category
                                                                )}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <Input
                                                    disabled={props.disabled}
                                                    variant="filled"
                                                    fullWidth={true}
                                                    name={`price`}
                                                    type="text"
                                                    error={errors.price}
                                                    label={i18n.t('form.price')}
                                                    placeholder={i18n.t('form.price')}
                                                />
                                                <AllergensSelect
                                                    values={values.allergens}
                                                    onChange={(vals: string[]) =>
                                                        setFieldValue('allergens', vals)
                                                    }
                                                    styles={{ marginBottom: 28 }}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.manageIngredientButton}
                                                    onClick={() => setShowAddIngredientsModal(true)}
                                                >
                                                    {`+ ${i18n
                                                        .t('restaurant.manageIngredients')
                                                        .toUpperCase()}`}
                                                </Button>
                                                <AddIngredientsModal
                                                    defaultIngredients={props.dish.ingredients}
                                                    isVisible={showAddIngredientsModal}
                                                    onClose={() => {
                                                        setShowAddIngredientsModal(false);
                                                    }}
                                                    onSave={(ingredients) => {
                                                        addToAllergens(
                                                            ingredients,
                                                            values,
                                                            setFieldValue
                                                        );
                                                        setFieldValue('ingredients', ingredients);
                                                        setShowAddIngredientsModal(false);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.bottomPanel}>
                                        <div className={classes.bottomContainer}>
                                            <Input
                                                component={FormikFields.CheckboxWithLabel}
                                                disabled={props.disabled}
                                                variant="filled"
                                                name={'isSpicy'}
                                                type="checkbox"
                                                Label={{ label: i18n.t('form.spicy') }}
                                                placeholder={i18n.t('form.spicy')}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                color="primary"
                                            />
                                            <img
                                                src={images.icons.chili}
                                                alt="chili-pepper"
                                                className={classes.bottomIcon}
                                            />
                                            <Input
                                                component={FormikFields.CheckboxWithLabel}
                                                disabled={props.disabled}
                                                variant="filled"
                                                name={'isVegetarian'}
                                                type="checkbox"
                                                Label={{ label: i18n.t('form.vegetarian') }}
                                                placeholder={i18n.t('form.vegetarian')}
                                                color="primary"
                                            />
                                            <img
                                                src={images.icons.leaf}
                                                alt="green-leaf"
                                                className={classes.bottomIcon}
                                            />
                                            <Input
                                                component={FormikFields.CheckboxWithLabel}
                                                disabled={props.disabled}
                                                variant="filled"
                                                name={'isVegan'}
                                                type="checkbox"
                                                Label={{ label: i18n.t('form.vegan') }}
                                                placeholder={i18n.t('form.vegan')}
                                                color="primary"
                                            />
                                            <img
                                                src={images.icons.doubleLeaf}
                                                alt="double-leaf"
                                                className={classes.bottomIcon}
                                            />
                                            <Input
                                                component={FormikFields.CheckboxWithLabel}
                                                disabled={props.disabled}
                                                variant="filled"
                                                name={'isHalal'}
                                                type="checkbox"
                                                Label={{ label: i18n.t('form.halal') }}
                                                placeholder={i18n.t('form.halal')}
                                                color="primary"
                                            />
                                            <img
                                                src={images.icons.halal}
                                                alt="halal"
                                                className={classes.bottomIcon}
                                            />
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {props.disabled ? (
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    onClick={handleClose}
                                                >
                                                    {i18n.t('button.close')}
                                                </Button>
                                            ) : (
                                                <>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        onClick={handleClose}
                                                        className={classes.mRight10}
                                                    >
                                                        {i18n.t('button.cancel')}
                                                    </Button>
                                                    <Button
                                                        disabled={formDisabled(values)}
                                                        onClick={submitForm}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {i18n.t('button.save')}
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
});

export default EditNewDishItem;
