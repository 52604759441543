import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import i18n from 'i18n-js';

import { images } from 'assets';
import * as GlobalStore from 'store/globalStore';
import { theme } from 'config/theme';
import { useStyles } from '../styles';

export const languages = [
    { code: 'en', name: 'English', flag: images.icons.engFlag },
    {
        code: 'de',
        name: 'German',
        flag: images.icons.gerFlag,
    },
];

const langKey = 'jamesLanguage';

export function NavLanguageSwitcher(props: { isOpen: boolean; open: () => void }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);

    function changeLanguage(languageCode: string) {
        const choosedLang = GlobalStore.prepareLanguage(languageCode);
        i18n.locale = choosedLang;
        dispatch(GlobalStore.actionCreators.changeLanguage(choosedLang));
        localStorage.setItem(langKey, choosedLang);
    }

    useEffect(() => {
        const targetLang = localStorage.getItem(langKey) || i18n.locale;
        i18n.locale = targetLang;
        dispatch(
            GlobalStore.actionCreators.changeLanguage(GlobalStore.prepareLanguage(targetLang))
        );
        if (!localStorage.getItem(langKey)) {
            localStorage.setItem(langKey, targetLang);
        }
    }, []); // eslint-disable-line

    function handleExpand() {
        setIsExpanded(!isExpanded);
    }

    return (
        <>
            <div className={classes.root}>
                <Accordion expanded={isExpanded}>
                    <AccordionSummary
                        className={props.isOpen ? classes.header : classes.headerHidden}
                        expandIcon={<ExpandIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={handleExpand}
                    >
                        <img src={languages.find((x) => x.code === i18n.locale)?.flag} alt="flag" />
                        <Typography style={{ marginLeft: 30, fontSize: 14 }}>
                            {languages.find((x) => x.code === i18n.locale)?.name}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List disablePadding>
                            <ListItem
                                divider
                                onClick={() => {
                                    changeLanguage(i18n.locale === 'de' ? 'en' : 'de');
                                    handleExpand();
                                }}
                            >
                                <img
                                    src={languages.find((x) => x.code !== i18n.locale)?.flag}
                                    alt="flag"
                                    style={{
                                        marginLeft: props.isOpen ? 0 : 5,
                                    }}
                                    height={28}
                                    width={28}
                                />
                                <ListItemText
                                    style={{ paddingLeft: 7 }}
                                    primary={languages.find((x) => x.code !== i18n.locale)?.name}
                                    primaryTypographyProps={{
                                        style: {
                                            color: theme.palette.text.disabled,
                                            fontWeight: 400,
                                            fontSize: 12,
                                        },
                                    }}
                                />
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}

function ExpandIcon() {
    return <img src={images.icons.triangleDown} alt="triangle-down" />;
}
