import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import i18n from 'i18n-js';

import MenuPreviewItem from './MenuPreviewItem';
import * as MenusStore from 'store/menusStore';
import { actionCreators as ingredientsActions } from 'store/ingredientsStore';
import { ApplicationState } from 'store';
import { MenuCreateModel } from 'api/agent';
import MenuDetails from 'components/menu/MenuDetails';
import { images } from 'assets';
import { Roles } from 'constants/enums';

interface MenusTableProps {
    isModal?: boolean;
    close?: () => void;
    setMenuName?: (name: string) => void;
    handleDetails?: (id: string) => void;
    showMenuDetails?: boolean;
    setShowMenuDetails?: () => void;
    menuId?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'inline-flex',
            flexWrap: 'wrap',
            //margin: '0 0 0 15px',
            //width: 'calc(100% + 32px)',
            padding: '23px 0 0 12px',
            maxHeight: 'calc(100% - 80px)',
            overflowY: 'auto',
        },
        forModal: {
            overflowX: 'hidden',
            height: 'calc(100% - 90px)',
            minWidth: 'calc(100% - 12px)',
        },
    })
);
export default function MenusTable(props: MenusTableProps) {
    const classes = useStyles();
    const menus = useSelector((state: ApplicationState) => state.menus);
    const lang = useSelector((state: ApplicationState) => state.global.language);
    const ingredients = useSelector((state: ApplicationState) => state.ingredients);
    const user = useSelector((state: ApplicationState) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(MenusStore.actionCreators.getMenus());
        ingredients.ingredients.length === 0 && dispatch(ingredientsActions.getIngredients(lang));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div
            className={classes.root}
            //className={clsx(classes.root, classes.forModal)}
            /* className={clsx(classes.root, {
                [classes.forModal]: props.isModal,
            })} */
        >
            {props.showMenuDetails ? (
                <MenuDetails
                    id={props.menuId}
                    close={() => {
                        !!props.setShowMenuDetails && props.setShowMenuDetails();
                    }}
                    isModal={props.isModal}
                />
            ) : (
                <>
                    {user.role === Roles.OWNER && (
                        <MenuPreviewItem
                            disable={(id: string) =>
                                dispatch(MenusStore.actionCreators.disableMenu(id))
                            }
                            key={'ING'}
                            menuType={'ING'}
                            edit={null}
                            menu={{
                                edit: '',
                                id: 'ii',
                                name: i18n.t('common.ingredients'),
                                description: 'Some',
                                price: 0,
                                photo: images.ingredientsDefault,
                                type: 2,
                                sections: [''],
                                dishesCount: ingredients.ingredientsCount,
                                isEnabled: true,
                                position: 0,
                                availableFrom: 0,
                                availableTo: 0,
                            }}
                            showDetails={props.isModal ? props.handleDetails : undefined}
                            setName={props.setMenuName}
                        />
                    )}
                    {menus.menus.length > 0 &&
                        menus.menus.map((menu) => (
                            <MenuPreviewItem
                                disable={(id: string) =>
                                    dispatch(MenusStore.actionCreators.disableMenu(id))
                                }
                                key={menu.id}
                                menuType={''}
                                edit={(model: MenuCreateModel, id: string) =>
                                    dispatch(MenusStore.actionCreators.editMenu(model, id))
                                }
                                menu={menu}
                                showDetails={props.isModal ? props.handleDetails : undefined}
                                setName={props.setMenuName}
                            />
                        ))}
                </>
            )}
        </div>
    );
}
