import React, { useState, useEffect, useRef } from 'react';

import i18n from 'i18n-js';
import { Button, createStyles, IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import agent, { OrderUpdateModel } from 'api/agent';
import { images } from 'assets';
import commonStyles from 'config/commonStyles';
import { DishData, MenuDetailsData, SectionData } from 'store/menuDetailsStore';
import MenusTable from './components/MenusTable';
import { SearchResultTable } from './components/SearchResultTable';
import AddNewMenu from './components/AddNewMenu';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { Roles } from 'constants/enums';
import { actionCreators as globalActions } from 'store/globalStore';
import { actionCreators as ingredientActions } from 'store/ingredientsStore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        header: {
            width: '96%',
            height: 60,
            borderBottom: '1px solid  #E5E5E5',
            padding: '0 24px',
            fontSize: 20,
            fontWeight: 500,
        },
        actionsContainer: {
            '& img:first-child': {
                height: 20,
                //margin: '5px 25px 0 0',
                cursor: 'pointer',
            },
            '& .MuiTextField-root': {
                marginLeft: 20,
            },
        },
        lensIcon: {
            marginLeft: 20,
            cursor: 'pointer',
        },
        searchInput: {
            borderBottom: 'none',
            '& div:hover, & div::after, & div::before': {
                borderBottom: 'none !important',
            },
        },
        iconButton: {
            color: theme.palette.common.black,
            marginRight: '10px',
        },
    })
);

export interface MenuSections {
    menuId: string;
    sections: SectionData[];
}

export const MenusPage = (props: {
    onClose?: () => any;
    onHide?: () => any;
    isModal?: boolean;
    saveChanges: (model: OrderUpdateModel | null) => void;
}) => {
    const order = useSelector((state: ApplicationState) => state.orders.orderDetails);
    const initialOrderItems = useSelector(
        (state: ApplicationState) => state.orders.initialOrderItems
    );
    const language = useSelector((state: ApplicationState) => state.global.language);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [menusDetails, setMenusDetails] = useState<MenuDetailsData[]>([]);
    const [searchResult, setSearchResult] = useState<MenuDetailsData[]>([]);
    const [sectionsByMenu, setSectionByMenu] = useState<MenuSections[]>([]);
    const user = useSelector((state: ApplicationState) => state.user);
    const [menuName, setMenuName] = useState('');
    const [menuId, setMenuId] = useState('');
    const [showMenuDetails, setShowMenuDetails] = useState(false);
    const initialOrderItemAmount = useRef(0);

    useEffect(() => {
        initialOrderItemAmount.current = initialOrderItems.reduce(
            (accumulator, currentValue) => accumulator + currentValue.amount,
            0
        );
        getMenuesDetails();
        dispatch(ingredientActions.getAllergies(language));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    function handleDetails(id: string) {
        setMenuId(id);
        setShowMenuDetails(true);
    }

    function closeSearch() {
        setShowSearchBar(false);
        setShowSearchResult(false);
        setSearchString('');
        getMenuesDetails();
    }

    function resetSearch() {
        setSearchString('');
        setSearchResult([]);
        setShowSearchResult(false);
    }

    function filterSectionsByMenus() {
        let sections: MenuSections[] = [];
        menusDetails.forEach((menu) => {
            sections.push({ menuId: menu.id, sections: menu.sections });
        });
        setSectionByMenu(sections);
    }

    function updateSearch(updatedDish: DishData, isDeleted: boolean = false) {
        let menu = searchResult.find(
            (menu) =>
                menu.name.toLowerCase() ||
                menu.sections.some((x) => x.dishes.some((y) => y.id === updatedDish.id))
        );
        if (menu) {
            let section = menu.sections.find((section) =>
                section.dishes.some((dish) => dish.id === updatedDish.id)
            );
            if (section) {
                if (isDeleted) {
                    const targetIndex = section.dishes.findIndex((x) => x.id === updatedDish.id);
                    section.dishes.splice(targetIndex, 1);
                } else {
                    section.dishes = section.dishes.map((dish) =>
                        dish.id === updatedDish.id && !isDeleted ? updatedDish : dish
                    );
                }

                setSearchResult(searchResult.map((x) => (x.id !== menu!.id ? x : menu!)));
                getMenuesDetails();
            }
        }
    }

    function getSearchResult() {
        setSearchResult([]);
        const searchValue = new RegExp(searchString, 'gi');
        let menusResult: MenuDetailsData[] = [];
        menusDetails.forEach((menu) => {
            let sectionsResult: SectionData[] = [];
            let targetDishes: string[] = [];
            if (!!menu.name.match(searchValue) && menu.sections?.length === 0) {
                menusResult.push({ ...menu, sections: [] });
            } else if (menu.sections?.length > 0) {
                menu.sections.forEach((section) => {
                    if (section.dishes.length > 0) {
                        section.dishes.forEach((dish) => {
                            if (
                                dish.name.match(searchValue) ||
                                dish.description.match(searchValue) ||
                                dish.shortDescription.match(searchValue)
                            ) {
                                targetDishes.push(dish.id);
                            }
                        });
                    }
                    if (targetDishes.length > 0) {
                        sectionsResult.push({
                            ...section,
                            dishes: section.dishes.filter((x) => targetDishes.includes(x.id)),
                        });
                    }
                });

                if (sectionsResult.length > 0) {
                    menusResult.push({ ...menu, sections: sectionsResult });
                } else if (!!menu.name.match(searchValue)) {
                    menusResult.push({ ...menu });
                }
            }
        });
        setSearchResult(menusResult);
        sectionsByMenu.length === 0 && filterSectionsByMenus();
    }

    function getMenuesDetails() {
        dispatch(globalActions.showSpiner());
        agent.Menus.GetMenusDetails()
            .then((response) => setMenusDetails(response))
            .catch((e) => dispatch(globalActions.showToaster('error', e)))
            .finally(() => dispatch(globalActions.hideSpiner()));
    }

    function handleSearchByEnter(e: any) {
        if (e.key === 'Enter') {
            getSearchResult();
            setShowSearchResult(true);
        }
    }

    function modifiedItemsAmount() {
        const length = order.orderItems.reduce(
            (accumulator, currentValue) => accumulator + currentValue.amount,
            0
        );
        return length - initialOrderItemAmount.current;
    }

    return (
        <>
            <>
                <div className={`${classes.header} ${classes.flexRowSpaceBetween}`}>
                    <div className={`${classes.actionsContainer} ${classes.flexRowSpaceBetween}`}>
                        {showSearchBar && (
                            <img
                                src={images.icons.backArrow}
                                onClick={closeSearch}
                                alt="back-arrow"
                            />
                        )}
                        {!showSearchBar && !showSearchResult && !!menuName ? (
                            <>
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    className={classes.iconButton}
                                    onClick={() => {
                                        setShowMenuDetails(false);
                                        setMenuName('');
                                    }}
                                >
                                    <ArrowBackIcon fontSize="inherit" />
                                </IconButton>
                                <span>{menuName}</span>
                            </>
                        ) : !showSearchBar ? (
                            <span>Menu</span>
                        ) : (
                            <></>
                        )}
                        {showSearchBar ? (
                            <img
                                src={
                                    searchString.length > 0
                                        ? images.icons.lensBlue
                                        : images.icons.lens
                                }
                                className={classes.lensIcon}
                                onClick={() => searchString.length > 0 && getSearchResult()}
                                alt="search-icon"
                            />
                        ) : (
                            <img
                                src={images.icons.lens}
                                className={classes.lensIcon}
                                onClick={() => setShowSearchBar(true)}
                                alt="lens-icon"
                            />
                        )}
                        {showSearchBar && (
                            <TextField
                                id="search-input"
                                placeholder={i18n.t('form.searchWord')}
                                onChange={(e) => setSearchString(e.target.value)}
                                value={searchString}
                                onKeyDown={(e) => handleSearchByEnter(e)}
                                className={
                                    searchString.trim().length > 0 ? classes.searchInput : ''
                                }
                            />
                        )}
                    </div>
                    {showSearchBar ? (
                        <Button variant="outlined" color="primary" onClick={resetSearch}>
                            {i18n.t('form.resetSearch')}
                        </Button>
                    ) : props.isModal ? (
                        <div>
                            <Button variant="text" color="primary" onClick={props.onClose}>
                                {i18n.t('button.close')}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={modifiedItemsAmount() === 0}
                                onClick={() => props.saveChanges(null)}
                            >
                                + {i18n.t('button.addToOrder')}
                                {modifiedItemsAmount() !== 0 && ` (${modifiedItemsAmount()})`}
                            </Button>
                        </div>
                    ) : (
                        user.role === Roles.OWNER && <AddNewMenu />
                    )}
                </div>
                {showSearchResult ? (
                    <SearchResultTable
                        menus={searchResult}
                        sections={sectionsByMenu}
                        recalculate={updateSearch}
                        searchString={searchString}
                        isModal={props.isModal}
                    />
                ) : (
                    <MenusTable
                        isModal={props.isModal}
                        close={props.onClose}
                        setMenuName={setMenuName}
                        menuId={menuId}
                        handleDetails={handleDetails}
                        showMenuDetails={showMenuDetails}
                        setShowMenuDetails={() => setShowMenuDetails(false)}
                    />
                )}
            </>
        </>
    );
};
