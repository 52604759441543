import { connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as AppUser from './userStore';
import { reducer as oidcReducer } from 'redux-oidc';
import { User } from 'oidc-client';
import * as Restaurants from './restaurantsStore';
import * as RestaurantDetails from './restaurantDetailsStore';
import * as Users from './usersStore';
import * as Roles from './rolesStore';
import * as Menus from './menusStore';
import * as MenuDetails from './menuDetailsStore';
import * as Category from './categoryStore';
import * as Orders from './ordersStore';
import * as Finance from './financeStore';
import * as Global from './globalStore';
import * as Ingredients from './ingredientsStore';
import * as UI from './uiStore';

export const history = createBrowserHistory();

export interface OidcState {
    isLoadingUser: boolean;
    user: User;
}

// The top-level state object
export interface ApplicationState {
    oidic: OidcState;
    router: RouterState;
    user: AppUser.UserState;
    restaurants: Restaurants.RestaurantsState;
    restaurantDetails: RestaurantDetails.RestaurantDetailsState;
    users: Users.UsersState;
    roles: Roles.RolesState;
    menus: Menus.MenusState;
    menuDetails: MenuDetails.MenuDetailsState;
    category: Category.CategoryState;
    orders: Orders.OrdersState;
    finance: Finance.FinanceState;
    global: Global.GlobalState;
    ingredients: Ingredients.IngredientsState;
    ui: UI.UiState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    router: connectRouter(history),
    oidc: oidcReducer,
    user: AppUser.reducer,
    restaurants: Restaurants.reducer,
    restaurantDetails: RestaurantDetails.reducer,
    users: Users.reducer,
    roles: Roles.reducer,
    menus: Menus.reducer,
    menuDetails: MenuDetails.reducer,
    category: Category.reducer,
    orders: Orders.reducer,
    finance: Finance.reducer,
    global: Global.reducer,
    ingredients: Ingredients.reducer,
    ui: UI.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState?: () => ApplicationState): void;
}
