import React, { useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n-js';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { alpha, Theme } from '@material-ui/core';

import commonStyles from 'config/commonStyles';
import { useRef, useState } from 'react';
import agent from 'api/agent';
import { actionCreators as globalActions } from 'store/globalStore';
import { ApplicationState } from '../../../store';
import { Positions } from '../../../constants/enums';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        photoContainer: {
            display: 'flex',
            height: '158px',
            marginBottom: '25px',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            border: `1px solid ${theme.palette.grey['300']}`,
            borderRadius: '4px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
        },
        changePhotoButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette.common.white, 0.8),
            color: theme.palette.primary.main,
            width: '45%',
            height: '36px',
            borderRadius: '4px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            cursor: 'pointer',
        },
        removePhotoButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette.common.white, 0.8),
            color: theme.palette.error.light,
            width: '45%',
            height: '36px',
            borderRadius: '4px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            cursor: 'pointer',
        },
        addPhotoText: {
            fontSize: '14px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            lineHeight: '16px',
            color: theme.palette.primary.main,
        },
        ...commonStyles,
    })
);

export interface ImageContainerRefProps {
    uploadImage: () => Promise<string>;
}

interface Props {
    photo: any;
    onChange?: (selected: boolean) => void;
    disabled?: boolean;
}

const ImageContainer = React.forwardRef<ImageContainerRefProps, Props>(
    ({ photo, onChange, disabled }, ref) => {
        const user = useSelector((state: ApplicationState) => state.user);
        const currentRestaurant = user.restaurants?.filter(
            (x) => x.id === user.currentRestaurantId
        )[0];

        const dispatch = useDispatch();
        const classes = useStyles();
        const [selectedFile, setSelectedFile] = useState('');
        const [fileUrl, setFileUrl] = useState(photo);
        const inputRef = useRef(null);

        function clickOnFileInput() {
            // @ts-ignore
            inputRef?.current.click();
        }

        function isDisabledByRoleOrPosition() {
            const currentPosition = currentRestaurant?.currentPosition;
            return currentPosition === Positions.COOK || currentPosition === Positions.WAITER;
        }

        useImperativeHandle(ref, () => ({
            uploadImage() {
                return new Promise<string>((resolve, reject) => {
                    const formData = new FormData();
                    if (selectedFile) {
                        formData.append('file', selectedFile!);
                        agent.requests
                            .post(`/File/uploadimage`, formData)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((error) => reject(error));
                    } else if (fileUrl) {
                        resolve(fileUrl);
                    } else {
                        resolve('');
                    }
                });
            },
        }));

        function changeHandler(event: any) {
            const file = event.target.files[0];
            const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            if (file && !acceptedImageTypes.includes(file['type'])) {
                return;
            }
            const size = parseFloat((file.size / (1024 * 1024)).toFixed(2));
            if (size > 1) {
                dispatch(globalActions.showToaster('warning', i18n.t('warnings.imageSizeWarning')));
                return;
            }
            setSelectedFile(file);
            let reader = new FileReader();
            reader.onload = (e) => {
                setFileUrl(e.target!.result!.toString());
            };
            onChange && onChange(true);
            reader.readAsDataURL(file);
        }

        function removePhoto() {
            setSelectedFile('');
            setFileUrl('');
            onChange && onChange(false);
        }

        return (
            <div
                onClick={!fileUrl && !disabled ? clickOnFileInput : () => ''}
                style={{
                    backgroundImage: `url(${fileUrl})`,
                    cursor: fileUrl ? 'auto' : 'pointer',
                }}
                className={classes.photoContainer}
            >
                {fileUrl ? (
                    !isDisabledByRoleOrPosition() && (
                        <>
                            <div
                                onClick={clickOnFileInput}
                                className={`${classes.changePhotoButton}`}
                            >
                                <h5>{i18n.t('common.changePhoto').toUpperCase()}</h5>
                            </div>
                            <div onClick={removePhoto} className={`${classes.removePhotoButton}`}>
                                <h5>{i18n.t('common.removePhoto').toUpperCase()}</h5>
                            </div>
                        </>
                    )
                ) : (
                    <span className={classes.addPhotoText}>
                        {i18n.t(`${disabled ? 'form.noPhoto' : 'form.addPhoto'}`)}
                    </span>
                )}
                <input
                    accept="image/*"
                    ref={inputRef}
                    type="file"
                    name="file"
                    id="upload"
                    onChange={changeHandler}
                    style={{ display: 'none' }}
                />
            </div>
        );
    }
);

export default ImageContainer;
