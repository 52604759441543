import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core';

import DishPreviewItem from '../DishPreviewItem';
import ThreeDotsSectionMenu from './components/ThreeDotsSectionMenu';
import { theme } from 'config/theme';
import { Roles, MenuType, OrderDishStatus } from 'constants/enums';
import { ApplicationState } from 'store';
import { actionCreators, OrderItem } from 'store/ordersStore';
import * as MenuDetailsStore from 'store/menuDetailsStore';
import { DishCreateModel } from 'api/agent';
import { priceStyleHelper } from 'helpers/helperFunctions';
import { Text } from '../../shared';
import commonStyles from 'config/commonStyles';
import { actionCreators as ingredientsActions } from '../../../store/ingredientsStore';
import { helperFunctions } from '../../../helpers';

export interface MenuSectionsProps {
    sectionId: string;
    isDisabled: boolean;
    onChooseExisting: () => void;
    onDishEdit: (dishId: string) => void;
    isModal?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        root: {
            margin: '0 !important',
            filter: 'none',
            '&:before': {
                backgroundColor: theme.palette.common.white,
                top: '-2px',
                height: 2,
            },
            '& .MuiListItem-gutters': {
                padding: '0',
            },
            '& .MuiButtonBase-root.MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded':
                {
                    backgroundColor: theme.palette.grey[100],
                    height: '37px !important',
                    minHeight: 0,
                },
            '& .MuiAccordionSummary-content': {
                textAlign: 'center',
            },
            '& .MuiAccordionSummary-content>p': {
                width: '100%',
            },
            '& .MuiAccordion-root.Mui-expanded': {
                margin: 0,
            },
            '& .MuiAccordionDetails-root': {
                display: 'inline-flex',
                flexWrap: 'wrap',
                margin: 0,
                paddingRight: 0,
                paddingLeft: 0,
            },
        },
        toolbarTitle: {
            flex: '1 1 100%',
            display: 'flex',
            justifyContent: 'center',
        },
        sectionLine: {
            background: theme.palette.secondary.light,
        },
        menuLabel: {
            display: 'flex',
            //position: 'absolute',
            right: '30px',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: theme.palette.common.black,
            marginRight: '10px',
            minWidth: '227px',
            height: '23px',
            background: theme.palette.common.white,
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: '5px',
            padding: '0 10px',
        },
        menuPrice: {
            fontWeight: 900,
            fontSize: '16px',
            lineHeight: '19px',
            color: theme.palette.primary.main,
        },
        chf: {
            color: theme.palette.primary.main,
            fontWeight: 400,
            marginRight: '5px',
            fontSize: '16px',
        },
        currencyText: {
            color: theme.palette.primary.main,
            fontSize: '20px',
            marginRight: '6px',
        },
        rightSideContainer: {
            ...commonStyles.flexRowCenter,
            position: 'absolute',
            //width: 345,
            right: 32,
            top: 3,
            height: 32,
            zIndex: 10,
        },
        itemAmountComponent: {
            ...commonStyles.flexRowSpaceBetween,
            border: `1px solid ${theme.palette.primary.main}`,
            width: 55,
            height: 31,
            borderRadius: 20,
            backgroundColor: theme.palette.common.white,
            zIndex: 2,
            padding: '0 16px',
        },
        itemAmountAction: {
            color: theme.palette.secondary.main,
            fontWeight: 900,
            fontSize: 17,
            cursor: 'pointer',
            lineHeight: '38px',
        },
        itemAmountCount: {
            color: theme.palette.common.black,
            fontWeight: 900,
            fontSize: 15,
            cursor: 'pointer',
            lineHeight: '38px',
        },
    })
);

export const MenuSectionsTable = (props: MenuSectionsProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.user);
    const menu = useSelector((state: ApplicationState) => state.menuDetails.menu);
    const order = useSelector((state: ApplicationState) => state.orders.orderDetails);
    const section = useSelector(
        (state: ApplicationState) =>
            state.menuDetails.menu.sections.find((x) => x.id === props.sectionId)!
    );
    const lang = useSelector((state: ApplicationState) => state.global.language);
    const [expanded, setExpanded] = React.useState<boolean>(!!section.dishes?.length);
    const [selectedDishesIds, setSelectedDishesIds] = React.useState<string[]>([]);
    const [itemCount, setItemCount] = React.useState(0);

    useEffect(() => {
        checkIfExpanded();
        dispatch(ingredientsActions.getAllergies(lang));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function checkIfExpanded() {
        let isExpanded = localStorage.getItem(`@expanded-${section.id}`);
        if (isExpanded) {
            setExpanded(isExpanded === '1');
        }
    }

    function handleChange(event: React.ChangeEvent<{}>) {
        event.stopPropagation();
        if (props.isModal && !section.isDisabled && !!section.price) {
            setSelectedDishesIds(section.dishes.map((x) => x.id));
        } else {
            setExpanded(!expanded);
            localStorage.setItem(`@expanded-${section.id}`, (expanded ? 0 : 1).toString());
        }
    }

    function selectDishes(id: string) {
        if (!!section.price) {
            setSelectedDishesIds(section.dishes.map((x) => x.id));
        } else {
            setSelectedDishesIds([id]);
        }
    }

    function deleteSection() {
        dispatch(
            MenuDetailsStore.actionCreators.deleteSection({
                menuId: menu.id,
                sectionId: props.sectionId,
            })
        );
    }

    function deleteDish(dishId: string) {
        dispatch(
            MenuDetailsStore.actionCreators.deleteDish({
                dishId,
                sectionId: props.sectionId,
                menuId: menu.id,
            })
        );
    }

    function disableDish(dishId: string) {
        dispatch(
            MenuDetailsStore.actionCreators.disableDish({
                dishId,
                sectionId: props.sectionId,
                menuId: menu.id,
            })
        );
    }

    function updateDish(model: DishCreateModel, id: string) {
        dispatch(MenuDetailsStore.actionCreators.updateItem(model, id, section.id));
    }

    function handleAmount(e: any, decrease: boolean = false) {
        e.stopPropagation();
        const item: OrderItem = {
            price: section.price!,
            name: section.name,
            id: section.id,
            uniqueId: helperFunctions.getRandomString(),
            amount: 1,
            shortDescription: '',
            orderId: order.id,
            isReady: false,
            orderNumber: order.orderNumber,
            comment: order.comment,
            service: order.service,
            ingredients: [],
            status: OrderDishStatus.None,
            categoryId: '',
            categoryName: '',
            dailyMenuId: section.id,
            dishes: [...section.dishes],
        };
        setItemCount(decrease ? (itemCount > 0 ? itemCount - 1 : 0) : itemCount + 1);
        dispatch(actionCreators.setSelectedDish(item.id, section.id));
        return dispatch(actionCreators.addItemToOrder(item, decrease ? -1 : 1));
    }

    function prepareAmountCounter() {
        return (
            <div className={classes.itemAmountComponent}>
                <div className={classes.itemAmountAction} onClick={(e) => handleAmount(e, true)}>
                    -
                </div>
                <div className={classes.itemAmountCount}>{itemCount}</div>
                <div className={classes.itemAmountAction} onClick={(e) => handleAmount(e, false)}>
                    +
                </div>
            </div>
        );
    }

    return (
        <Accordion square expanded={expanded} onChange={handleChange} className={classes.root}>
            <AccordionSummary
                style={{
                    backgroundColor: !expanded ? theme.palette.grey['200'] : '',
                    opacity: props.isDisabled ? 0.4 : 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
                aria-controls="panel1d-content"
                id="panel1d-header"
            >
                <Text text={section.name} maxLength={50} />
                <div className={classes.rightSideContainer}>
                    {menu.type === MenuType.Daily && (
                        <label htmlFor="" className={classes.menuLabel}>
                            {i18n.t('common.price')}:
                            <div>
                                <span className={classes.chf}>
                                    {i18n.t('common.currencies.chf')}
                                </span>
                                <span className={classes.currencyText}>
                                    {priceStyleHelper(section.price, true)}
                                </span>
                            </div>
                        </label>
                    )}
                    {props.isModal &&
                        !section.isDisabled &&
                        !!section.price &&
                        section.dishes.every((x) => selectedDishesIds.includes(x.id)) &&
                        prepareAmountCounter()}
                    {user.role === Roles.OWNER && (
                        <ThreeDotsSectionMenu
                            menuType={menu.type}
                            section={section}
                            sections={menu.sections}
                            createDishAction={MenuDetailsStore.actionCreators.addNewItem}
                            onDeleteSectionClick={deleteSection}
                            onChooseExisting={props.onChooseExisting}
                        />
                    )}
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {section.dishes?.length > 0 &&
                    section.dishes?.map((x) => (
                        <DishPreviewItem
                            dish={x}
                            id={x.id}
                            onItemDelete={() => deleteDish(x.id)}
                            onItemEdit={() => props.onDishEdit(x.id)}
                            onItemDisable={() => disableDish(x.id)}
                            key={x.id}
                            createDishAction={MenuDetailsStore.actionCreators.addNewItem}
                            updateDishAction={updateDish}
                            isModal={props.isModal}
                            isSelected={selectedDishesIds.includes(x.id)}
                            setSelected={selectDishes}
                            isDailyMenuDish={!!section.price}
                            dailyMenuId={!!section.price ? section.id : undefined}
                            isDisabled={section.isDisabled}
                        />
                    ))}
            </AccordionDetails>
        </Accordion>
    );
};
