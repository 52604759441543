import React from 'react';

import { Dialog, makeStyles, createStyles } from '@material-ui/core';
import { MenusPage } from '../MenusTable/MenusPage';
import { OrderUpdateModel } from 'api/agent';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '& .MuiDialog-paperWidthLg': {
                width: 1115,
            },
            '& .MuiPaper-root.MuiDialog-paper': {
                height: '90%',
                maxHeight: 776,
                overflow: 'hidden',
            },
        },
    })
);

export default function MenusModal(props: {
    isOpen: boolean;
    onClose: () => void;
    saveChanges: (model: OrderUpdateModel | null) => void;
}) {
    const classes = useStyles();

    return (
        <Dialog
            maxWidth={'lg'}
            open={props.isOpen}
            aria-labelledby="form-dialog-title"
            className={classes.root}
        >
            <MenusPage isModal={true} onClose={props.onClose} saveChanges={props.saveChanges} />
        </Dialog>
    );
}
