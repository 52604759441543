import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import i18n from 'i18n-js';

import agent from 'api/agent';
import { HourData } from 'components/restaurantInformation/components';
import { actionCreators as globalActions, GlobalAction } from './globalStore';
import { Positions } from 'constants/enums';

export interface CategoryDishData {
    id: string;
    name: string;
    categoryId: string | null;
}

export interface CategoryDetails {
    name: string;
    id: string;
    isDisabled: boolean;
    position: Positions;
    openHours: HourData[];
}

export interface CategoryDetailsData {
    categories: CategoryDetails[];
    dishes: CategoryDishData[];
}

//STATE
export interface CategoryState {
    categoryDetails: CategoryDetailsData | null;
}

//ACTIONS
interface GetCategoryDetails {
    type: 'GET_CATEGORY_DETAILS_START';
}

interface GetCategoryDetailsSuccess {
    type: 'GET_CATEGORY_DETAILS_SUCCESS';
    data: any;
}

interface GetCategoryDetailsError {
    type: 'GET_CATEGORY_DETAILS_ERROR';
}

interface CreateCategoryStart {
    type: 'CREATE_CATEGORY_START';
}

interface CreateCategorySuccess {
    type: 'CREATE_CATEGORY_SUCCESS';
    id: string;
    name: string;
    openHours: HourData[];
}

interface CreateCategoryError {
    type: 'CREATE_CATEGORY_ERROR';
}

interface EditCategoryStart {
    type: 'EDIT_CATEGORY_START';
}

interface EditCategorySuccess {
    type: 'EDIT_CATEGORY_SUCCESS';
}

interface EditCategoryError {
    type: 'EDIT_CATEGORY_ERROR';
}

interface DisableOrEnableCategoriesStart {
    type: 'DISABLE_OR_ENABLE_CATEGORIES_START';
}

interface DisableOrEnableCategoriesSuccess {
    type: 'DISABLE_OR_ENABLE_CATEGORIES_SUCCESS';
    result: {
        categoriesIds: string[];
        isDisabled: boolean;
    };
}
interface DisableOrEnableCategoriesError {
    type: 'DISABLE_OR_ENABLE_CATEGORIES_ERROR';
}

export interface CreateCategoryModel {
    Name: string;
    Dishes: string[];
    openHours: HourData[];
    position: string;
}

//ACTION TYPES
export type CategoryAction =
    | GetCategoryDetails
    | GetCategoryDetailsSuccess
    | GetCategoryDetailsError
    | CreateCategoryStart
    | CreateCategorySuccess
    | CreateCategoryError
    | EditCategoryStart
    | EditCategorySuccess
    | EditCategoryError
    | DisableOrEnableCategoriesStart
    | DisableOrEnableCategoriesSuccess
    | DisableOrEnableCategoriesError
    | GlobalAction;

//ACTION CREATORS
export const actionCreators = {
    getCategoryDetails:
        (asc: boolean = false, orderBy: string = 'name'): AppThunkAction<CategoryAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_CATEGORY_DETAILS_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Category.GetCategoryDetails(asc, orderBy)
                .then((res) => {
                    dispatch({
                        type: 'GET_CATEGORY_DETAILS_SUCCESS',
                        data: res,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_CATEGORY_DETAILS_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },

    createCategory:
        (model: CreateCategoryModel): AppThunkAction<CategoryAction> =>
        (dispatch) => {
            dispatch({
                type: 'CREATE_CATEGORY_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Category.CreateCategory(model)
                .then((res) => {
                    dispatch({
                        type: 'CREATE_CATEGORY_SUCCESS',
                        id: res.id,
                        name: res.name,
                        openHours: res.openHours,
                    });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.createdSuccessfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'CREATE_CATEGORY_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },

    editCategory:
        (model: CreateCategoryModel, categoryId: string): AppThunkAction<CategoryAction> =>
        (dispatch) => {
            dispatch({
                type: 'EDIT_CATEGORY_START',
            });
            globalActions.showSpiner()(dispatch);
            if (
                model.openHours.length === 1 &&
                model.openHours[0].from === '' &&
                model.openHours[0].to === ''
            ) {
                model.openHours = [];
            }
            agent.Category.EditCategory(model, categoryId)
                .then(() => {
                    // For now better to get updated categories from server
                    // Because dont know how some cases would be implemented
                    // @ts-ignore
                    actionCreators.getCategoryDetails()(dispatch);
                    dispatch({
                        type: 'EDIT_CATEGORY_SUCCESS',
                    });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'EDIT_CATEGORY_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },

    disableOrEnableCategory:
        (categoriesIds: string[]): AppThunkAction<CategoryAction> =>
        (dispatch) => {
            dispatch({
                type: 'DISABLE_OR_ENABLE_CATEGORIES_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Category.DisableOrEnableCategory(categoriesIds)
                .then((result) => {
                    dispatch({
                        type: 'DISABLE_OR_ENABLE_CATEGORIES_SUCCESS',
                        result,
                    });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'DISABLE_OR_ENABLE_CATEGORIES_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
};

//REDUCER
const initialState: CategoryState = {
    categoryDetails: null,
};

export const reducer: Reducer<CategoryState> = (
    state: CategoryState | undefined = initialState,
    incomingAction: Action
): CategoryState => {
    const action = incomingAction as CategoryAction;

    switch (action.type) {
        case 'GET_CATEGORY_DETAILS_SUCCESS': {
            return {
                ...state,
                categoryDetails: action.data,
            };
        }
        case 'CREATE_CATEGORY_SUCCESS': {
            const newCategoryDetails: any = { ...state?.categoryDetails };
            newCategoryDetails.categories.push({
                id: action.id,
                name: action.name,
                openHours: action.openHours,
            });
            return {
                ...state,
                categoryDetails: newCategoryDetails,
            };
        }
        case 'DISABLE_OR_ENABLE_CATEGORIES_SUCCESS': {
            const newCategoryDetails: any = { ...state?.categoryDetails };
            newCategoryDetails.categories = newCategoryDetails.categories
                ? newCategoryDetails.categories.map((category: { id: string }) =>
                      action.result.categoriesIds.includes(category.id)
                          ? { ...category, isDisabled: action.result.isDisabled }
                          : category
                  )
                : newCategoryDetails.categories;
            return {
                ...state,
                categoryDetails: newCategoryDetails as CategoryDetailsData,
            };
        }
        default:
            return state;
    }
};
