import React, { useState } from 'react';

import * as yup from 'yup';
import i18n from 'i18n-js';
import { Button, TextField, DialogActions } from '@material-ui/core';
import { Formik, getIn } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import * as FormikFields from 'formik-material-ui';
import { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { RestaurantData } from 'store/restaurantsStore';
import { regexHelper } from 'helpers/regexHelper';
import { RestaurantUserEditModel, UserData } from 'store/usersStore';
import { Roles } from 'constants/enums';
import { CustomPhoneInput, Input } from 'components/shared';
import { images } from '../../../assets';
import PositionSelect from './PositionSelect';
import { useAppSelector } from 'index';

interface EditRestaurantUserProps {
    user: UserData;
    editRestaurantUser: any;
    cancel: any;
    close: any;
    restaurants: RestaurantData[];
}

export default function EditRestaurantUserForm(props: EditRestaurantUserProps) {
    const browserData = useAppSelector((state) => state.user.browserData);

    const getUserRestaurant = () => {
        let found;
        for (let i = 0; i < props.user.restaurants.length; i++) {
            const eachUserRestaurant = props.user.restaurants[i];
            found = props.restaurants.find(
                (eachRes) => eachRes.id === eachUserRestaurant.restaurantId
            );

            if (found) break;
        }
        return found;
    };
    const [userRestaurant] = useState(
        getUserRestaurant() ?? {
            id: '',
            name: '',
            addressLine: '',
            zip: '',
            city: '',
            logoUrl: '',
        }
    );
    const initalFormValues: RestaurantUserEditModel = {
        restaurant: userRestaurant,
        id: props.user.id,
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        email: props.user.email,
        phoneNumber: props.user.phoneNumber,
        positions:
            props.user.restaurants.find((x) => x.restaurantId === userRestaurant.id)?.positions ??
            [],
        isContactPerson:
            props.user.restaurants.find((x) => x.restaurantId === userRestaurant.id)
                ?.isContactPerson ?? false,
        isOwner: props.user.roles[0]?.name === Roles.OWNER,
    };

    const validationSchema = yup.object().shape({
        restaurant: yup.object().required('Required'),
        firstName: yup
            .string()
            .matches(
                regexHelper.onlyLetters,
                i18n.t('form.errors.lettersOnly', {
                    name: 'First Name',
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'First Name',
                })
            ),
        lastName: yup
            .string()
            .matches(
                regexHelper.onlyLetters,
                i18n.t('form.errors.lettersOnly', {
                    name: 'Last Name',
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'Last Name',
                })
            ),
        email: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Email',
                })
            )
            .email(i18n.t('form.errors.email')),
        phoneNumber: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Phone Number',
                })
            )
            .matches(regexHelper.phoneNumber, i18n.t('form.errors.phoneNumber')),
        positions: yup.array().of(
            yup.string().min(
                1,
                i18n.t('form.errors.required', {
                    name: 'Position',
                })
            )
        ),
    });

    const submitPromise = async (model: RestaurantUserEditModel) => {
        props.close();
        props.editRestaurantUser(model);
    };

    return (
        <Formik
            initialValues={initalFormValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm, setFieldValue }) => {
                if (values.restaurant?.id.length > 0) {
                    const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber);
                    if (parsedPhoneNumber) {
                        if (!parsedPhoneNumber.country) {
                            const fixedPhoneNumber = values.phoneNumber.replace(
                                '+' + parsedPhoneNumber.countryCallingCode.toString(),
                                browserData?.country_calling_code
                            );
                            setFieldValue('phoneNumber', fixedPhoneNumber);
                            values.phoneNumber = fixedPhoneNumber;
                        }
                    }

                    submitPromise(values).then(() => {
                        resetForm();
                        props.cancel();
                    });
                }
            }}
        >
            {({ submitForm, values, setFieldValue, errors, touched, dirty }) => {
                return (
                    <form>
                        <Autocomplete
                            id="restaurant"
                            disabled
                            style={{ position: 'relative' }}
                            options={props.restaurants}
                            getOptionLabel={(option) => option.name}
                            value={values.restaurant}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e: object, value: any | null) => {
                                setFieldValue('restaurant', value);
                            }}
                            renderInput={(params) => (
                                <>
                                    <img
                                        style={{
                                            width: 23,
                                            height: 23,
                                            position: 'absolute',
                                            borderRadius: '50%',
                                            left: 5,
                                            bottom: 35,
                                        }}
                                        src={
                                            values.restaurant.logoUrl
                                                ? values.restaurant.logoUrl
                                                : images.icons.logoNoText
                                        }
                                        alt="restaurant-logo"
                                    />
                                    <TextField
                                        {...params}
                                        name="restaurant"
                                        label="Restaurant"
                                        inputProps={{
                                            style: {
                                                padding: 10,
                                                marginLeft: 15,
                                            },
                                            ...params.inputProps,
                                        }}
                                        variant="filled"
                                        fullWidth
                                        error={
                                            values.restaurant?.name.length === 0 &&
                                            getIn(touched, 'restaurant')
                                        }
                                        helperText={
                                            values.restaurant?.name.length === 0 &&
                                            getIn(touched, 'restaurant')
                                                ? i18n.t('form.errors.required', {
                                                      name: 'Restaurant',
                                                  })
                                                : null
                                        }
                                    />
                                </>
                            )}
                        />

                        <Input
                            variant="filled"
                            error={errors.firstName}
                            fullWidth={true}
                            name={'firstName'}
                            type="text"
                            label={i18n.t('form.firstName')}
                            placeholder={i18n.t('form.firstName')}
                            onBlur={(e: any) => setFieldValue('firstName', e.target.value.trim())}
                        />
                        <Input
                            variant="filled"
                            error={errors.lastName}
                            fullWidth={true}
                            name={'lastName'}
                            type="text"
                            label={i18n.t('form.lastName')}
                            placeholder={i18n.t('form.lastName')}
                            onBlur={(e: any) => setFieldValue('lastName', e.target.value.trim())}
                        />
                        <Input
                            variant="filled"
                            error={errors.email}
                            fullWidth={true}
                            name={'email'}
                            type="text"
                            label={i18n.t('form.email')}
                            placeholder={i18n.t('form.email')}
                        />
                        <CustomPhoneInput
                            invalid={errors.phoneNumber}
                            placeholder={i18n.t('form.phoneNumber')}
                            value={values.phoneNumber}
                            onChange={(val: string) => setFieldValue('phoneNumber', val)}
                        />
                        <PositionSelect
                            values={values.positions}
                            error={!touched && !values.positions.length}
                            onChange={(val) => setFieldValue('positions', val)}
                        />
                        <div style={{ textAlign: 'start' }}>
                            <Input
                                component={FormikFields.CheckboxWithLabel}
                                variant="filled"
                                name={'isContactPerson'}
                                type="checkbox"
                                Label={{ label: i18n.t('form.contactPerson') }}
                                placeholder={i18n.t('form.contactPerson')}
                            />
                        </div>
                        <DialogActions>
                            <Button type="button" color="primary" onClick={props.cancel}>
                                {i18n.t('button.cancel')}
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                disabled={!dirty}
                                onClick={submitForm}
                            >
                                {i18n.t('button.save')}
                            </Button>
                        </DialogActions>
                    </form>
                );
            }}
        </Formik>
    );
}
