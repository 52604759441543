import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import i18n from 'i18n-js';
import * as FormikFields from 'formik-material-ui';
import { Autocomplete } from '@material-ui/lab';
import { alpha, TextField, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import { DishCreateModel, IngredientModel } from 'api/agent';
import { SectionData } from 'store/menuDetailsStore';
import { images } from 'assets';
import commonStyles from 'config/commonStyles';
import { ApplicationState } from 'store';
import { actionCreators as categoryStoreActionCreators } from 'store/categoryStore';
import { AddIngredientsModal, Input } from 'components/shared';
import AllergensSelect from 'components/shared/components/AllergensSelect';
import ImageContainer, { ImageContainerRefProps } from '../../../shared/components/ImageContainer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:focus': {
                backgroundColor: theme.palette.primary.main,
                '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                    color: theme.palette.common.white,
                },
            },
            '& img': {
                width: 'auto',
                maxHeight: 171,
                height: 'auto',
            },
            '& .MuiFormHelperText-root.Mui-error': {
                position: 'absolute',
                bottom: -17,
            },
        },
        formEdit: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '48%',
        },
        formWrapper: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
        },
        dialog: {
            '& .MuiDialogTitle-root > h2': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        bottomPanel: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '40px 0 30px 0',
        },
        actionsArea: {
            '&>span': {
                color: theme.palette.primary.main,
                cursor: 'pointer',
            },
            '& button': {
                margin: 0,
            },
            '&>div': {
                width: 180,
                display: 'flex',
                justifyContent: 'space-between',
            },
        },
        bottomContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        bottomIcon: {
            margin: '0 20px 0 -10px',
        },
        manageIngredientButton: {
            width: '100%',
            height: '56px',
            borderRadius: 0,
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
        ...commonStyles,
    })
);

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export interface AddNewDishProps {
    sections: SectionData[];
    sectionId: string;
    createAction: any;
    close: any;
    forThreeDotsMenu: boolean;
}

export default function AddNewDishItem(props: AddNewDishProps) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const categoryDetails = useSelector(
        (state: ApplicationState) => state.category.categoryDetails
    );

    const [open, setOpen] = useState(false);
    const [showAddIngredientsModal, setShowAddIngredientsModal] = useState(false);
    const childRef = useRef<ImageContainerRefProps>(null);
    const dishes = useRef<string[]>([]);

    const initialFormValues = {
        name: '',
        description: '',
        shortDescription: '',
        price: 0,
        photo: '',
        isSpicy: false,
        isVegetarian: false,
        isVegan: false,
        isHalal: false,
        section: props.sectionId ?? '',
        kitchenId: '',
        category: '',
        addToGallery: false,
        photoSelected: false,
        allergens: [],
        ingredients: [],
    };

    function handleClickOpen(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        event.preventDefault();
        setOpen(true);
        props.close();
    }

    function handleClose(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        event.preventDefault();
        setOpen(false);
        props.close();
    }

    function preventProp(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !dishes.current.includes(value?.toUpperCase())
            ),
        shortDescription: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Short description',
            })
        ),
        price: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .min(0.1, i18n.t('form.errors.greaterZero'))
            .required(
                i18n.t('form.errors.required', {
                    name: 'Price',
                })
            ),
        category: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Category',
            })
        ),
        section: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Section',
            })
        ),
    });

    const SubmitPromise = async (model: DishCreateModel) => {
        await dispatch(props.createAction(model));
    };

    function checkPriceFormat(price: string) {
        let value = price.replace(/[^\d.-]/g, '');
        let firstDotIndex = price.indexOf('.');
        if (firstDotIndex !== -1) {
            if (firstDotIndex === 0) {
                value = '0' + value;
            } else {
                let arr = value.replace(/(\.)/g, '').split('');
                arr.splice(firstDotIndex, 0, '.');
                value = arr.join('');
            }
        }

        return value;
    }

    useEffect(() => {
        if (!categoryDetails) {
            dispatch(categoryStoreActionCreators.getCategoryDetails());
        } else {
            dishes.current = categoryDetails!.dishes.map((x) => x.name.toUpperCase());
        }
    }, [categoryDetails]); // eslint-disable-line

    function addToAllergens(
        ingredients: IngredientModel[],
        values: any,
        setFieldValue: (key: string, value: any) => void
    ) {
        const newAllergens: string[] = [...values.allergens];
        ingredients.forEach((eachIngredient) => {
            eachIngredient.allergens.forEach((allergen) => {
                if (!newAllergens.includes(allergen)) {
                    newAllergens.push(allergen);
                }
            });
        });
        setFieldValue('allergens', newAllergens);
    }

    return (
        <>
            {props.forThreeDotsMenu ? (
                <StyledMenuItem color="primary" onClick={handleClickOpen}>
                    <AddIcon fontSize="small" color="disabled" style={{ marginRight: 23 }} />
                    <ListItemText primary={i18n.t('restaurant.createNewItem')} />
                </StyledMenuItem>
            ) : (
                <Button variant="contained" color="primary" onClick={handleClickOpen}>
                    {i18n.t('button.newItem')}
                </Button>
            )}
            <Dialog
                maxWidth={'md'}
                onClick={preventProp}
                open={open}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">
                    {i18n.t('restaurant.createNewItem')}
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialFormValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            childRef?.current
                                ?.uploadImage()
                                .then((photo) => {
                                    if (photo.length > 0) values.photo = photo;
                                    const model: DishCreateModel = {
                                        name: values.name,
                                        description: values.description,
                                        shortDescription: values.shortDescription,
                                        price: values.price,
                                        photo: values.photo,
                                        isSpicy: values.isSpicy,
                                        isVegetarian: values.isVegetarian,
                                        isVegan: values.isVegan,
                                        isHalal: values.isHalal,
                                        sectionId: values.section,
                                        kitchenId: '',
                                        addToGallery: values.addToGallery,
                                        categoryId: values.category,
                                        allergens: values.allergens,
                                        ingredients: values.ingredients,
                                    };
                                    SubmitPromise(model).then(() => {
                                        resetForm();
                                        setOpen(false);
                                    });
                                })
                                .catch((error) => alert(error));
                        }}
                    >
                        {({ values, touched, setFieldValue, errors, submitForm }) => (
                            <Form className={classes.root}>
                                <div className={classes.formWrapper}>
                                    <div className={classes.formEdit}>
                                        <div className={classes.photoContainer}>
                                            <ImageContainer
                                                ref={childRef}
                                                photo={values.photo}
                                                onChange={(selected) =>
                                                    setFieldValue('photoSelected', selected)
                                                }
                                            />
                                        </div>
                                        <Input
                                            error={errors.name}
                                            variant="filled"
                                            fullWidth={true}
                                            name={`name`}
                                            type="text"
                                            label={i18n.t('form.itemName')}
                                            placeholder={i18n.t('form.itemName')}
                                        />
                                        <br />
                                        <Input
                                            error={errors.shortDescription}
                                            variant="filled"
                                            fullWidth={true}
                                            name={`shortDescription`}
                                            type="text"
                                            label={i18n.t('form.shortDescription')}
                                            placeholder={i18n.t('form.shortDescription')}
                                        />
                                        <br />
                                        <Input
                                            variant="filled"
                                            fullWidth={true}
                                            name={`description`}
                                            type="textarea"
                                            label={i18n.t('form.description')}
                                            placeholder={i18n.t('form.description')}
                                        />
                                    </div>
                                    <div className={classes.formEdit}>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: 5,
                                                top: 10,
                                            }}
                                        >
                                            <Input
                                                disabled={!values.photoSelected}
                                                component={FormikFields.CheckboxWithLabel}
                                                variant="filled"
                                                name={'addToGallery'}
                                                type="checkbox"
                                                Label={{ label: i18n.t('form.addPhotoToGallery') }}
                                                placeholder={i18n.t('form.addPhotoToGallery')}
                                                color="primary"
                                            />
                                        </div>
                                        <br />
                                        <br />
                                        <div>
                                            <Autocomplete
                                                id="section"
                                                options={props.sections.map((x) => x.id)}
                                                getOptionLabel={(option) =>
                                                    props.sections.find((x) => x.id === option)
                                                        ?.name ?? 'Section'
                                                }
                                                value={values.section}
                                                getOptionSelected={(option, value) =>
                                                    option === value
                                                }
                                                onChange={(e, value) => {
                                                    setFieldValue('section', value || '');
                                                }}
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField
                                                            {...params}
                                                            name="section"
                                                            variant="filled"
                                                            fullWidth
                                                            label={
                                                                Boolean(
                                                                    touched.section &&
                                                                        errors.section
                                                                )
                                                                    ? i18n.t(
                                                                          'form.errors.required',
                                                                          {
                                                                              name: 'Section',
                                                                          }
                                                                      )
                                                                    : i18n.t('common.section')
                                                            }
                                                            error={Boolean(
                                                                touched.section && errors.section
                                                            )}
                                                        />
                                                    </>
                                                )}
                                            />
                                            <br />
                                            <Autocomplete
                                                id="category"
                                                options={
                                                    categoryDetails?.categories.map((x) => x.id) ||
                                                    []
                                                }
                                                getOptionLabel={(option) =>
                                                    categoryDetails?.categories.find(
                                                        (x) => x.id === option
                                                    )?.name ?? ''
                                                }
                                                value={values.category}
                                                getOptionSelected={(option, value) =>
                                                    option === value
                                                }
                                                onChange={(e, value) =>
                                                    setFieldValue('category', value || '')
                                                }
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField
                                                            {...params}
                                                            name="categories"
                                                            label={
                                                                Boolean(
                                                                    touched.category &&
                                                                        errors.category
                                                                )
                                                                    ? i18n.t(
                                                                          'form.errors.required',
                                                                          {
                                                                              name: 'Category',
                                                                          }
                                                                      )
                                                                    : i18n.t('common.categories')
                                                            }
                                                            variant="filled"
                                                            fullWidth
                                                            error={Boolean(
                                                                touched.category && errors.category
                                                            )}
                                                        />
                                                    </>
                                                )}
                                            />
                                            <br />
                                            <Input
                                                variant="filled"
                                                fullWidth={true}
                                                name={`price`}
                                                type="text"
                                                label={i18n.t('form.price')}
                                                placeholder={i18n.t('form.price')}
                                                onChange={(e: any) =>
                                                    setFieldValue(
                                                        'price',
                                                        checkPriceFormat(e.target.value)
                                                    )
                                                }
                                            />
                                            <br />
                                            <br />
                                            <AllergensSelect
                                                values={values.allergens}
                                                onChange={(vals: string[]) =>
                                                    setFieldValue('allergens', vals)
                                                }
                                            />
                                            <br />
                                            <br />
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                className={classes.manageIngredientButton}
                                                onClick={() => setShowAddIngredientsModal(true)}
                                            >
                                                {`+ ${i18n
                                                    .t('restaurant.manageIngredients')
                                                    .toUpperCase()}`}
                                            </Button>
                                            <AddIngredientsModal
                                                isVisible={showAddIngredientsModal}
                                                onClose={() => {
                                                    setShowAddIngredientsModal(false);
                                                }}
                                                onSave={(ingredients) => {
                                                    addToAllergens(
                                                        ingredients,
                                                        values,
                                                        setFieldValue
                                                    );
                                                    setFieldValue('ingredients', ingredients);
                                                    setShowAddIngredientsModal(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.bottomPanel}>
                                    <div className={classes.bottomContainer}>
                                        <Input
                                            component={FormikFields.CheckboxWithLabel}
                                            variant="filled"
                                            name={'isSpicy'}
                                            type="checkbox"
                                            Label={{ label: i18n.t('form.spicy') }}
                                            placeholder={i18n.t('form.spicy')}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            color="primary"
                                        />
                                        <img
                                            src={images.icons.chili}
                                            alt="chili-pepper"
                                            className={classes.bottomIcon}
                                        />
                                        <Input
                                            component={FormikFields.CheckboxWithLabel}
                                            variant="filled"
                                            name={'isVegetarian'}
                                            type="checkbox"
                                            Label={{ label: i18n.t('form.vegetarian') }}
                                            placeholder={i18n.t('form.vegetarian')}
                                            color="primary"
                                        />
                                        <img
                                            src={images.icons.leaf}
                                            alt="green-leaf"
                                            className={classes.bottomIcon}
                                        />
                                        <Input
                                            component={FormikFields.CheckboxWithLabel}
                                            variant="filled"
                                            name={'isVegan'}
                                            type="checkbox"
                                            Label={{ label: i18n.t('form.vegan') }}
                                            placeholder={i18n.t('form.vegan')}
                                            color="primary"
                                        />
                                        <img
                                            src={images.icons.doubleLeaf}
                                            alt="double-leaf"
                                            className={classes.bottomIcon}
                                        />
                                        <Input
                                            component={FormikFields.CheckboxWithLabel}
                                            variant="filled"
                                            name={'isHalal'}
                                            type="checkbox"
                                            Label={{ label: i18n.t('form.halal') }}
                                            placeholder={i18n.t('form.halal')}
                                            color="primary"
                                        />
                                        <img
                                            src={images.icons.halal}
                                            alt="halal"
                                            className={classes.bottomIcon}
                                        />
                                    </div>
                                    <div className={classes.actionsArea}>
                                        <span></span>
                                        <div>
                                            <Button
                                                type="button"
                                                color="primary"
                                                onClick={handleClose}
                                            >
                                                {i18n.t('button.cancel')}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={submitForm}
                                            >
                                                {i18n.t('button.create')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
}
