import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import i18n from 'i18n-js';
import {
    alpha,
    createStyles,
    IconButton,
    ListItemIcon,
    makeStyles,
    Paper,
    Theme,
    Toolbar,
    Typography,
    Button,
} from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

import AddSectionModal from './components/AddSectionModal';
import ChooseExistingModal from './components/ChooseExistingModal';
import AddDailyMenuSectionModal from './components/AddDailyMenuSectionModal';
import { MenuEditModal } from '../Modals/MenuEditModal';
import { BasicThreeDotsMenu, Text } from 'components/shared';
import { MenuSectionsTable } from '../MenuSectionManagement/MenuSectionsTable';
import logo from 'assets/breakfast-logo.svg';
import commonStyles from 'config/commonStyles';
import { MenuType, Roles } from 'constants/enums';
import * as MenuDetailsStore from 'store/menuDetailsStore';
import { ApplicationState } from 'store';
import * as MenusStore from 'store/menusStore';
import { DishData } from 'store/menuDetailsStore';
import MenuIngredientManagement from './components/MenuIngredientManagement';

interface MenuDetailsProps {
    close?: () => void;
    isModal?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        root: {
            height: '100%',
            width: '100%',
            '& .MuiListItem-gutters': {
                padding: '0',
            },
            '& .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters': {
                padding: '0 16px',
            },
            '& .MuiTableContainer-root': {
                height: 'auto',
            },
        },
        table: {
            minWidth: 650,
        },
        dots: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        breakfastLogo: {
            marginLeft: '15px',
            marginRight: '15px',
        },
        menuLabel: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            color: theme.palette.common.black,
            marginRight: '10px',
            minWidth: '438px',
            height: '41px',
            background: theme.palette.secondary.light,
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: '5px',
        },
        menuPrice: {
            fontWeight: 900,
            fontSize: '16px',
            lineHeight: '19px',
            color: theme.palette.primary.main,
        },
        toolbarTitle: {
            flex: '1 1 80%',
        },
        iconButton: {
            color: theme.palette.common.black,
            marginRight: '10px',
        },
        menuSectionsContainer: {
            //minHeight: 728,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            background: theme.palette.common.white,
            width: '100%',
            '& .MuiPaper-root': {
                height: 'auto',
            },
            height: 'calc(100% - 60px)',
            overflowY: 'auto',
        },
        forModal: {
            height: '100%',
        },
        dailyMenu: {
            color: theme.palette.secondary.main,
        },
        itemAmount: {
            position: 'absolute',
            right: '5%',
            fontSize: '14px',
            color: alpha(theme.palette.common.black, 0.87),
            fontWeight: 'normal',
        },
    })
);

export default function MenuManagementTable(props: MenuDetailsProps) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const dishList = useSelector((state: ApplicationState) => state.menus.dishList);
    const user = useSelector((state: ApplicationState) => state.user);
    const menu = useSelector((state: ApplicationState) => state.menuDetails.menu);
    const [showAddSectionModal, setShowAddSectionModal] = useState(false);
    const [showChooseExistingModal, setShowChooseExistingModal] = useState(false);
    const [showEditMenuModal, setShowEditMenuModal] = useState(false);
    const [selectedSectionId, setSelectedSectionId] = useState('');

    const menuType = menu.type;

    function addSection(sectionName: string) {
        setShowAddSectionModal(false);
        dispatch(
            MenuDetailsStore.actionCreators.addSection({
                name: sectionName,
                menuId: menu.id,
            })
        );
    }

    function addExistingDish(sectionId: string, dishId: string, dish: DishData) {
        hideExistingModal();
        dispatch(
            MenuDetailsStore.actionCreators.addExistingDish({
                sectionId,
                dishId,
                dish,
            })
        );
    }

    function showExistingModal(sectionId: string) {
        setSelectedSectionId(sectionId);
        setShowChooseExistingModal(true);
    }

    function hideExistingModal() {
        setShowChooseExistingModal(false);
        setSelectedSectionId('');
    }

    function handleBackButtonClick() {
        !!props.close ? props.close() : history.goBack();
    }

    useEffect(() => {
        dispatch(MenusStore.actionCreators.getDishList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getTotalItemAmount() {
        let total = 0;
        menu.sections.forEach((each) => {
            each.dishes.filter((dish) => !dish.isDisabled).forEach((dish) => (total += 1));
        });
        return total;
    }

    return (
        <div className={classes.root}>
            {!props.isModal && (
                <Toolbar style={{ paddingBottom: '10px' }}>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className={classes.iconButton}
                        onClick={handleBackButtonClick}
                    >
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                    <Typography
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        className={classes.toolbarTitle}
                    >
                        <Text text={menu.name} maxLength={40} fontSize={18} />
                        <img src={logo} className={classes.breakfastLogo} alt="logo" />
                        {menuType === MenuType.Daily && (
                            <span className={classes.dailyMenu}>/ Daily menu</span>
                        )}
                    </Typography>
                    {menuType === MenuType.Daily && <></>}
                    {menuType === MenuType.Regular && (
                        <Typography align={'right'} className={classes.itemAmount}>
                            {`${getTotalItemAmount() || 0} ${i18n.t('common.items')}`}
                        </Typography>
                    )}
                    {user.role === Roles.OWNER &&
                        (menuType === MenuType.Daily ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setShowAddSectionModal(true)}
                            >
                                {`+ ${i18n.t('restaurant.newMenu').toUpperCase()}`}
                            </Button>
                        ) : (
                            <BasicThreeDotsMenu
                                items={
                                    menuType === MenuType.Regular
                                        ? [
                                              <div
                                                  className={classes.threeDotsMenuItemContainer}
                                                  onClick={() => setShowAddSectionModal(true)}
                                              >
                                                  <ListItemIcon>
                                                      <AddIcon fontSize="small" color="disabled" />
                                                  </ListItemIcon>
                                                  <Typography variant="inherit">
                                                      {i18n.t('common.addSection')}
                                                  </Typography>
                                              </div>,
                                              <div
                                                  className={classes.threeDotsMenuItemContainer}
                                                  onClick={() => {
                                                      setShowEditMenuModal(true);
                                                  }}
                                              >
                                                  <ListItemIcon>
                                                      <BorderColorIcon
                                                          fontSize="small"
                                                          color="disabled"
                                                      />
                                                  </ListItemIcon>
                                                  <Typography variant="inherit">
                                                      {i18n.t('common.edit')}
                                                  </Typography>
                                              </div>,
                                          ]
                                        : [
                                              <div
                                                  className={classes.threeDotsMenuItemContainer}
                                                  onClick={() => {
                                                      setShowEditMenuModal(true);
                                                  }}
                                              >
                                                  <ListItemIcon>
                                                      <BorderColorIcon
                                                          fontSize="small"
                                                          color="disabled"
                                                      />
                                                  </ListItemIcon>
                                                  <Typography variant="inherit">
                                                      {i18n.t('common.edit')}
                                                  </Typography>
                                              </div>,
                                          ]
                                }
                            />
                        ))}
                </Toolbar>
            )}

            <Paper
                className={clsx({
                    [classes.forModal]: props.isModal,
                    [classes.menuSectionsContainer]: props.isModal,
                })}
            >
                {props.isModal ? (
                    <>
                        <div style={{ width: '67%' }}>
                            {menu.sections.length > 0 &&
                                menu.sections.map((section: any, i) => (
                                    <MenuSectionsTable
                                        key={section.name}
                                        onChooseExisting={() => showExistingModal(section.id)}
                                        onDishEdit={section.onDishEdit}
                                        sectionId={section.id}
                                        isDisabled={section.isDisabled}
                                        isModal={props.isModal}
                                    />
                                ))}
                        </div>
                        <div style={{ width: '30%' }}>
                            <MenuIngredientManagement />
                        </div>
                    </>
                ) : (
                    menu.sections.length > 0 &&
                    menu.sections.map((section: any, i) => (
                        <MenuSectionsTable
                            key={section.name}
                            onChooseExisting={() => showExistingModal(section.id)}
                            onDishEdit={section.onDishEdit}
                            sectionId={section.id}
                            isDisabled={section.isDisabled}
                            isModal={props.isModal}
                        />
                    ))
                )}
            </Paper>
            <AddSectionModal
                isVisible={showAddSectionModal && menu.type === MenuType.Regular}
                onClose={() => setShowAddSectionModal(false)}
                onAdd={(sectionName) => addSection(sectionName)}
                sections={menu.sections.map((x) => x.name.toUpperCase())}
            />
            <AddDailyMenuSectionModal
                isVisible={showAddSectionModal && menu.type === MenuType.Daily}
                onClose={() => setShowAddSectionModal(false)}
                onAdd={(section) => {
                    dispatch(
                        MenuDetailsStore.actionCreators.addSection({
                            ...section,
                            menuId: menu.id,
                        })
                    );
                    setShowAddSectionModal(false);
                }}
                menus={menu.sections.map((x) => x.name.toUpperCase())}
            />
            <ChooseExistingModal
                dishList={dishList}
                sections={menu.sections}
                isVisible={showChooseExistingModal}
                onClose={() => setShowChooseExistingModal(false)}
                onAdd={(sectionId, dishId, dish) => addExistingDish(sectionId, dishId, dish)}
                sectionId={selectedSectionId}
            />
            <MenuEditModal
                menu={{
                    edit: null,
                    id: menu.id,
                    name: menu.name,
                    description: menu.description,
                    availableFrom: menu.availableFrom,
                    availableTo: menu.availableTo,
                    price: menu.price,
                    photo: menu.photo,
                    type: menu.type,
                    sections: [],
                    dishesCount: menu.dishesCount,
                    isEnabled: menu.isEnabled,
                    position: menu.position,
                }}
                isOpen={showEditMenuModal}
                handleClose={() => setShowEditMenuModal(false)}
                isDetails={true}
            />
        </div>
    );
}
