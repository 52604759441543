import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    createStyles,
    makeStyles,
    Paper,
    TableContainer,
    Theme,
    Toolbar,
    Typography,
} from '@material-ui/core';
import i18n from 'i18n-js';

import logo from 'assets/breakfast-logo.svg';
import mainLogo from 'assets/Img-Welcome.svg';
import { MenuDetailsData } from 'store/menuDetailsStore';
import { MenuType } from 'constants/enums';
import DishCard from './DishCard';
import { MenuSections } from '../MenusPage';
import agent, { DishCreateModel } from 'api/agent';
import { OrderDetailsItem } from 'store/ordersStore';
import { actionCreators as globalActions } from 'store/globalStore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiAccordionSummary-root': {
                height: '37px !important',
                minHeight: 0,
                backgroundColor: theme.palette.grey['300'],
            },
            '& .MuiListItem-gutters': {
                padding: '0',
            },
            '& .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters': {
                padding: '0 16px',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                justifyContent: 'center',
            },
            '& .MuiAccordion-root.MuiPaper-elevation1': {
                filter: 'none',
            },
            overflowX: 'hidden',
        },
        table: {
            minWidth: 650,
        },
        detailsList: {
            width: 'calc(100% + 32px)',
            margin: '0 0 0 -32px',
            display: 'inline-flex',
            flexWrap: 'wrap',
        },
        dots: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        breakfastLogo: {
            marginLeft: '15px',
            marginRight: '15px',
        },
        menuLabel: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            color: theme.palette.common.black,
            marginRight: '10px',
            minWidth: '438px',
            height: '41px',
            background: theme.palette.secondary.light,
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: '5px',
        },
        menuPrice: {
            fontWeight: 900,
            fontSize: '16px',
            lineHeight: '19px',
            color: theme.palette.primary.main,
        },
        toolbarTitle: {
            flex: '1 1 90%',
        },
        nothingToSee: {
            width: '100%',
            height: '100%',
            textAlign: 'center',
            margin: '50px 0',
            '& h2': {
                color: theme.palette.secondary.dark,
            },
        },
        paper: {
            background: theme.palette.common.white,
            width: '100%',
        },
    })
);

export const SearchResultTable = (props: {
    menus: MenuDetailsData[];
    sections: MenuSections[];
    searchString: string;
    recalculate: any;
    addItem?: (
        id?: string | undefined,
        amount?: number | undefined,
        item?: OrderDetailsItem | undefined
    ) => void;
    isModal?: boolean;
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedDishId, setSelectedDishId] = useState('');

    function updateDish(model: DishCreateModel, id: string, menuId: string) {
        dispatch(globalActions.showSpiner());
        agent.Menus.EditDish(model, id)
            .then((response) => {
                props.recalculate(response);
            })
            .catch((e) => dispatch(globalActions.showToaster('error', e)))
            .finally(() => dispatch(globalActions.hideSpiner()));
    }
    function deleteDish(id: string) {
        agent.Menus.DeleteDish({ id: id })
            .then((response) => {
                props.recalculate(response, true);
            })
            .catch((error) => alert(error));
    }

    function getDishAmount(menu: MenuDetailsData) {
        let amount = 0;
        menu.sections.forEach((eachSection) => {
            amount += eachSection.dishes.length;
        });
        return amount;
    }

    return props.menus.length > 0 ? (
        <TableContainer component={Paper} className={classes.root}>
            {props.menus.map((menu) => (
                <>
                    <Toolbar>
                        <Typography
                            variant="h6"
                            id="tableTitle"
                            component="div"
                            className={classes.toolbarTitle}
                        >
                            {menu.name}
                            <img src={logo} className={classes.breakfastLogo} alt="logo" />
                        </Typography>
                        <h5>{`${getDishAmount(menu)} item${
                            getDishAmount(menu) !== 1 ? 's' : ''
                        }`}</h5>
                    </Toolbar>
                    <Paper className={classes.paper}>
                        {menu.sections.map((section) => (
                            <Accordion square expanded className={classes.root}>
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <Typography>{section.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.detailsList}>
                                    {section.dishes?.length > 0 &&
                                        section.dishes.map((dish) => (
                                            <DishCard
                                                dish={dish}
                                                sections={
                                                    props.sections.find(
                                                        (x) => x.menuId === menu.id
                                                    )!.sections
                                                }
                                                updateAction={updateDish}
                                                deleteAction={deleteDish}
                                                isDaily={menu.type === MenuType.Daily}
                                                searchString={props.searchString}
                                                isModal={props.isModal}
                                                isSelected={selectedDishId === dish.id}
                                                setSelected={setSelectedDishId}
                                            />
                                        ))}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Paper>
                </>
            ))}
        </TableContainer>
    ) : (
        <div className={classes.nothingToSee}>
            <img src={mainLogo} alt="logo" />
            <h2>{i18n.t('main.nothingToSee')}</h2>
            <h2>{i18n.t('main.tryAnotherWord')}</h2>
            <br />
            <br />
            <br />
        </div>
    );
};
