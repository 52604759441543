import React, { useRef, useState, useEffect } from 'react';

import clsx from 'clsx';
import i18n from 'i18n-js';
import { Form, Formik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import * as yup from 'yup';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { DialogActions } from '@material-ui/core';

import { Input, TimePickerModal } from 'components/shared';
import { HourData } from 'components/restaurantInformation/components';
import { getDayList } from 'helpers/datetimeHelper';
import { getInitialDates } from 'components/restaurantInformation/functions';
import ImageContainer, {
    ImageContainerRefProps,
} from 'components/shared/components/ImageContainer';

import { makeStyles } from '@material-ui/core';
import { theme } from 'config/theme';

const styles = makeStyles({
    daysContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dayButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 53,
        height: 35,
        fontWeight: 500,
        color: theme.palette.secondary.main,
        textTransform: 'uppercase',
        fontSize: 14,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 3,
        cursor: 'pointer',
        userSelect: 'none',
    },
    dayButtonSelected: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
});

interface AddDailyMenuSectionProps {
    isVisible: boolean;
    onClose: () => void;
    onAdd: (model: any) => void;
    menus: string[];
}

export interface DaysWithHours {
    sunday: HourData;
    monday: HourData;
    tuesday: HourData;
    wednesday: HourData;
    thursday: HourData;
    friday: HourData;
    saturday: HourData;
}

export default function AddDailyMenuSectionModal(props: AddDailyMenuSectionProps) {
    const classes = styles();
    const defaultHours = { from: '00.00', to: '00.00' };
    const daysWithHours = useRef({
        sunday: { ...defaultHours },
        monday: { ...defaultHours },
        tuesday: { ...defaultHours },
        wednesday: { ...defaultHours },
        thursday: { ...defaultHours },
        friday: { from: '10.00', to: '12.00' },
        saturday: { ...defaultHours },
    });
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [selectedHours, setSelectedHours] = useState(defaultHours);
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [clickedElementRect, setClickedElementRect] = useState(null);
    const imageContainerRef = useRef<ImageContainerRefProps>(null);
    function handleDaySelection(day: string) {
        if (selectedDays.includes(day)) {
            setSelectedDays(selectedDays.filter((x) => x !== day));
        } else {
            setSelectedDays([...selectedDays, day]);
        }
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !props.menus.includes(value?.toUpperCase())
            ),
        price: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .required(
                i18n.t('form.errors.required', {
                    name: 'Price',
                })
            )
            .min(0.1),
    });

    function handleTimePicker(e: any) {
        setClickedElementRect(e?.target.getBoundingClientRect());
        setShowTimePicker(true);
    }

    useEffect(() => {
        if (selectedDays.length === 1) {
            setSelectedHours(
                daysWithHours.current[selectedDays[0].toString() as keyof DaysWithHours]
            );
        } else {
            setSelectedHours(defaultHours);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDays]);

    return (
        <Dialog open={props.isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{i18n.t('restaurant.addNewMenu')}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        name: '',
                        price: 0.0,
                        photo: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        imageContainerRef?.current?.uploadImage().then((photo) => {
                            if (photo.length > 0) values.photo = photo;
                            props.onAdd({
                                name: values.name,
                                price: values.price,
                                photo: values.photo,
                                availability: { ...daysWithHours.current },
                            });
                        });
                        /* </DialogContent>/const result = await agent.Menus.AddDailyMenuSection(model); */
                    }}
                >
                    {({ errors, submitForm, values }) => (
                        <Form>
                            <ImageContainer ref={imageContainerRef} photo={values.photo} />
                            <>
                                <Input
                                    error={errors.name}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`name`}
                                    type="text"
                                    label={i18n.t('form.menuName')}
                                    placeholder={i18n.t('form.name')}
                                />
                                <br />
                                <Input
                                    error={errors.price}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`price`}
                                    type="text"
                                    label={i18n.t('form.price')}
                                    placeholder={i18n.t('form.price')}
                                />
                            </>
                            <div className={classes.daysContainer}>
                                {getDayList(daysWithHours.current).map((day) => (
                                    <div
                                        key={day.name}
                                        className={clsx(classes.dayButton, {
                                            [classes.dayButtonSelected]: selectedDays.includes(
                                                day.key
                                            ),
                                        })}
                                        onClick={() => handleDaySelection(day.key)}
                                    >
                                        {day.name}
                                    </div>
                                ))}
                            </div>
                            <br />
                            <Input
                                variant="filled"
                                fullWidth={true}
                                name={`hours`}
                                type="text"
                                label={i18n.t('restaurant.menuHours')}
                                placeholder={i18n.t('restaurant.menuHours')}
                                disabled
                                onClick={(e: any) => selectedDays.length && handleTimePicker(e)}
                                value={selectedHours.from + ' - ' + selectedHours.to}
                            />
                            <DialogActions>
                                <Button type="button" color="primary" onClick={props.onClose}>
                                    {i18n.t('button.cancel')}
                                </Button>
                                <Button onClick={submitForm} variant="contained" color="primary">
                                    {i18n.t('common.add')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
                <TimePickerModal
                    initialFromHour={getInitialDates(selectedHours, 'from', 'hour')}
                    initialFromMinute={getInitialDates(selectedHours, 'from', 'minute')}
                    initialToHour={getInitialDates(selectedHours, 'to', 'hour')}
                    initialToMinute={getInitialDates(selectedHours, 'to', 'minute')}
                    clickedElementRect={clickedElementRect}
                    isVisible={showTimePicker}
                    onClose={(selectedHours) => {
                        selectedDays.length &&
                            selectedDays.forEach((day) => {
                                daysWithHours.current[day.toString() as keyof DaysWithHours] =
                                    selectedHours;
                            });
                        setShowTimePicker(false);
                    }}
                    onDateChange={(selectedHours) => {
                        setSelectedHours(selectedHours);
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}
