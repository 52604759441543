import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    ListItemIcon,
    Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';
import i18n from 'i18n-js';

import { ApplicationState } from 'store';
import { actionCreators as ingredientActions } from 'store/ingredientsStore';
import { Roles } from 'constants/enums';
import { truncateString } from '../../../helpers/helperFunctions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        allergensSelect: {
            '& .MuiSelect-root.MuiSelect-select': {
                display: 'flex',
                flexWrap: 'wrap',
            },
        },
        /* allergensSelectSelected: {
            '& .MuiSelect-root.MuiSelect-select': {
                paddingBottom: 5,
            },
        }, */
        allergensSelectValue: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginRight: 5,
            '& > img': {
                marginLeft: 5,
            },
        },
        options: {
            height: 300,
            marginTop: -70,
        },
        selectedAllergen: {
            marginRight: 5,
            '&:last-child > span': {
                display: 'none',
            },
        },
    })
);

export interface AllergensSelectProps {
    onChange: (values: string[]) => void;
    values: string[];
    styles?: {};
}

//TODO: fix type script warnings, remove ignore tags
export default function AllergensSelect(props: AllergensSelectProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const allergies = useSelector((state: ApplicationState) => state.ingredients.allergies);
    const language = useSelector((state: ApplicationState) => state.global.language);
    const user = useSelector((state: ApplicationState) => state.user);

    useEffect(() => {
        if (allergies.length === 0) {
            dispatch(ingredientActions.getAllergies(language));
        }
    }, []); // eslint-disable-line

    function getRenderedValue() {
        let values = '';
        allergies
            .filter((x) => props.values.includes(x.id))
            .forEach((s) => (values += s!.name + ' /'));
        return values;
    }

    return (
        <FormControl fullWidth variant="filled" style={{ ...props.styles }}>
            <InputLabel>{i18n.t('common.allergens')}</InputLabel>
            <Select
                className={clsx(classes.allergensSelect, {
                    //[classes.allergensSelectSelected]: props.values.length > 3,
                })}
                multiple
                disabled={user.role !== Roles.OWNER}
                value={props.values}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    transformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    anchorPosition: {
                        top: 0,
                        left: 0,
                    },
                    classes: {
                        paper: classes.options,
                        list: classes.options,
                    },
                    getContentAnchorEl: null,
                }}
                renderValue={() => (
                    <Tooltip title={getRenderedValue()}>
                        <span className={classes.allergensSelect}>
                            {truncateString(getRenderedValue(), 40)}
                        </span>
                    </Tooltip>
                )}
                onChange={(e) => {
                    props.onChange([
                        //@ts-ignore
                        ...e.target.value,
                    ]);
                }}
            >
                {allergies.map((a) => (
                    <MenuItem value={a.id} key={a.id}>
                        <Checkbox
                            checked={props.values.includes(
                                //@ts-ignore
                                a.id
                            )}
                        />
                        <ListItemText primary={a.name} />
                        <ListItemIcon>
                            <img src={a.icon} alt={a.name} />
                        </ListItemIcon>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
