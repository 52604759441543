import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import i18n from 'i18n-js';
import _ from 'lodash';

import agent, {
    EmptyOrderModel,
    OrderItemStatusChangeModel,
    OrderUpdateModel,
    PositionOrderItemStatusChangeModel,
} from 'api/agent';
import { OrderDishStatus, OrderStatus, PaymentMethod, OrderServices } from 'constants/enums';
import { isFullPageCount } from 'helpers/helperFunctions';
import { actionCreators as globalActions, GlobalAction } from './globalStore';

export interface OrderData {
    id: string;
    orderNumber: number;
    createDate: string;
    status: OrderStatus;
}

export interface OrderDetails {
    id: string;
    orderNumber: number;
    createDate: number;
    orderItems: OrderItem[];
    status: OrderStatus;
    isPaid: boolean;
    totalAmount: number;
    firstName: string;
    lastName: string;
    addressLine: string;
    city: string;
    zip: string;
    phoneNumber: string;
    email: string;
    comment: string;
    deliveryTime: number;
    service: OrderServices;
    paymentMethod: PaymentMethod;
    qrCodeName: string;
    restaurantId?: string;
}

export interface OrderItem {
    price: number;
    name: string;
    id: string;
    amount: number;
    shortDescription: string;
    orderId?: string;
    isReady?: boolean;
    orderNumber?: number;
    dishes: any[];
    comment?: string;
    service?: OrderServices;
    ingredients?: any[];
    status: OrderDishStatus;
    categoryId: string;
    categoryName: string;
    dailyMenuId: string;
    dailyMenuName?: string;
    dailyMenuPrice?: number;
    qrCodeName?: string;
    modifiedByManagement?: number;
    uniqueId?: string;
    allergens?: any;
    isVegan?: boolean;
    isVegeterian?: boolean;
    isSpicy?: boolean;
    isHalal?: boolean;
    modified?: boolean;
    modifiedIngredients?: ModifiedIngredient[];
}

export interface OrderDetailsItem extends OrderItem {
    dishes: OrderItem[];
}

export interface ModifiedIngredient {
    id: string;
    isAdditionChecked?: boolean;
    isRemovalChecked?: boolean;
}

export interface OverdudedOrder {
    id: string;
    orderNumber: number;
    orderCreationDate: number;
    status: OrderStatus;
}

export interface OrderReadyDishes {
    [key: string]: string[];
}

//STATE
export interface OrdersState {
    orders: OrderDetails[];
    positionOrders: OrderItem[];
    orderDetailsId: string;
    orderDetails: OrderDetails;
    searchString: string;
    newOrders: number;
    count: number;
    positionOrdersLoaded: boolean;
    overdudedOrders: OverdudedOrder[];
    showSearch: boolean;
    needToUpdate: boolean;
    removedModifiedOrders: string[];
    initialOrderItems: OrderItem[];
    selectedDishId: string;
    selectedDailyMenuId?: string;
}

//ACTIONS
interface GetOrdersListStart {
    type: 'GET_ORDERS_LIST_START';
}

interface GetOrdersListSuccess {
    type: 'GET_ORDERS_LIST_SUCCESS';
    list: OrderDetails[];
    count: number;
    overduded: boolean;
}

interface GetOrdersListError {
    type: 'GET_ORDERS_LIST_ERROR';
}

interface UpdateOrdersListStart {
    type: 'UPDATE_ORDERS_LIST_START';
}

interface UpdateOrdersListSuccess {
    type: 'UPDATE_ORDERS_LIST_SUCCESS';
    list: OrderDetails[];
    count: number;
}

interface UpdateOrdersListError {
    type: 'UPDATE_ORDERS_LIST_ERROR';
}

interface SetOrderDetailsId {
    type: 'SET_ORDER_DETAILS_ID';
    id: string;
}

interface SetOrderDetails {
    type: 'SET_ORDER_DETAILS';
    order: OrderDetails;
}

interface ChangeOrderItemStatusStart {
    type: 'CHANGE_ORDER_ITEM_STATUS_START';
}

interface ChangeOrderItemStatusSuccess {
    type: 'CHANGE_ORDER_ITEM_STATUS_SUCCESS';
    order: OrderDetails;
    itemId: string;
    dailyMenuId?: string;
}

interface ChangeOrderItemStatusError {
    type: 'CHANGE_ORDER_ITEM_STATUS_ERROR';
}

interface UpdateOrderStatus {
    type: 'UPDATE_ORDER_STATUS';
    id: string;
    status: OrderStatus;
}

interface SetSearchString {
    type: 'SET_SEARCH_STRING';
    data: string;
}

interface GetNewOrdersStart {
    type: 'GET_NEW_ORDERS_START';
}

interface GetNewOrdersSuccess {
    type: 'GET_NEW_ORDERS_SUCCESS';
    newOrders: number;
}

interface GetNewOrdersError {
    type: 'GET_NEW_ORDERS_ERROR';
}

interface GetPositionOrdersStart {
    type: 'GET_POSITION_ORDERS_START';
}

interface GetPositionOrdersSuccess {
    type: 'GET_POSITION_ORDERS_SUCCESS';
    data: OrderItem[];
}

interface GetPositionOrdersError {
    type: 'GET_POSITION_ORDERS_ERROR';
}

interface CreateEmptyOrderStart {
    type: 'CREATE_EMPTY_ORDER_START';
}

interface CreateEmptyOrderSuccess {
    type: 'CREATE_EMPTY_ORDER_SUCCESS';
    data: OrderDetails;
}

interface CreateEmptyOrderError {
    type: 'CREATE_EMPTY_ORDER_ERROR';
}

interface ChangePositionOrderStatusStart {
    type: 'CHANGE_POSITION_ORDER_STATUS_START';
}

interface ChangePositionOrderStatusSuccess {
    type: 'CHANGE_POSITION_ORDER_STATUS_SUCCESS';
}

interface ChangePositionOrderStatusError {
    type: 'CHANGE_POSITION_ORDER_STATUS_ERROR';
    orderId: string;
    previousStatus: number;
}

interface AddItemsToOrder {
    type: 'ADD_ITEMS_TO_ORDER';
    items: OrderItem[];
}

interface UpdateOrderStart {
    type: 'UPDATE_ORDER_START';
}

interface UpdateOrderSuccess {
    type: 'UPDATE_ORDER_SUCCESS';
    order: OrderDetails;
}

interface UpdateOrderError {
    type: 'UPDATE_ORDER_ERROR';
}

interface GetOrderDetailsStart {
    type: 'GET_ORDER_DETAILS_START';
}

interface GetOrderDetailsSuccess {
    type: 'GET_ORDER_DETAILS_SUCCESS';
    order: OrderDetails;
}

interface GetOrderDetailsError {
    type: 'GET_ORDER_DETAILS_ERROR';
}

interface ResetOrderDetails {
    type: 'RESET_ORDER_DETAILS';
}

interface SetOverdudedOrders {
    type: 'SET_OVERDUDED_ORDERS';
    orders: OverdudedOrder[];
}

interface SetShowSearch {
    type: 'SET_SHOW_SEARCH';
    toggle: boolean;
    showSearch: boolean;
}

interface GetDetailedOverdudedOrders {
    type: 'GET_DETAILED_OVERDUDED_ORDERS';
    orders: OverdudedOrder[];
}

interface UpdateReadyOrdersDishes {
    type: 'UPDATE_READY_ORDERS_DISHES';
    data: OrderReadyDishes;
}

interface SetSelectedDish {
    type: 'SET_SELECTED_DISH';
    data: string;
    selectedDailyMenuId?: string;
}

interface AddItemToOrder {
    type: 'ADD_ITEM_TO_ORDER';
    item: OrderItem;
    amount: number;
    action?: () => void;
}

//ACTION TYPES
export type OrdersAction =
    | GlobalAction
    | GetOrdersListStart
    | GetOrdersListSuccess
    | GetOrdersListError
    | SetOrderDetailsId
    | ChangeOrderItemStatusStart
    | ChangeOrderItemStatusSuccess
    | ChangeOrderItemStatusError
    | UpdateOrderStatus
    | SetSearchString
    | GetNewOrdersStart
    | GetNewOrdersSuccess
    | GetNewOrdersError
    | GetPositionOrdersStart
    | GetPositionOrdersSuccess
    | GetPositionOrdersError
    | CreateEmptyOrderStart
    | CreateEmptyOrderSuccess
    | CreateEmptyOrderError
    | ChangePositionOrderStatusStart
    | ChangePositionOrderStatusSuccess
    | ChangePositionOrderStatusError
    | AddItemsToOrder
    | UpdateOrderStart
    | UpdateOrderSuccess
    | UpdateOrderError
    | GetOrderDetailsStart
    | GetOrderDetailsSuccess
    | GetOrderDetailsError
    | UpdateOrdersListStart
    | UpdateOrdersListSuccess
    | UpdateOrdersListError
    | SetOrderDetails
    | ResetOrderDetails
    | SetOverdudedOrders
    | SetShowSearch
    | GetDetailedOverdudedOrders
    | UpdateReadyOrdersDishes
    | SetSelectedDish
    | AddItemToOrder;

//ACTION CREATORS
export const actionCreators = {
    getOrdersList:
        (
            searchString: string = '',
            dateFrom: string | undefined = undefined,
            dateTo: string | undefined = undefined,
            page: number = 0,
            limit: number = 13,
            asc: boolean = false,
            orderBy: string = ''
        ): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_ORDERS_LIST_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Order.GetOrdersList(searchString, dateFrom, dateTo, page, limit, asc, orderBy)
                .then((res) => {
                    dispatch({
                        type: 'GET_ORDERS_LIST_SUCCESS',
                        list: res.list,
                        count: res.count,
                        overduded: false,
                    });
                    dispatch({
                        type: 'SET_ORDER_DETAILS_ID',
                        id: res.list[0]?.id,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_ORDERS_LIST_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    updateOrdersList:
        (
            searchString: string = '',
            dateFrom: string | undefined = undefined,
            dateTo: string | undefined = undefined,
            page: number = 0,
            limit: number = 13,
            asc: boolean = false,
            orderBy: string = ''
        ): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_ORDERS_LIST_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Order.GetOrdersList(searchString, dateFrom, dateTo, page, limit, asc, orderBy)
                .then((res) => {
                    dispatch({
                        type: 'UPDATE_ORDERS_LIST_SUCCESS',
                        list: res.list,
                        count: res.count,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'UPDATE_ORDERS_LIST_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },

    setOrderDetailsId:
        (id: string): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_ORDER_DETAILS_ID',
                id: id,
            });
        },

    changeOrderItemStatus:
        (model: OrderItemStatusChangeModel): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'CHANGE_ORDER_ITEM_STATUS_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Order.ChangeOrderItemStatus(model)
                .then((res) => {
                    dispatch({
                        type: 'CHANGE_ORDER_ITEM_STATUS_SUCCESS',
                        order: res,
                        itemId: model.itemId,
                        dailyMenuId: model.dailyMenuId,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'CHANGE_ORDER_ITEM_STATUS_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },

    updateOrderStatus:
        (id: string, status: OrderStatus): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_ORDER_STATUS',
                id: id,
                status: status,
            });
        },

    setSearchString:
        (data: string): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_SEARCH_STRING',
                data: data,
            });
        },

    getNewOrders:
        (position: string): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_NEW_ORDERS_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Order.GetNewOrdersCount(position)
                .then((res) => {
                    dispatch({
                        type: 'GET_NEW_ORDERS_SUCCESS',
                        newOrders: res,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_NEW_ORDERS_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    getPositionOrders:
        (position: string): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_POSITION_ORDERS_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Order.GetPositionOrders(position)
                .then((res) => {
                    dispatch({
                        type: 'GET_POSITION_ORDERS_SUCCESS',
                        data: res,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_POSITION_ORDERS_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    createEmptyOrder:
        (model: EmptyOrderModel): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'CREATE_EMPTY_ORDER_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Order.CreateEmptyOrder(model)
                .then((res) => {
                    dispatch({
                        type: 'CREATE_EMPTY_ORDER_SUCCESS',
                        data: res,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'CREATE_EMPTY_ORDER_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    changePositionOrderStatus:
        (model: PositionOrderItemStatusChangeModel): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'CHANGE_POSITION_ORDER_STATUS_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Order.ChangeOrderItemStatus(model)
                .then(() => {
                    dispatch({
                        type: 'CHANGE_POSITION_ORDER_STATUS_SUCCESS',
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'CHANGE_POSITION_ORDER_STATUS_ERROR',
                        orderId: model.orderId,
                        previousStatus: model.previousStatus,
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    updateOrder:
        (id: string, model: OrderUpdateModel): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_ORDER_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Order.UpdateOrder(id, model)
                .then((res) => {
                    dispatch({
                        type: 'UPDATE_ORDER_SUCCESS',
                        order: res,
                    });
                    globalActions.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                    return res;
                })
                .catch((e) => {
                    dispatch({
                        type: 'UPDATE_ORDER_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    getOrderDetails:
        (
            id: string,
            setState?: (order: OrderDetails) => void,
            setInitialState?: (order: OrderDetails) => void
        ): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_ORDER_DETAILS_START',
            });
            globalActions.showSpiner()(dispatch);
            agent.Order.GetOrder(id)
                .then((res) => {
                    dispatch({
                        type: 'GET_ORDER_DETAILS_SUCCESS',
                        order: res,
                    });
                    setState && setState({ ...res, orderItems: _.cloneDeep(res.orderItems) });
                    setInitialState &&
                        setInitialState({ ...res, orderItems: _.cloneDeep(res.orderItems) });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_ORDER_DETAILS_ERROR',
                    });
                    globalActions.showToaster('error', e)(dispatch);
                })
                .finally(() => globalActions.hideSpiner()(dispatch));
        },
    setOrderDetails:
        (order: OrderDetails): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_ORDER_DETAILS',
                order: order,
            });
        },
    resetOrderDetails: (): AppThunkAction<OrdersAction> => (dispatch) => {
        dispatch({
            type: 'RESET_ORDER_DETAILS',
        });
    },
    getOverdudedOrders: (): AppThunkAction<OrdersAction> => (dispatch) => {
        agent.Order.GetOverdudedOrders().then((result) => {
            dispatch({
                type: 'SET_OVERDUDED_ORDERS',
                orders: result,
            });
        });
    },
    setOverdudedOrders:
        (orders: OverdudedOrder[]): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_OVERDUDED_ORDERS',
                orders: orders,
            });
        },
    setShowSearch:
        (showSearch: boolean, toggle: boolean = false): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_SHOW_SEARCH',
                showSearch: showSearch,
                toggle: toggle,
            });
        },
    prepareGettingDetailedOverdudedOrders:
        (orders: OverdudedOrder[]): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_SEARCH_STRING',
                data: orders.map((x) => x.orderNumber).join(', '),
            });
            dispatch({
                type: 'SET_SHOW_SEARCH',
                showSearch: true,
                toggle: false,
            });
        },
    getDetailedOverdudedOrders:
        (ordersNumbers: OverdudedOrder[]): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_ORDERS_LIST_START',
            });
            agent.Order.GetOverdudedOrdersDetails(ordersNumbers.map((x) => x.orderNumber)).then(
                (result) => {
                    dispatch({
                        type: 'GET_ORDERS_LIST_SUCCESS',
                        list: result.list,
                        count: result.count,
                        overduded: true,
                    });
                }
            );
        },
    updateOrdersReadyDishes:
        (orders: OrderReadyDishes): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_READY_ORDERS_DISHES',
                data: orders,
            });
        },
    setSelectedDish:
        (
            dishId: string,
            selectedDailyMenuId: string | undefined = undefined
        ): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_SELECTED_DISH',
                data: dishId,
                selectedDailyMenuId,
            });
        },
    addItemToOrder:
        (item: OrderItem, amount = 1, action?: () => void): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'ADD_ITEM_TO_ORDER',
                item,
                amount,
                action,
            });
        },
};

//REDUCER
const initialState: OrdersState = {
    orders: [],
    positionOrders: [],
    count: 0,
    newOrders: 0,
    orderDetailsId: '',
    orderDetails: {
        id: '',
        orderNumber: 0,
        createDate: 0,
        orderItems: [],
        status: OrderStatus.Non,
        isPaid: false,
        totalAmount: 0,
        firstName: '',
        lastName: '',
        addressLine: '',
        city: '',
        zip: '',
        phoneNumber: '',
        email: '',
        comment: '',
        deliveryTime: 0,
        service: OrderServices.OrderInRestaurant,
        paymentMethod: PaymentMethod.None,
        qrCodeName: '',
    },
    searchString: '',
    positionOrdersLoaded: false,
    overdudedOrders: [],
    showSearch: false,
    needToUpdate: false,
    removedModifiedOrders: [],
    initialOrderItems: [],
    selectedDishId: '',
    selectedDailyMenuId: undefined,
};

export const reducer: Reducer<OrdersState> = (
    state: OrdersState | undefined = initialState,
    incomingAction: Action
): OrdersState => {
    const action = incomingAction as OrdersAction;
    switch (action.type) {
        case 'GET_ORDERS_LIST_SUCCESS': {
            return {
                ...state,
                orders: action.list,
                count: action.count,
                orderDetailsId: action.overduded
                    ? action.list[0]?.id
                    : !state.orderDetailsId
                    ? action.list[0]?.id
                    : state.orderDetailsId,
            };
        }
        case 'UPDATE_ORDERS_LIST_SUCCESS': {
            let updatedOrders = [...state.orders];
            const previousOrdersIds = updatedOrders.map((x) => x.id);
            const newOrders = action.list.filter((x) => !previousOrdersIds.includes(x.id));
            if (newOrders.length === updatedOrders.length) {
                updatedOrders = [...newOrders];
            } else {
                updatedOrders.splice(updatedOrders.length - newOrders.length - 1, newOrders.length);
                updatedOrders.splice(0, 0, ...newOrders);
            }
            return {
                ...state,
                orders: action.list,
                count: action.count,
                orderDetailsId: !!!state.orderDetailsId ? action.list[0]?.id : state.orderDetailsId,
            };
        }
        case 'SET_ORDER_DETAILS_ID': {
            return {
                ...state,
                orderDetailsId: action.id,
            };
        }
        case 'CHANGE_ORDER_ITEM_STATUS_SUCCESS': {
            let orderToUpdate = { ...state.orders.find((x) => x.id === action.order.id)! };
            orderToUpdate.status = action.order.status;
            let itemToUpdate;
            if (action.dailyMenuId) {
                itemToUpdate = orderToUpdate.orderItems
                    .find((x) => x.id === action.dailyMenuId)!
                    .dishes.find((y) => y.id === action.itemId)!;
            } else {
                itemToUpdate = orderToUpdate.orderItems.find((x) => x.id === action.itemId)!;
            }

            itemToUpdate.status =
                itemToUpdate.status === OrderDishStatus.None
                    ? OrderDishStatus.Ready
                    : OrderDishStatus.None;
            return {
                ...state,
                orders: state.orders.map((x) => (x.id === orderToUpdate.id ? orderToUpdate : x)),
            };
        }
        case 'UPDATE_ORDER_STATUS': {
            return {
                ...state,
                orders: state.orders.map((x) =>
                    x.id !== action.id ? x : { ...x, status: action.status }
                ),
            };
        }
        case 'SET_SEARCH_STRING': {
            return {
                ...state,
                searchString: action.data,
            };
        }
        case 'GET_NEW_ORDERS_SUCCESS': {
            return {
                ...state,
                newOrders: action.newOrders,
            };
        }
        case 'GET_POSITION_ORDERS_SUCCESS': {
            return {
                ...state,
                positionOrders: action.data,
                positionOrdersLoaded: true,
            };
        }
        case 'GET_POSITION_ORDERS_ERROR': {
            return {
                ...state,
                positionOrdersLoaded: true,
            };
        }
        case 'CHANGE_POSITION_ORDER_STATUS_ERROR': {
            const indexOfItem = state?.positionOrders.findIndex(
                (each) => each.orderId === action.orderId
            );
            if (indexOfItem > -1) {
                const newPositionOrders: any = [...state?.positionOrders];
                newPositionOrders[indexOfItem].status = action.previousStatus;
                return {
                    ...state,
                    positionOrders: newPositionOrders,
                };
            }
            return {
                ...state,
            };
        }
        case 'CREATE_EMPTY_ORDER_START': {
            return {
                ...state,
            };
        }
        case 'CREATE_EMPTY_ORDER_SUCCESS': {
            let newOrders = [...state.orders];
            if (isFullPageCount(newOrders.length)) {
                newOrders.splice(newOrders.length - 1, 1);
                newOrders.splice(0, 0, action.data);
            } else {
                newOrders.push(action.data);
            }

            return {
                ...state,
                orders: [...newOrders],
                orderDetailsId: action.data.id,
            };
        }
        case 'UPDATE_ORDER_SUCCESS': {
            return {
                ...state,
                orders: [...state.orders.map((x) => (x.id === action.order.id ? action.order : x))],
                orderDetails: action.order,
                initialOrderItems: [...action.order.orderItems],
            };
        }
        case 'GET_ORDER_DETAILS_SUCCESS': {
            return {
                ...state,
                orders: [...state.orders.map((x) => (x.id === action.order.id ? action.order : x))],
                orderDetails: action.order,
                initialOrderItems: [...action.order.orderItems],
                needToUpdate: false,
            };
        }
        case 'SET_ORDER_DETAILS': {
            return {
                ...state,
                orderDetails: action.order,
                initialOrderItems: [...action.order.orderItems],
            };
        }
        case 'RESET_ORDER_DETAILS': {
            return {
                ...state,
                orderDetails: state.orders.find((x) => x.id === state.orderDetailsId)!,
                removedModifiedOrders: [],
            };
        }
        case 'SET_OVERDUDED_ORDERS': {
            return {
                ...state,
                overdudedOrders: action.orders,
            };
        }
        case 'SET_SHOW_SEARCH': {
            return {
                ...state,
                showSearch: action.toggle ? !state.showSearch : action.showSearch,
            };
        }
        case 'UPDATE_READY_ORDERS_DISHES': {
            return {
                ...state,
                orders: state.orders.map((o) => ({
                    ...o,
                    orderItems: o.orderItems.map((i) =>
                        action.data[o.id]?.includes(i.id)
                            ? { ...i, status: OrderDishStatus.Ready }
                            : i
                    ),
                    status:
                        o.orderItems.length === 0 ||
                        o.orderItems.filter((x) => x.status !== OrderDishStatus.Ready)?.length >
                            0 ||
                        o.status === OrderStatus.Closed
                            ? o.status
                            : OrderStatus.Ready,
                })),
                orderDetails: {
                    ...state.orderDetails,
                    orderItems: state.orderDetails.orderItems.map((i) =>
                        action.data[state.orderDetails.id]?.includes(i.id)
                            ? { ...i, status: OrderDishStatus.Ready }
                            : i
                    ),
                },
                needToUpdate: !!action.data[state.orderDetails.id],
            };
        }
        case 'SET_SELECTED_DISH': {
            return {
                ...state,
                selectedDishId: action.data,
                selectedDailyMenuId: action.selectedDailyMenuId,
            };
        }
        case 'ADD_ITEM_TO_ORDER': {
            const { item, amount } = action;
            const orderDetails = state?.orderDetails;
            // Add +1
            if (amount === 1) {
                if (item.uniqueId) {
                    const targetItem = orderDetails?.orderItems.find(
                        (each) => each.uniqueId === item.uniqueId
                    );
                    if (targetItem) {
                        targetItem.amount++;
                    }
                } else {
                    const targetItem = orderDetails?.orderItems.find((each) => each.id === item.id);
                    if (targetItem) {
                        targetItem.amount++;
                    } else {
                        orderDetails?.orderItems.push(item);
                    }
                }
            }
            // Remove it totally
            else if (amount === 0) {
                if (item.uniqueId) {
                    orderDetails?.orderItems.filter((each) => each.uniqueId === item.uniqueId);
                } else {
                    orderDetails?.orderItems.filter((each) => each.id === item.id);
                }
            }
            // Subtract -1
            else {
                let indexOfItem = orderDetails?.orderItems.findIndex((each) =>
                    item.uniqueId ? each.uniqueId === item.uniqueId : each.id === item.id
                );
                if (indexOfItem > -1) {
                    const targetItem = orderDetails?.orderItems[indexOfItem];
                    if (targetItem.amount > 1) {
                        targetItem.amount--;
                    } else {
                        orderDetails?.orderItems.splice(indexOfItem, 1);
                    }
                }
            }
            if (action.action) {
                action.action();
            }

            return {
                ...state,
                orderDetails,
            };
        }
        default:
            return state;
    }
};
