import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core';
import commonStyles from 'config/commonStyles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        ordersPage: {
            display: 'flex',
            height: '100%',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%,0)',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: 200,
            }),
        },
        root: {
            flexGrow: 1,
            marginLeft: 8,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            backgroundColor: 'transparent',
            //maxWidth: 784,
        },
        orderDetailsHeader: {
            height: 76,
            backgroundColor: theme.palette.common.white,
            padding: '0 24px',
        },
        orderDetailsHeadingText: {
            fontSize: 10,
            letterSpacing: '1.5px',
            textTransform: 'uppercase',
            color: alpha(theme.palette.common.black, 0.87),
        },
        orderDetailsGroupText: {
            fontSize: 14,
            letterSpacing: '1.5px',
            textTransform: 'uppercase',
            color: alpha(theme.palette.common.black, 0.87),
            fontWeight: 700,
            margin: '10px 0 15px 0',
            display: 'flex',
            alignItems: 'center',
        },
        orderDetailsHeadingBackground: {
            width: 100,
            height: 20,
            borderRadius: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            color: theme.palette.common.white,
            paddingBottom: 2,
        },
        orderNumber: {
            fontWeight: 500,
            fontSize: '12px',
            textAlign: 'center',
            width: '100%',
            //lineHeight: '30px',
        },
        orderQrCodeName: {
            fontSize: '13px',
            fontWeight: 700,
            color: theme.palette.common.black,
        },
        orderHeaderItem: {
            padding: '9px 15px ',
            backgroundColor: alpha(theme.palette.secondary.light, 0.3),
            height: 34,
            borderRadius: 7,
            margin: '5px 11px 5px 0',
            alignItems: 'flex-start',
            border: '1px solid transparent',
            cursor: 'pointer',
            '&>div:first-child': {
                marginBottom: 3,
            },
            '&:nth-last-child(-n+2):hover, &:nth-last-child(-n+2):active': {
                border: '1px solid #99567E',
            },
        },
        orderHeaderItemText: {
            '& span:first-child': {
                fontWeight: 'bold',
                fontSize: 16,
                lineHeight: '140.2%',
                marginRight: 5,
            },
        },
        orderHeaderItemActive: {
            border: '1px solid #99567E !important',
            backgroundColor: `${alpha(theme.palette.secondary.light, 0.3)} !important`,
        },
        orderItemsDetailsContainer: {
            ...commonStyles.flexRowSpaceBetween,
            backgroundColor: theme.palette.common.white,
            marginTop: 8,
            flexGrow: 1,
        },
        orderItemsDetails: {
            //width: 441,
            padding: '0 24px 13px 24px',
            height: '98.2%',
            display: 'flex',
            flexDirection: 'column',
            borderRight: `1px solid ${theme.palette.secondary.light}`,
            flexGrow: 1,
        },
        orderItemsDetailsHeader: {
            height: 60,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        orderItemsContainer: {
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
            height: 640,
        },
        orderItemsFooter: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            padding: 2,
            marginTop: 10,
        },
        orderItemsFooterCard: {
            ...commonStyles.flexRowSpaceBetween,
            width: 'calc(100% - 28px);',
            padding: '0 14px',
            height: 52,
            marginTop: 16,
            filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.11));',
        },
        orderItemsFooterCardPrice: {
            fontSize: 16,
            color: theme.palette.primary.main,
            '& span': {
                fontSize: 22,
                fontWeight: 'bold',
            },
        },
        orderItemCardTitle: {
            fontWeight: '700',
            fontSize: 16,
            lineHeight: '140.2%',
            color: theme.palette.common.black,
        },
        orderItemCard: {
            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.11)',
            padding: '14px 14px 8px 5px',
            marginBottom: 5,
            minHeight: 80,
            '& .OrderItemCardDescription': {
                fontSize: 14,
                lineHeight: '150%',
                letterSpacing: '0.25',
            },
            '& .OrderItemCardPrice': {
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                fontSize: 14,
                color: theme.palette.primary.main,
                minWidth: 90,
                marginLeft: 10,
            },
            '&.Disabled, &.Disabled .OrderItemCardTitle, &.Disabled .OrderItemCardDescription, &.Disabled .OrderItemCardPrice, &.Disabled .MuiCheckbox-root':
                {
                    color: `${theme.palette.grey[400]} !important`,
                    pointerEvents: 'none',
                },
        },
        orderItemCardDaily: {
            boxShadow: 'none',
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 80,
        },
        orderItemCardPrice: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            fontSize: 14,
            color: theme.palette.primary.main,
            minWidth: 90,
            marginLeft: 10,
        },
        disabledOrderItemAmount: {
            color: `${theme.palette.grey[400]} !important`,
            pointerEvents: 'none',
            cursor: 'unset',
        },
        disabledMenu: {
            color: `${theme.palette.grey[400]} !important`,
            pointerEvents: 'none',
        },
        orderStatus: {
            alignItems: 'center',
            backgroundColor: 'transparent',
            marginRight: 0,
            position: 'relative',
            cursor: 'pointer',
            border: '1px solid transparent',
            '&:hover, &:focus, &:active': {
                backgroundColor: alpha(theme.palette.secondary.light, 0.3),
            },
        },
        selected: {
            border: '1px solid #99567E',
            pointerEvents: 'none',
        },
        customerDataContainer: {
            width: 334,
            minWidth: 334,
            backgroundColor: alpha(theme.palette.secondary.light, 0.3),
            height: '100%',
            overflowY: 'auto',
        },
        customerData: {
            //height: '100%',
            margin: '20px 24px 13px 24px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        customerDataItem: {
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: 25,
            color: theme.palette.text.primary,
            fontSize: 14,
            lineHeight: '21px',
            '& div': {
                height: 18,
                width: 18,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                marginRight: 16,
            },
        },
        customerNote: {
            color: theme.palette.text.primary,
            fontSize: 14,
            lineHeight: '21px',
            width: '95%',
            overflowWrap: 'break-word',
            marginTop: 25,
            marginBottom: 26,
        },
        categoryFilterContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        categoryFilterItem: {
            fontWeight: 'bold',
            fontSize: 16,
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: 100,
            color: theme.palette.primary.main,
            display: 'inline-block',
            padding: '5px 20px',
            textAlign: 'center',
            minWidth: 83,
            cursor: 'pointer',
            margin: '0 12px 18px 0',
            userSelect: 'none',
            '&.selected': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
        },
        selectedFilterIcon: {
            '&::after': {
                content: '""',
                height: 7,
                width: 7,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 100,
                position: 'absolute',
                top: 2,
                left: 17,
            },
        },
        checkboxContainer: {
            marginLeft: 15,
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            width: '100%',
            position: 'relative',
        },
        itemPrice: {
            fontSize: 18,
            fontWeight: 'bold',
            marginLeft: 5,
        },
        blueTabContainer: {
            width: '100%',
            '& div': {
                width: 'calc(100% + 48px)',
                margin: '0 -24px 7px -24px;',
            },
        },
        buttonsContainer: {
            display: 'flex',
            '& .MuiButton-root': {
                padding: '6px 25px',
            },
        },
        orderItemAmountContainer: {
            display: 'block',
        },
        orderItemAmount: {
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: 20,
            display: 'flex',
            margin: '10px 0 0 20px',
            justifyContent: 'center',
            padding: '2px 0 4px 0',
            alignItems: 'center',
        },
        orderItemAmountNumber: {
            fontWeight: 900,
            fontSize: 14,
            margin: '0 12px',
            userSelect: 'none',
        },
        orderItemAmountControl: {
            fontWeight: 900,
            fontSize: 17,
            color: theme.palette.grey[300],
            cursor: 'pointer',
            userSelect: 'none',
            '&.Disabled': {
                pointerEvents: 'none',
                cursor: 'unset',
            },
        },
        oderActions: {
            ...commonStyles.flexRowSpaceBetween,
            width: 76,
        },
        orderEditButton: {
            cursor: 'pointer',
            '&.Disabled': {
                pointerEvents: 'none',
                cursor: 'unset',
                opacity: 0.5,
            },
            height: 18,
            width: 18,
        },
        removeOrderItem: {
            padding: 9,
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&.Disabled': {
                pointerEvents: 'none',
                cursor: 'unset',
                opacity: 0.5,
            },
        },
        highlighter: {
            backgroundColor: theme.palette.secondary.main,
        },
        filterDisabled: {
            pointerEvents: 'none',
            cursor: 'unset',
        },
        changeStatusDropdown: {
            zIndex: 2,
            position: 'absolute',
            width: '100%',
            top: '110%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: 10,
        },
        dateTime: {
            '&::-webkit-datetime-edit': {
                display: 'none',
            },
            '&::-webkit-calendar-picker-indicator': {
                position: 'absolute',
                cursor: 'pointer',
                top: 0,
                right: 0,
                padding: 0,
                height: 150,
                width: 150,
                background: 'transparent',
            },
        },
        dishAmount: {
            position: 'absolute',
            padding: '3px 8px',
            lineHeight: 1,
            right: 9,
            bottom: 6,
            background: 'linear-gradient(180deg, #FFD79A 9.9%, #FF9F9F 100%)',
            boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.05)',
            borderRadius: 5,
            fontWeight: 900,
            fontSize: 18,
            textAlign: 'center',
            color: theme.palette.primary.dark,
        },
        changeOfIngredientsContainer: {
            borderTop: `1px solid ${theme.palette.secondary.light}`,
            //width: '115%',
            //marginRight: '1%',
            marginRight: -14,
            marginLeft: -4,
            paddingRight: 10,
        },
        changeOfIngredientsChipContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '300px',
            flexWrap: 'wrap',
        },
        changeOfIngredientChip: {
            color: theme.palette.common.white,
            fontSize: '14px',
            fontWeight: '500',
            height: '21px',
            minWidth: '117px',
            maxWidth: '150px',
            borderRadius: '100px',
            padding: '0 10px 2px 10px',
            marginLeft: 10,
            marginTop: 10,
            display: 'flex',
            alignItems: 'center',
        },
        dishIngredientDialog: {
            paddingLeft: 0,
            paddingRight: 0,
            '& > div': {
                marginLeft: 0,
            },
            overflowY: 'auto',
        },
        servicesDropdown: {
            '& .MuiPaper-root.MuiMenu-paper': {
                width: 'auto',
                maxWidth: 'unset',
            },
            '& .MuiList-root': {
                width: 'unset',
            },
        },
    })
);
