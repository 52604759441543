import React, { useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import IconButton from '@material-ui/core/IconButton';
import { alpha, Checkbox, createStyles, makeStyles, Theme } from '@material-ui/core';
import { actionCreators } from 'store/ordersStore';

import { ApplicationState } from 'store';
import { images } from 'assets';
import { truncateString } from 'helpers/helperFunctions';
import commonStyles from 'config/commonStyles';
import { DishData } from 'store/menuDetailsStore';
import { IngredientModel } from 'api/agent';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        menuIngredientManagementContainer: {
            height: '100%',
            borderLeft: `1px solid ${theme.palette.secondary.light}`,
            marginLeft: -30,
            backgroundColor: alpha(theme.palette.secondary.light, 0.3),
            overflow: 'auto',
        },
        dishIngredientItemContainer: {
            paddingLeft: 20,
            paddingTop: 10,
        },
        dishIngredientItemHeader: {
            height: '30%',
            paddingBottom: 20,
        },
        chooseDishText: {
            fontSize: '16px',
            fontWeight: '700',
            color: theme.palette.secondary.main,
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
        },
        subTitle: {
            fontSize: '10px',
            fontWeight: '400',
        },
        ingredientText: {
            fontSize: '14px',
            fontWeight: '400',
            color: alpha(theme.palette.common.black, 0.6),
        },
        divider: {
            height: 1,
            backgroundColor: `${theme.palette.grey['300']}`,
            marginLeft: -20,
            marginBottom: 20,
        },
        dishTitle: { fontSize: '18px', fontWeight: '700', color: theme.palette.secondary.dark },
        dishDescription: {
            fontSize: '14px',
            fontWeight: '400',
            color: theme.palette.primary.dark,
        },
        priceText: {
            fontSize: '12px',
            color: theme.palette.primary.main,
        },
    })
);

const MenuIngredientManagement = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const orderDetails = useSelector((state: ApplicationState) => state.orders.orderDetails);
    const selectedDishId = useSelector((state: ApplicationState) => state.orders.selectedDishId);
    const selectedDailyMenuId = useSelector(
        (state: ApplicationState) => state.orders.selectedDailyMenuId
    );

    function getSelectedDish() {
        if (selectedDishId.length) {
            if (selectedDailyMenuId) {
                const dailyMenu = orderDetails.orderItems.find(
                    (each) => each.id === selectedDailyMenuId
                );
                if (dailyMenu) {
                    return dailyMenu.dishes;
                }
            }

            const item = orderDetails.orderItems.find((each) => each.id === selectedDishId);
            // Daily Menu
            if (item && item.dishes?.length) {
                return item.dishes;
            }
            const multipleItems = orderDetails.orderItems.filter(
                (each) => each.id === selectedDishId
            );
            // Multi selection of same item
            if (multipleItems.length > 1) {
                return multipleItems;
            }
            return item;
        }
        return orderDetails.orderItems[0];
    }

    function getDishData(id: string) {
        if (orderDetails.orderItems[0]?.dishes?.length > 0 && selectedDailyMenuId) {
            return orderDetails.orderItems[0].dishes.find((eachDish) => eachDish.id === id);
        }
        if (selectedDishId) {
            return orderDetails.orderItems.find((eachDish) => eachDish.id === selectedDishId);
        }
        return orderDetails.orderItems.find((eachDish) => eachDish.id === id);
    }

    function updateOrderDetails(newDish: any) {
        const { uniqueId } = newDish;
        let index;
        if (selectedDailyMenuId) {
            orderDetails.orderItems.forEach((eachOrderItem) => {
                index = eachOrderItem.dishes.findIndex((each) => each.id === newDish.id);
                if (index > -1) {
                    eachOrderItem.dishes[index] = {
                        ...newDish,
                        modifiedIngredients: [...newDish.modifiedIngredients],
                    };
                    return;
                }
            });
        } else {
            if (uniqueId) {
                index = orderDetails.orderItems.findIndex(
                    (each) => each.uniqueId === newDish.uniqueId
                );
            } else {
                index = orderDetails.orderItems.findIndex((each) => each.id === selectedDishId);
            }
            if (index > -1) {
                orderDetails.orderItems[index] = {
                    ...newDish,
                    modifiedIngredients: [...newDish.modifiedIngredients],
                };
                dispatch(actionCreators.setOrderDetails(orderDetails));
            }
        }
    }

    function renderDishIngredientItems() {
        const dish = getSelectedDish();
        if (dish && Array.isArray(dish)) {
            return dish.map((each, i) => (
                <DishIngredientItem
                    // @ts-ignore
                    defaultDish={each}
                    open={i === 0}
                    hidable
                    onValueChange={updateOrderDetails}
                    key={`${i}-${each.id}`}
                />
            ));
        }
        return (
            <DishIngredientItem
                // @ts-ignore
                defaultDish={getDishData(dish.id)}
                onValueChange={updateOrderDetails}
                key={dish?.id}
            />
        );
    }

    return (
        <div className={classes.menuIngredientManagementContainer}>
            {getSelectedDish() ? (
                renderDishIngredientItems()
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <span className={classes.chooseDishText}>
                        {i18n.t('restaurant.chooseDishToManage')}
                    </span>
                </div>
            )}
        </div>
    );
};

export default MenuIngredientManagement;

interface DishIngredientItemProps {
    defaultDish: DishData;
    hidable?: boolean;
    open?: boolean;
    onValueChange: (newDish: DishData) => void;
}

const DishIngredientItem = ({
    defaultDish,
    hidable = false,
    open = true,
    onValueChange,
}: DishIngredientItemProps) => {
    const classes = useStyles();
    const allergens = useSelector((state: ApplicationState) => state.ingredients.allergies);
    const defaultDishValues = useRef({ ...getDefaultDish() }).current;
    const [dish, setDish] = useState(getDefaultDish());
    const [isOpen, setIsOpen] = useState(open);

    function getDefaultDish() {
        const defaultIngredients: any = [];
        if (defaultIngredients && defaultDish?.ingredients) {
            defaultDish.ingredients.forEach((ingredient: IngredientModel) => {
                defaultIngredients.push({
                    ...ingredient,
                    isAdditionChecked: ingredient.isAdditionChecked ?? ingredient.isAddition,
                    isRemovalChecked: ingredient.isRemovalChecked ?? ingredient.isRemoval,
                });
            });
            defaultDish.ingredients = defaultIngredients;
        }

        return {
            ...defaultDish,
        };
    }

    function changeValue(key: string, id: string | undefined, value: boolean | undefined) {
        if (id) {
            const newDish = { ...dish };
            const index = newDish.ingredients.findIndex((each) => each.id === id);
            const modifiedIngredient = newDish.modifiedIngredients?.find((each) => each.id === id);
            if (modifiedIngredient) {
                newDish.modifiedIngredients = newDish.modifiedIngredients?.filter(
                    (each) => each.id !== id
                );
            } else {
                if (!newDish.modifiedIngredients) {
                    newDish.modifiedIngredients = [];
                }
                newDish.modifiedIngredients.push({
                    id,
                    [key]: !value,
                });
            }
            if (
                JSON.stringify(newDish.ingredients) !==
                JSON.stringify(defaultDishValues.ingredients)
            ) {
                newDish.modified = true;
                newDish.ingredients[index].modified = true;
            } else {
                delete newDish.modified;
                delete newDish.ingredients[index].modified;
            }
            onValueChange({ ...newDish });
            setDish({ ...newDish });
        }
    }

    function getAllergenIcons() {
        return dish.allergens?.map((id: string) => {
            const allergen = allergens.find((each) => each.id === id);
            return (
                <img
                    src={allergen?.icon}
                    alt={allergen?.name}
                    style={{ height: '24px', marginLeft: 7 }}
                />
            );
        });
    }

    function getFixedIngredients(defDish: any = null) {
        const dishToFilter = defDish ? defDish : dish;
        return dishToFilter.ingredients?.filter(
            (each: IngredientModel) => each.state === 0 && !each.isRemoval
        );
    }

    function getChangeOrDeleteIngredients(defDish: any = null) {
        const dishToFilter = defDish ? defDish : dish;
        return dishToFilter.ingredients?.filter(
            (each: IngredientModel) => each.state === 0 && each.isRemoval
        );
    }

    function getAdditionalIngredients(defDish: any = null) {
        const dishToFilter = defDish ? defDish : dish;
        return dishToFilter.ingredients?.filter((each: IngredientModel) => each.state === 1);
    }

    function getValue(id: string | undefined, key: string) {
        const modifiedIngredient = dish.modifiedIngredients?.find((each) => each.id === id);
        if (modifiedIngredient) {
            // @ts-ignore
            return modifiedIngredient[key];
        }
        // @ts-ignore
        return dish.ingredients.find((each) => each.id === id)[key];
    }

    return (
        <div className={classes.dishIngredientItemContainer}>
            <div className={classes.dishIngredientItemHeader}>
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className={`${classes.flexRowSpaceBetween} ${classes.mBot20}`}
                    style={{ cursor: hidable ? 'pointer' : 'auto' }}
                >
                    <div className={classes.flexRow}>
                        <span className={classes.dishTitle}>{truncateString(dish.name, 25)}</span>
                        <div style={{ marginLeft: 3 }}>
                            {dish.isSpicy && (
                                <IconButton
                                    className={classes.mLeft5}
                                    aria-label="add to favorites"
                                >
                                    <img src={images.icons.chili} alt="chili-paper" />
                                </IconButton>
                            )}
                            {dish.isVegetarian && (
                                <IconButton
                                    className={classes.mLeft5}
                                    aria-label="add to favorites"
                                >
                                    <img src={images.icons.leaf} alt="green-leaf" />
                                </IconButton>
                            )}
                            {dish.isVegan && (
                                <IconButton
                                    className={classes.mLeft5}
                                    aria-label="add to favorites"
                                >
                                    <img src={images.icons.doubleLeaf} alt="chili-paper" />
                                </IconButton>
                            )}
                            {dish.isHalal && (
                                <IconButton
                                    className={classes.mLeft5}
                                    aria-label="add to favorites"
                                >
                                    <img src={images.icons.halal} alt="green-leaf" />
                                </IconButton>
                            )}
                        </div>
                    </div>
                    {hidable && (
                        <img
                            src={images.icons.arrowDropdown}
                            alt="arrow"
                            style={{
                                marginRight: 20,
                                transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)',
                            }}
                        />
                    )}
                </div>
                {isOpen && (
                    <>
                        <span className={classes.dishDescription}>
                            {truncateString(dish.shortDescription, 30)}
                        </span>
                        <div style={{ marginTop: 20, marginLeft: -8 }}>{getAllergenIcons()}</div>
                    </>
                )}
            </div>
            <div className={classes.divider} />
            {isOpen && (
                <div style={{ paddingRight: 20 }}>
                    {getFixedIngredients()?.length > 0 && (
                        <>
                            <div className={classes.flexRowAlignCenter}>
                                <span className={`${classes.subTitle} ${classes.mBot10}`}>
                                    {i18n.t('restaurant.fixedIngredient').toUpperCase()}
                                </span>
                                <img
                                    src={images.icons.lock}
                                    alt={'lock'}
                                    style={{ marginLeft: 8, marginBottom: -2 }}
                                />
                            </div>
                            <div className={classes.mBot10}>
                                {getFixedIngredients()?.map((each: IngredientModel) => {
                                    return (
                                        <div
                                            className={`${classes.flexRowSpaceBetween} ${classes.ingredientText} ${classes.mTop10}`}
                                            key={each.id}
                                        >
                                            <span>{each.name ?? 'No Name'}</span>
                                            <span>{each.amount}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {getChangeOrDeleteIngredients()?.length > 0 && (
                        <>
                            <span className={`${classes.subTitle} ${classes.mBot10}`}>
                                {i18n.t('restaurant.changeOrDelete').toUpperCase()}
                            </span>
                            <div>
                                {getChangeOrDeleteIngredients()?.map((each: IngredientModel) => {
                                    return (
                                        <div
                                            className={`${classes.flexRowSpaceBetween} ${classes.ingredientText}`}
                                        >
                                            <div className={classes.flexRowSpaceBetween}>
                                                <Checkbox
                                                    style={{ marginLeft: -10 }}
                                                    checked={getValue(each.id, 'isRemovalChecked')}
                                                    onChange={() => {
                                                        changeValue(
                                                            'isRemovalChecked',
                                                            each.id,
                                                            each.isRemovalChecked
                                                        );
                                                    }}
                                                />
                                                <span>{each.name ?? 'No Name'}</span>
                                            </div>
                                            <span>{each.amount}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {getAdditionalIngredients()?.length > 0 && (
                        <>
                            <span className={`${classes.subTitle} ${classes.mBot10}`}>
                                {i18n.t('restaurant.additionalIngredients').toUpperCase()}
                            </span>
                            <div>
                                {getAdditionalIngredients()?.map((each: IngredientModel) => {
                                    return (
                                        <div
                                            className={`${classes.flexRowSpaceBetween} ${classes.ingredientText}`}
                                        >
                                            <div className={classes.flexRowSpaceBetween}>
                                                <Checkbox
                                                    style={{ marginLeft: -10 }}
                                                    checked={getValue(each.id, 'isAdditionChecked')}
                                                    onChange={() => {
                                                        changeValue(
                                                            'isAdditionChecked',
                                                            each.id,
                                                            each.isAdditionChecked
                                                        );
                                                    }}
                                                />
                                                <span>{each.name ?? 'No Name'}</span>
                                            </div>
                                            <span>{each.amount}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
