import React, { useEffect, useState } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    createStyles,
    FormControlLabel,
    makeStyles,
    Radio,
} from '@material-ui/core';
import clsx from 'clsx';
import i18n from 'i18n-js';

import { images } from 'assets';
import commonStyles from 'config/commonStyles';
import { PaymentMethod } from 'constants/enums';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
            marginBottom: 25,
            '& .MuiAccordion-root': {
                marginBottom: 5,
                filter: 'none',
            },
            '& .MuiAccordion-root::before': {
                content: '',
            },
            '& .AccordionSummary-expandIcon': {
                transform: 'none',
            },
            '& .MuiAccordionDetails-root': {
                display: 'block',
                padding: 0,
            },
            '& .MuiTypography-body1': {
                fontWeight: 700,
                color: theme.palette.secondary.dark,
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '12px 0',
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.secondary.main,
            },
            '& .MuiSvgIcon-root:nth-child(2)': {
                color: theme.palette.primary.main,
            },
        },
        paymentIcon: {
            ...commonStyles.imageContainerCenter,
            width: 45,
            height: 30,
        },
        paymentOptionContainer: {
            borderTop: `1px solid ${theme.palette.secondary.light}`,
            padding: 8,
        },
        paymentOption: {
            padding: 15,
            borderRadius: 5,
            cursor: 'pointer',
        },
        paymentOptionSelected: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            fontWeight: 500,
            ...commonStyles.flexRowSpaceBetween,
        },
        checkedIcon: {
            width: 20,
            height: 20,
            mrginRight: 20,
        },
        paidText: {
            fontStyle: 'Italic',
            marginLeft: 3,
            fontSize: 14,
        },
        noPointer: {
            cursor: 'default !important',
            '& .MuiFormControlLabel-root, & .MuiButtonBase-root, & .MuiAccordionDetails-root div, & div, & span, & input':
                {
                    cursor: 'default !important',
                },
        },
    })
);

interface OrderPaymentProps {
    paymentMethod: PaymentMethod;
    changePaymentMethod: (method: PaymentMethod) => void;
    isPaid?: boolean;
    isDisabled?: boolean;
}

export default function OrderPayment(props: OrderPaymentProps) {
    enum PaymentMethodsText {
        Online = 'online',
        OnDelivery = 'onDelivery',
        Cash = 'cash',
    }
    const classes = useStyles();
    const [checked, setChecked] = useState<string | null>(null);

    const isCardChecked = () =>
        checked === PaymentMethodsText.Online || checked === PaymentMethodsText.OnDelivery;

    function handleCheck(value: string) {
        if (props.isPaid || value === PaymentMethodsText.Online || props.isDisabled) return;
        setChecked(checked !== value ? value : null);
        saveChanges(value);
    }

    function handleCardCheck() {
        if (props.isPaid || props.isDisabled) return;
        else if (isCardChecked()) {
            setChecked(null);
            saveChanges(null);
        } else {
            setChecked(PaymentMethodsText.OnDelivery);
            saveChanges(PaymentMethodsText.OnDelivery);
        }
    }

    function saveChanges(method: string | null) {
        switch (method) {
            case PaymentMethodsText.Cash:
                props.changePaymentMethod(PaymentMethod.Cash);
                break;
            case PaymentMethodsText.OnDelivery:
                props.changePaymentMethod(PaymentMethod.Card);
                break;
            case PaymentMethodsText.Online:
                props.changePaymentMethod(PaymentMethod.Online);
                break;
            case null:
                props.changePaymentMethod(PaymentMethod.None);
                break;
            default:
                props.changePaymentMethod(PaymentMethod.None);
        }
    }

    function preparePaymentMethod() {
        switch (props.paymentMethod) {
            case PaymentMethod.None:
                setChecked(null);
                break;
            case PaymentMethod.Online:
                setChecked(PaymentMethodsText.Online);
                break;
            case PaymentMethod.Card:
                setChecked(PaymentMethodsText.OnDelivery);
                break;
            case PaymentMethod.Cash:
                setChecked(PaymentMethodsText.Cash);
        }
    }

    function prepareCardPaymentMethod(text: string, method: PaymentMethodsText) {
        return (
            <div className={classes.paymentOptionContainer} onClick={() => handleCheck(method)}>
                <div
                    className={clsx(classes.paymentOption, {
                        [classes.paymentOptionSelected]: checked === method,
                        [classes.noPointer]: props.isPaid,
                    })}
                >
                    {checked === method && props.isPaid ? (
                        <span>
                            {text}
                            <span className={classes.paidText}>({i18n.t('orders.paid')})</span>
                        </span>
                    ) : (
                        text
                    )}
                    {checked === method && props.isPaid && (
                        <img
                            src={images.icons.checkedCircle}
                            alt="checked-circle"
                            className={classes.checkedIcon}
                        />
                    )}
                </div>
            </div>
        );
    }

    useEffect(() => preparePaymentMethod(), [props.paymentMethod]); // eslint-disable-line

    return (
        <div
            className={clsx(classes.root, {
                [classes.noPointer]: props.isDisabled,
            })}
        >
            <Accordion square expanded>
                <AccordionSummary
                    onClick={handleCardCheck}
                    className={clsx({
                        [classes.noPointer]: props.isPaid,
                    })}
                    expandIcon={
                        <div
                            className={classes.paymentIcon}
                            style={{ backgroundImage: `url(${images.creditCard})` }}
                        />
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        control={<Radio checked={isCardChecked()} />}
                        label={i18n.t('orders.card')}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    {prepareCardPaymentMethod(
                        i18n.t('orders.paymentOnDelivery'),
                        PaymentMethodsText.OnDelivery
                    )}
                    {prepareCardPaymentMethod(
                        i18n.t('orders.onlinePayment'),
                        PaymentMethodsText.Online
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={false} onClick={() => handleCheck(PaymentMethodsText.Cash)}>
                <AccordionSummary
                    className={clsx({
                        [classes.noPointer]: props.isPaid,
                    })}
                    expandIcon={
                        <img
                            alt="money"
                            src={images.icons.money}
                            style={{ width: 31, height: 31 }}
                        />
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        //onClick={(event) => event.stopPropagation()}
                        //onFocus={(event) => event.stopPropagation()}
                        control={<Radio checked={checked === PaymentMethodsText.Cash} />}
                        label={
                            checked === PaymentMethodsText.Cash && props.isPaid
                                ? `${i18n.t('orders.cash')} (${i18n.t('orders.paid')})`
                                : i18n.t('orders.cash')
                        }
                    />
                </AccordionSummary>
            </Accordion>
        </div>
    );
}
