import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import * as yup from 'yup';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { DialogActions } from '@material-ui/core';
import i18n from 'i18n-js';
import { Form, Formik } from 'formik';

import { Input } from 'components/shared';

interface AddSectionProps {
    isVisible: boolean;
    onClose: () => void;
    onAdd: (sectionName: string) => void;
    sections: string[];
}

export default function AddSectionModal({ isVisible, onClose, onAdd, sections }: AddSectionProps) {
    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !sections.includes(value?.toUpperCase())
            ),
    });

    return (
        <Dialog open={isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{i18n.t('common.addSection')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{i18n.t('common.enterSectionName')}</DialogContentText>
                <Formik
                    initialValues={{
                        name: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        onAdd(values.name);
                        resetForm();
                    }}
                >
                    {({ errors, submitForm }) => (
                        <Form>
                            <>
                                <br />
                                <Input
                                    error={errors.name}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`name`}
                                    type="text"
                                    label={i18n.t('form.name')}
                                    placeholder={i18n.t('form.name')}
                                />
                            </>
                            <DialogActions>
                                <Button type="button" color="primary" onClick={onClose}>
                                    {i18n.t('button.cancel')}
                                </Button>
                                <Button onClick={submitForm} variant="contained" color="primary">
                                    {i18n.t('common.add')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
